
html {
    box-sizing: border-box;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    overflow-x: hidden;
    cursor: default;
}

*,
*::after,
*::before {
    box-sizing: inherit;
}

* {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -webkit-tap-highlight-color: transparent;
}

html,
body,
hgroup,
h1,
h2,
h3,
h4,
h5,
h6,
dl,
dt,
dd,
ol,
ul,
li,
p,
blockquote,
pre,
abbr,
form,
fieldset,
legend,
label,
input,
select,
textarea,
button,
table,
caption,
thead,
tbody,
tfoot,
tr,
th,
td {
    padding: 0;
    margin: 0;
}

dl,
ol,
ul,
li {
    list-style: none;
}

fieldset,
iframe,
img {
    border: 0;
}

table {
    border: 1px solid #000;
    border-collapse: collapse;
    border-spacing: 0;
}

a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:active,
    &:focus,
    &:visited {
        text-decoration: none;
    }
}

button {
    border: 0;
    background: transparent;
    color: inherit;
    font: inherit;
    // outline: none;
    cursor: pointer;
}

*::placeholder {
    /* color: ${Colors.silver}; */
    opacity: 1;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input::-ms-clear {
    display: none;
}

input:invalid {
    box-shadow: none;
}

input:focus {
    outline: none;
}

input,
textarea {
    -webkit-user-select: auto;
    -webkit-appearance: none;
}

input[type='password'] {
}

img {
    width: 100%;
}
