@charset "utf-8";

.popup {
    font-size: 1rem;
    h3 {
        color: #fff;
        font-size: 1.325rem; //16px;
        font-weight: 700;
    }
    h4,
    h5 {
        margin-bottom: 1rem; //12px;
    }
    h4,
    &__text-info {
        margin-bottom: 1rem; //12px;
        color: #dedede;
        font-size: 1.25rem; //14px;
        font-weight: 400;
        > span {
            margin-left: 0.8em; //12px;
            > span {
                color: #888;
            }
        }
    }
    h5 {
        font-size: 1.175rem; //14px;
        font-weight: 400;
    }
    strong {
        font-weight: 700;
    }
    &__white {
        &-gray {
            color: #dedede;
        }
    }
    &__bg {
        &::before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: 20;
            background-color: rgba(0, 0, 0, 0.64);
        }
        &-black {
            background-color: rgba(0, 0, 0, 1);
            border-width: 0;
        }
    }
    .icon__close-line {
        width: 2rem; //24px;
        height: 2rem; //24px;
        background-image: url('/img/icon_x_popup.svg');
    }
    &__text {
        &-small {
            font-size: 1rem; //12px;;
        }
    }
    &__layer {
        position: relative;
        top: 50px;
        z-index: 20;
        width: 100%;
        color: #a3a3a3;
        &-content {
            padding: 1.675em 2.5em 2.65em; //20px 30px 42px;
            margin-bottom: 50px;
            position: relative;
            z-index: 30;
            background-color: #191919;
            border-style: solid;
            border-width: 1px;
            border-color: #4e4e4e;
            font-size: 1.325em; //16px;
            font-weight: 400;

            > div {
                margin-bottom: 1.888em; //30px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
            .layout__flex-title {
                margin-bottom: 1rem; //12px;
                .popup__text-small {
                    font-size: 1.085rem;
                }
            }
            ul {
                li {
                    padding: 1.175em 1.275em;
                    &.popup__text-small {
                        color: #4e4e4e;
                    }
                }
            }
        }
        .btn__line {
            // border-color: #4e4e4e;
            color: #fff;
            font-weight: 400;
            // &.active {
            //     border-color: #a3a3a3;
            // }
        }
        .page_notice {
            padding: 0;
            margin-bottom: 1.25em;
            font-size: 0.95rem;
        }
    }
    &__list {
        &-transparent {
            ul {
                border-style: solid;
                border-color: #212121;
                border-width: 1px 0 0;
                &.line {
                    border-color: #2e2e2e;
                }
                &:nth-child(2) {
                    border-color: #2e2e2e;
                }
                &:last-child {
                    border-bottom: solid 1px #2e2e2e;
                }
                li {
                    .popup__text-small {
                        margin-left: 1em;
                    }
                }
            }
        }
    }
    &__btn {
        margin-top: 1.888em;
        width: 100%;
        text-align: center;
    }
    //장르전체, 채널전체
    &_grid_select {
        position: absolute;
        top: 2.5vw;
        left: 0;
        z-index: 5;
        ul {
            padding: 1.75em 1.5em;
            box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.7);
            border: solid 1px #4d4d4d;
            background-color: #191919;
            display: grid;
            grid-template: repeat(6, 1em) / auto;
            grid-auto-flow: column;
            gap: 0.85em 3em;
            font-size: 0.95em; //15px
            li {
                position: relative;
                flex-basis: 3em;
                white-space: nowrap;
                opacity: 0.7;
                &:hover,
                &.click_on {
                    opacity: 1;
                    font-weight: 500;
                }
                &.click_on {
                    &::after {
                        content: '';
                        display: inline-block;
                        // position: absolute;
                        margin-left: 0.3em;
                        width: 0.95em;
                        height: 0.95em;
                        transform: translate3d(0, 0.1em, 0);
                        background-image: url('../../img/icon_select_check.svg');
                        background-size: 190% auto;
                        background-position: 50% 60%;
                        background-repeat: no-repeat;
                    }
                }
            }
        }
    }
}
