//colors
$color_royal_blue_10_approx: rgba(48, 121, 244,.1);

.vb {
	height: 100%;
	> .vb-dragger {
		z-index: 5;
		width: 8px;
		right: 0;
		> .vb-dragger-styler {
			//Instead of the line below you could use @include backface-visibility($visibility)
			backface-visibility: hidden;
			//Instead of the line below you could use @include transform($scale, $rotate, $transx, $transy, $skewx, $skewy, $originx, $originy)
			transform: rotate3d(0,0,0,0);
			//Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
			transition: background-color 100ms ease-out, margin 100ms ease-out, height 100ms ease-out;
			background-color: #{$color-tvn};
			background-color: var(--color-tvn);
			margin: 5px 5px 5px 0;
			height: calc(100% - 10px);
			display: block;
		}
		&:hover > .vb-dragger-styler {
			background-color: #{$color-tvn};
			background-color: var(--color-tvn);
		}
	}
	&.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: #{$color-tvn};
		background-color: var(--color-tvn);
	}
	&.vb-dragging > .vb-dragger > .vb-dragger-styler {
		background-color: #{$color-tvn};
		background-color: var(--color-tvn);
	}
	&.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
		background-color: #{$color-tvn};
		background-color: var(--color-tvn);
	}
}
.__vuescroll {
	&::-webkit-scrollbar {
		width: 0;
	}
	.__bar-is-vertical {
		background-color: #{$color-tvn} !important;
		background-color: var(--color-tvn) !important;
		border-radius: 0 !important;
	}
	.__hidebar {
		width: calc(100% + 15px);
		.__view {
			min-width: calc(100% - 15px) !important;
			max-width: calc(100% - 15px) !important;
		}
	}
}
