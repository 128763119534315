.lists {
    .swiper-button-prev,
    .swiper-button-next,
    .swiper-pagination,
    .slide_item_all {
        opacity: 0;
        transition: opacity 0.1s;
    }
    &:hover {
        .swiper-button-prev,
        .swiper-button-next,
        .swiper-pagination,
        .slide_item_all {
            opacity: 1;
        }
    }
    + .lists {
        h2 {
            margin-top: em(15);
        }
    }
    h2 {
        margin: 0;
        padding: 0 0 1rem 0;
        font-size: 1.667rem;
        font-weight: 700;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: normal;
        text-align: left;
        color: #fff;

        @media screen and(max-width: 767px) {
            padding: 0 0 0.833rem 0;
            font-size: 1.333rem;
        }
    }
    &__title {
        &-num {
            margin-left: 0.675em;
            color: #b3b3b3;
            font-size: 1.25rem; //15px;
            font-weight: 400;
        }
    }
    &__slides {
        &,
        &-vertical {
            .item {
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1.019rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1.019rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        &-horizontal {
            .item {
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        &-square {
            .item {
                font-size: 1.275rem;
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                }
            }
        }
    }
    &__rows {
        display: grid;
        grid-template: auto/auto;
        align-items: flex-start;
        gap: 0 0.65em;
        &,
        &-vertical {
            .item {
                padding-bottom: 3em;
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1.019rem;
                }
                .layout__flex-left {
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1.019rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        &-horizontal {
            .item {
                padding-bottom: 0.8rem;
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
                &__column {
                    > a {
                        grid-template: auto/13.7rem auto;
                        gap: 1.2rem;
                        .item {
                            &__tags-left {
                                top: 0;
                                left: 0;
                            }
                            &__info {
                                .item {
                                    &__subinfo {
                                        margin-bottom: 0.4rem;
                                        dt {
                                            margin-right: 0.8rem;
                                            width: auto;
                                            color: rgba(255, 255, 255, 0.87);
                                            font-size: 1.5rem;
                                            font-weight: bold;
                                        }
                                        dd {
                                            color: rgba(255, 255, 255, 0.43);
                                            font-size: 1.3rem;
                                        }
                                    }
                                    &__subtitle {
                                        -webkit-line-clamp: 1;
                                        -webkit-box-orient: vertical;
                                        display: -webkit-inline-box;
                                        word-wrap: break-word;
                                        overflow: hidden;
                                        text-overflow: ellipsis;
                                        font-size: 1.3rem;
                                        line-height: 1.23;
                                        max-height: 3rem;
                                        color: rgba(255, 255, 255, 0.64);
                                        &.item__line {
                                            &-2 {
                                                -webkit-line-clamp: 2;
                                            }
                                            &-3 {
                                                -webkit-line-clamp: 3;
                                            }
                                            &-4 {
                                                -webkit-line-clamp: 4;
                                            }
                                            &-infinite {
                                                -webkit-line-clamp: initial;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    &__columns {
        display: grid;
        grid-template: auto/ repeat(12, 1fr);
        align-items: flex-start;
        gap: 0 0.65em;
        &,
        &-vertical {
            grid-template: auto/ repeat(3, 1fr);
            @media screen and(min-width:600px) {
                grid-template: auto/ repeat(5, 1fr);
            }
            @media screen and(min-width:1000px) {
                grid-template: auto/ repeat(6, 1fr);
            }
            @media screen and(min-width:1400px) {
                grid-template: auto/ repeat(7, 1fr);
            }
            @media screen and(min-width:2000px) {
                grid-template: auto/ repeat(8, 1fr);
            }
            &.lists__itemtype-row {
                grid-template: auto/ repeat(1, 1fr);
                gap: 0;
                @media screen and(min-width:1200px) {
                    grid-template: auto/ repeat(2, 1fr);
                    gap: 0 3.35em;
                    .item {
                        font-size: 1rem;
                    }
                }
                @media screen and(min-width:1600px) {
                    .item {
                        font-size: 0.93rem;
                    }
                }
            }
            .item {
                padding-bottom: 3em;
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1.019rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1.019rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        &-horizontal {
            grid-template: auto/ repeat(2, 1fr);
            @media screen and(min-width:600px) {
                grid-template: auto/ repeat(3, 1fr);
            }
            @media screen and(min-width:1000px) {
                grid-template: auto/ repeat(4, 1fr);
            }
            @media screen and(min-width:1400px) {
                grid-template: auto/ repeat(5, 1fr);
            }
            @media screen and(min-width:2000px) {
                grid-template: auto/ repeat(6, 1fr);
            }
            &.lists__itemtype-row {
                .item {
                    .item__column {
                        padding-bottom: 0;
                    }
                    > a {
                        grid-template: auto/ 22.5em auto !important;
                    }
                }
            }
            .item {
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }
        &-onAir {
            grid-template: auto/ repeat(2, 1fr);
            @media screen and(min-width:600px) {
                grid-template: auto/ repeat(3, 1fr);
            }
            @media screen and(min-width:1000px) {
                grid-template: auto/ repeat(4, 1fr);
            }
            @media screen and(min-width:1400px) {
                grid-template: auto/ repeat(5, 1fr);
            }
            @media screen and(min-width:2000px) {
                grid-template: auto/ repeat(6, 1fr);
            }
            &.lists__itemtype-row {
                .item {
                    .item__column {
                        padding-bottom: 0;
                    }
                    > a {
                        grid-template: auto/ 22.5em auto !important;
                    }
                }
            }
            .item {
                font-size: 1.275rem;
                .is-mobile & {
                    font-size: 1rem;
                }
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                    .is-mobile & {
                        font-size: 1rem;
                    }
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                    .is-mobile & {
                        font-size: 0.9rem;
                    }
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                    .is-mobile & {
                        font-size: 0.8rem;
                    }
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                    .is-mobile & {
                        font-size: 0.7rem;
                    }
                }
            }
        }

        &-square {
            grid-template: auto/ repeat(3, 1fr);
            @media screen and(min-width:600px) {
                grid-template: auto/ repeat(4, 1fr);
            }
            @media screen and(min-width:1000px) {
                grid-template: auto/ repeat(5, 1fr);
            }
            @media screen and(min-width:1400px) {
                grid-template: auto/ repeat(6, 1fr);
            }
            @media screen and(min-width:2000px) {
                grid-template: auto/ repeat(7, 1fr);
            }
            .item {
                font-size: 1.275rem;
                @media screen and(min-width: 600px) {
                    font-size: 1.275rem;
                }
                @media screen and(min-width:1000px) {
                    font-size: 1.175rem;
                }
                @media screen and(min-width:1400px) {
                    font-size: 1.075rem;
                }
                @media screen and(min-width:2000px) {
                    font-size: 0.975rem;
                }
            }
        }
    }
    // &__noneLazy {
    //     .item__thumb {
    //         picture {
    //             img,
    //             source {
    //                 opacity: 1;
    //             }
    //         }
    //     }
    // }
}

.none_hover_item {
    display: inline-block;
    position: relative;
    margin: 0;
    font-size: 1rem;
    color: rgba(255, 255, 255, 1);
    transition: transform 300ms ease-in-out;

    &__thumb {
        transition: box-shadow 300ms ease-in-out;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0), 0 0 5px 0 rgba(0, 0, 0, 0);
        will-change: box-shadow;
    }

    & > a {
        position: relative;
        display: block;
    }
    > .item {
        &:hover {
            .is-desktop & {
                transform: translate3d(0, 0, 0);
            }
        }
    }
    &:hover {
        .is-desktop & {
            // transform: translate3d(0, -.75rem, 0);
            .item__thumb {
                box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 1), 0 0 5px 0 rgba(0, 0, 0, 1);
            }
            .item__summary {
                opacity: 1;
            }
            .item__title {
                color: rgba(255, 255, 255, 1);
            }
            .item__subtitle {
                color: rgba(255, 255, 255, 0.87);
            }
            .item__subinfo {
                color: rgba(255, 255, 255, 0.64);
            }
        }
    }
    &__column {
        // margin-left:3.35em;
        &:first-child {
            margin-left: 0;
        }
        > a {
            display: grid;
            grid-template: auto/ 13.5em auto; //180px;
            align-items: flex-start;
            gap: 0 1.5em; //20px;
            .item__info {
                margin-right: auto;
                padding: 0;
                //My페이지
                .item__title {
                    position: relative;
                    box-sizing: border-box;
                    font-size: 1.25em; //15px;
                    // font-weight: 500;
                    line-height: 1.47;
                    color: rgba(255, 255, 255, 0.7);
                }
                .item__subtitle {
                    display: block;
                    font-size: 1.065em; //14px;
                    font-weight: 400;
                    line-height: 1.43;
                    color: rgba(179, 179, 179, 1);
                    &-strong {
                        margin-top: 1.415em; //0.75em;//18px;
                        display: block;
                        font-size: 1.065em; //14px;
                        color: #ff153c;
                    }
                }
                .item__subinfo {
                    margin: 0.5em 0;
                    color: #808080;
                    font-size: 1.065em; //14px;
                    color: #b3b3b3;
                    &.layout__flex {
                        justify-content: flex-start;
                    }
                    dt {
                        width: 5.833em; //82px;
                    }
                }
            }
        }
    }
    &__tags {
        pointer-events: none;
        position: absolute;
        top: 0; //4px
        bottom: 0;
        left: 0;
        right: 0; //2px
        z-index: 1;
        &-left {
            position: absolute;
            top: 0.333rem;
            left: 0.333rem;
            flex-wrap: wrap;
            gap: 0.2em;
            white-space: nowrap;
        }
        &-right {
            position: absolute;
            top: 0.333rem;
            right: 0.333rem;
            flex-wrap: wrap;
            gap: 0.2em;
            white-space: nowrap;
        }
        // &-down {
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     height: 50%;
        //     display: flex;
        //     align-items: flex-end;
        //     font-size: 1.2em;
        //     padding-bottom: 10.5%;
        //     background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        //     text-align: center;
        //     > span {
        //         margin-bottom: 0;
        //     }

        //     @media (max-width: 767px) {
        //         .is-mobile & {
        //             padding-bottom: 6.48%;
        //         }
        //     }
        // }
    }
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
    }

    &__play-btn {
        pointer-events: none;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            height: 50%;
            background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj4KICAgIDxnPgogICAgICAgIDxnIGZpbGw9InJnYmEoMCwwLDAsMC41KSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNXB4IiBvcGFjaXR5PSIwLjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjM4NyAtMikgdHJhbnNsYXRlKDIuMzg3IDIpIj4KICAgICAgICAgICAgPGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiIHN0cm9rZT0ibm9uZSIvPgogICAgICAgICAgICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjMwIiByPSIyOS4yNSIgZmlsbD0ibm9uZSIvPgogICAgICAgIDwvZz4KICAgICAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNOS44My4yNzFhLjIuMiAwIDAgMSAuMzM5IDBsOS42MzkgMTUuNDIzYS4yLjIgMCAwIDEtLjE3LjMwNkguMzYxYS4yLjIgMCAwIDEtLjE3LS4zMDZ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMi4zODcgLTIpIHRyYW5zbGF0ZSg0My4zODcgMjIpIHJvdGF0ZSg5MCkiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
        }
    }
    &__thumb-title {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translatey(-50%);
        font-size: 1.135em;
        font-weight: 700;
    }
    &__progress {
        display: block;
        width: 100%;
        height: 0.25em;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.1);
        &-bar {
            width: 50%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba(255, 22, 60, 0.9);
        }
    }
    &__info {
        padding: 0.6em 1em 0 0.05em;
        box-sizing: border-box;
        color: rgba(255, 255, 255, 0.7);
        @media (max-width: 1199px) {
            padding: 0.45em 0em 0 0.05em;
        }
        @media (max-width: 767px) {
            padding: 0.327em 0em 0 0.05em;
        }
        &-rank {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: repeat(3, auto);
            align-items: flex-start;
            column-gap: 1.25em;
        }
    }
    &__title {
        position: relative;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-inline-box;
        max-width: 100%;
        font-size: em(17);
        // font-weight: 500;
        line-height: 1.47;
        color: rgba(255, 255, 255, 0.87);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.1s;
        // background-color: #fafa0473;
        &-new {
            padding-right: 0.8em;
        }
        .is-mobile & {
            color: rgba(255, 255, 255, 0.64);
        }
    }
    &__point-new {
        width: 0.35em;
        height: 0.35em;
        display: block;
        position: absolute;
        right: 0em;
        top: 0.3em;
        background-color: #ff153c;
        border-radius: 50px;
    }
    &__subtitle {
        position: relative;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0.1em;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: em(16);
        color: rgba(255, 255, 255, 0.64);
        line-height: 1.5;
        transition: color 0.1s;
        // background-color: #c4275b6c;
        &__row {
            > p {
                margin-left: 0.5em;
                &:first-child {
                    margin-left: 0;
                }
            }
            .info {
                &-cancle {
                    text-decoration: line-through;
                    color: #808080;
                }
            }
        }
    }
    &__line {
        &-2 {
            -webkit-line-clamp: 2;
        }
        &-3 {
            -webkit-line-clamp: 3;
        }
        &-4 {
            -webkit-line-clamp: 4;
        }
        &-infinite {
            -webkit-line-clamp: initial;
        }
    }
    &__subinfo {
        overflow: hidden;
        margin-top: 0.3em;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: em(15);
        color: rgba(255, 255, 255, 0.43);
        transition: color 0.1s;
        // background-color: #c4275b3a;
    }
    &__rank-no {
        grid-row: 1 / last-line;
        position: relative;
        z-index: 2;
        top: 0.6em;
        text-align: left;
        line-height: 0;
        color: #fff;
        font-size: 3.6em;
        transform: skewX(-10deg) translateY(-0.2em);
        font-weight: 700;
        letter-spacing: -5px;
    }
    &__summary {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        height: 100%;
        background-color: rgba(25, 25, 25, 0.96);
        opacity: 0;
        font-size: 0.8em;
        transition: opacity 0.1s;
        dl {
            padding: 2.083em 1.45em;
            margin: 0 auto;
            dt {
                font-weight: 700;
                font-size: 2em;
                line-height: 1.3em;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                word-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgba(255, 255, 255, 1);
            }
            dd {
                span {
                    font-size: 1.25em;
                    line-height: 1.75em;
                    color: rgba(255, 255, 255, 0.88);
                }
                margin: 0 auto;
                opacity: 0.5;
                p {
                    margin-top: 0.25em;
                    font-size: 1.175em;
                    line-height: 1.55em;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgba(255, 255, 255, 0.64);

                    @media screen and(min-width: 600px) {
                        -webkit-line-clamp: 4;
                    }

                    @media screen and(min-width: 850px) {
                        -webkit-line-clamp: 5;
                    }
                }
            }
        }
    }
}

.item {
    display: inline-block;
    position: relative;
    margin: 0;
    font-size: 1rem;
    color: rgba(255, 255, 255, 1);
    transition: transform 300ms ease-in-out;

    &__thumb {
        transition: box-shadow 300ms ease-in-out;
        box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 0), 0 0 5px 0 rgba(0, 0, 0, 0);
        will-change: box-shadow;
    }

    & > a {
        position: relative;
        display: block;
    }
    > .item {
        &:hover {
            .is-desktop & {
                transform: translate3d(0, 0, 0);
            }
        }
    }
    &:hover {
        .is-desktop & {
            transform: translate3d(0, -0.75rem, 0);
            .item__thumb {
                box-shadow: 0 0px 20px 0 rgba(0, 0, 0, 1), 0 0 5px 0 rgba(0, 0, 0, 1);
            }
            .item__summary {
                opacity: 1;
            }
            .item__title {
                color: rgba(255, 255, 255, 1);
            }
            .item__subtitle {
                color: rgba(255, 255, 255, 0.87);
            }
            .item__subinfo {
                color: rgba(255, 255, 255, 0.64);
            }
        }
    }
    &__column {
        // margin-left:3.35em;
        &:first-child {
            margin-left: 0;
        }
        > a {
            display: grid;
            grid-template: auto/ 13.5em auto; //180px;
            align-items: flex-start;
            gap: 0 1.5em; //20px;
            .item__info {
                margin-right: auto;
                padding: 0;
                //My페이지
                .item__title {
                    position: relative;
                    box-sizing: border-box;
                    font-size: 1.25em; //15px;
                    // font-weight: 500;
                    line-height: 1.47;
                    color: rgba(255, 255, 255, 0.7);
                }
                .item__subtitle {
                    display: block;
                    font-size: 1.065em; //14px;
                    font-weight: 400;
                    line-height: 1.43;
                    color: rgba(179, 179, 179, 1);
                    &-strong {
                        margin-top: 1.415em; //0.75em;//18px;
                        display: block;
                        font-size: 1.065em; //14px;
                        color: #ff153c;
                    }
                }
                .item__subinfo {
                    margin: 0.5em 0;
                    color: #808080;
                    font-size: 1.065em; //14px;
                    color: #b3b3b3;
                    &.layout__flex {
                        justify-content: flex-start;
                    }
                    dt {
                        width: 5.833em; //82px;
                    }
                }
            }
        }
    }
    &__tags {
        pointer-events: none;
        position: absolute;
        top: 0; //4px
        bottom: 0;
        left: 0;
        right: 0; //2px
        z-index: 1;
        &-left {
            position: absolute;
            top: 0.333rem;
            left: 0.333rem;
            flex-wrap: wrap;
            gap: 0.2em;
            white-space: nowrap;
        }
        &-right {
            position: absolute;
            top: 0.333rem;
            right: 0.333rem;
            flex-wrap: wrap;
            gap: 0.2em;
            white-space: nowrap;
        }
        // &-down {
        //     position: absolute;
        //     left: 0;
        //     right: 0;
        //     bottom: 0;
        //     height: 50%;
        //     display: flex;
        //     align-items: flex-end;
        //     font-size: 1.2em;
        //     padding-bottom: 10.5%;
        //     background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.5));
        //     text-align: center;
        //     > span {
        //         margin-bottom: 0;
        //     }

        //     @media (max-width: 767px) {
        //         .is-mobile & {
        //             padding-bottom: 6.48%;
        //         }
        //     }
        // }
    }
    &__image {
        position: absolute;
        width: 100%;
        height: 100%;
    }
    &__thumb {
        overflow: hidden;
        position: relative;
        width: 100%;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.13);
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='28' viewBox='0 0 88 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-3%7Bfill:%23fff%7D %3C/style%3E%3C/defs%3E%3Cg id='TVING_logo_W' opacity='0.1' transform='translate(0 10.306)'%3E%3Cpath id='area' fill='none' d='M0 0H88V28H0z' transform='translate(0 -10.306)'/%3E%3Cg id='logo' transform='translate(-1 -1.93)'%3E%3Cpath id='사각형_1958' d='M0 0H6.325V19.975H0z' class='cls-3' transform='translate(41.561 -4.377)'/%3E%3Cpath id='패스_1268' d='M65.982 271.969H50.875v6.331h5.881v13.689h6.509V278.3h5.955l-1.982-5.438a1.343 1.343 0 0 0-1.255-.895' class='cls-3' transform='translate(-49.875 -276.346)'/%3E%3Cpath id='패스_1269' d='M205.762 280.825l-6.279-8.214a1.617 1.617 0 0 0-1.284-.642h-3.92v19.8l.017.17h6.092l.166-.018v-8.843l6.271 8.217a1.62 1.62 0 0 0 1.287.644h3.925v-19.97h-6.274z' class='cls-3' transform='translate(-143.654 -276.346)'/%3E%3Cpath id='패스_1270' d='M117.444 272.879l-3.389 9.708-3.373-9.706a1.343 1.343 0 0 0-1.261-.913h-6l7.481 19.974h6.313l7.485-19.974h-6a1.342 1.342 0 0 0-1.26.911' class='cls-3' transform='translate(-84.201 -276.344)'/%3E%3Cpath id='패스_1271' d='M264.2 286.15h-3.754v-8.272h7.646a1.763 1.763 0 0 0 1.641-1.166l1.743-4.744h-17.359v19.975h17.148v-11.4H264.2z' class='cls-3' transform='translate(-182.798 -276.344)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        // background-position: center;
        // background-size: 48%;
        // background-repeat: no-repeat;
        // // background-color: #191919;

        @media screen and(max-width: 768px) {
            border-radius: 3px;
        }

        &:before {
            content: '';
            display: block;
            padding-top: 144%;
            width: 100%;
            float: left;
            box-sizing: inherit;
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        &-25x36 {
            &:before {
                padding-top: 144%;
            }
        }
        &-16x9 {
            &:before {
                padding-top: 56.25%;
            }
        }
        &-1x1 {
            &:before {
                padding-top: 100%;
            }
        }
        &-200x87 {
            &:before {
                padding-top: 43.5%;
            }
        }
        &-200x10 {
            &:before {
                padding-top: 34.54%;
            }
        }
        &-10x1 {
            &:before {
                padding-top: 10%;
            }
        }
        &-10x1-2 {
            &:before {
                padding-top: 8.33%;
            }
        }
        &-1x2 {
            border-radius: 6px;

            &:before {
                padding-top: 200%;
            }

            picture {
                &.error {
                    &:before {
                        background-size: 10rem 2.438rem;
                    }
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        // picture {
        //     img,
        //     source {
        //         opacity: 1;
        //         transition: opacity 0.1s;
        //     }
        //     &.error {
        //         &::before {
        //             content: '';
        //             position: absolute;
        //             top: 50%;
        //             left: 50%;
        //             width: 100%;
        //             height: 100%;
        //             background: #212121 url("/img/tving-empty.svg") no-repeat center center;
        //             background-size: 7.333rem 2.333rem;
        //             transform: translate(-50%, -50%);
        //         }
        //         img,
        //         source {
        //             opacity: 0;
        //             transition: opacity 0s;
        //         }
        //     }
        //     &.loaded {
        //         img,
        //         source {
        //             opacity: 1;
        //             transition: opacity 0.1s;
        //         }
        //     }
        // }
        &-16x9 {
            picture.error:after {
                background-size: 32%;
            }
        }
        &:not(&-16x9):not(&-1x1):not(&-200x87) + .item__info-rank {
            .item__rank-no {
                top: 0.17em;
            }
        }
    }

    &__thumb-new-tving-item {
        overflow: hidden;
        position: relative;
        width: 100%;
        border-radius: 4px;
        background-color: #262626;
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='28' viewBox='0 0 88 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-3%7Bfill:%23fff%7D %3C/style%3E%3C/defs%3E%3Cg id='TVING_logo_W' opacity='0.1' transform='translate(0 10.306)'%3E%3Cpath id='area' fill='none' d='M0 0H88V28H0z' transform='translate(0 -10.306)'/%3E%3Cg id='logo' transform='translate(-1 -1.93)'%3E%3Cpath id='사각형_1958' d='M0 0H6.325V19.975H0z' class='cls-3' transform='translate(41.561 -4.377)'/%3E%3Cpath id='패스_1268' d='M65.982 271.969H50.875v6.331h5.881v13.689h6.509V278.3h5.955l-1.982-5.438a1.343 1.343 0 0 0-1.255-.895' class='cls-3' transform='translate(-49.875 -276.346)'/%3E%3Cpath id='패스_1269' d='M205.762 280.825l-6.279-8.214a1.617 1.617 0 0 0-1.284-.642h-3.92v19.8l.017.17h6.092l.166-.018v-8.843l6.271 8.217a1.62 1.62 0 0 0 1.287.644h3.925v-19.97h-6.274z' class='cls-3' transform='translate(-143.654 -276.346)'/%3E%3Cpath id='패스_1270' d='M117.444 272.879l-3.389 9.708-3.373-9.706a1.343 1.343 0 0 0-1.261-.913h-6l7.481 19.974h6.313l7.485-19.974h-6a1.342 1.342 0 0 0-1.26.911' class='cls-3' transform='translate(-84.201 -276.344)'/%3E%3Cpath id='패스_1271' d='M264.2 286.15h-3.754v-8.272h7.646a1.763 1.763 0 0 0 1.641-1.166l1.743-4.744h-17.359v19.975h17.148v-11.4H264.2z' class='cls-3' transform='translate(-182.798 -276.344)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        // background-position: center;
        // background-size: 48%;
        // background-repeat: no-repeat;
        // // background-color: #191919;

        @media screen and(max-width: 768px) {
            border-radius: 3px;
        }

        &:before {
            content: '';
            display: block;
            padding-top: 144%;
            width: 100%;
            float: left;
            box-sizing: inherit;
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        &-25x36 {
            &:before {
                padding-top: 144%;
            }
        }
        &-16x9 {
            &:before {
                padding-top: 56.25%;
            }
        }
        &-1x1 {
            &:before {
                padding-top: 100%;
            }
        }
        &-225x128 {
            &:before {
                padding-top: 56%;
            }
        }
        &-200x87 {
            &:before {
                padding-top: 43.5%;
            }
        }
        &-200x10 {
            &:before {
                padding-top: 34.54%;
            }
        }
        &-10x1 {
            &:before {
                padding-top: 10%;
            }
        }
        &-10x1-2 {
            &:before {
                padding-top: 8.33%;
            }
        }
        &-10x1-2-mobile {
            &:before {
                padding-top: 16.4%;
            }
        }
        &-76x98 {
            &:before {
                padding-top: 128.95%;
            }
        }
        &-1x2 {
            border-radius: 6px;

            &:before {
                padding-top: 200%;
            }

            picture {
                &.error {
                    &:before {
                        background-size: 10rem 2.438rem;
                    }
                }
            }
        }
        &-9x15 {
            &:before {
                padding-top: 166.66%;
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        // picture {
        //     img,
        //     source {
        //         opacity: 1;
        //         transition: opacity 0.1s;
        //     }
        //     &.error {
        //         &::before {
        //             content: '';
        //             position: absolute;
        //             top: 50%;
        //             left: 50%;
        //             width: 100%;
        //             height: 100%;
        //             background: #212121 url("/img/tving-empty.svg") no-repeat center center;
        //             background-size: 7.333rem 2.333rem;
        //             transform: translate(-50%, -50%);
        //         }
        //         img,
        //         source {
        //             opacity: 0;
        //             transition: opacity 0s;
        //         }
        //     }
        //     &.loaded {
        //         img,
        //         source {
        //             opacity: 1;
        //             transition: opacity 0.1s;
        //         }
        //     }
        // }
        &-16x9 {
            picture.error:after {
                background-size: 32%;
            }
        }
        &:not(&-16x9):not(&-1x1):not(&-200x87) + .item__info-rank {
            .item__rank-no {
                top: 0.17em;
            }
        }
    }

    &__thumb-last {
        // overflow: hidden;
        position: relative;
        width: 100%;
        border-radius: 4px;
        background-color: rgba(255, 255, 255, 0.13);
        // background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='88' height='28' viewBox='0 0 88 28'%3E%3Cdefs%3E%3Cstyle%3E .cls-3%7Bfill:%23fff%7D %3C/style%3E%3C/defs%3E%3Cg id='TVING_logo_W' opacity='0.1' transform='translate(0 10.306)'%3E%3Cpath id='area' fill='none' d='M0 0H88V28H0z' transform='translate(0 -10.306)'/%3E%3Cg id='logo' transform='translate(-1 -1.93)'%3E%3Cpath id='사각형_1958' d='M0 0H6.325V19.975H0z' class='cls-3' transform='translate(41.561 -4.377)'/%3E%3Cpath id='패스_1268' d='M65.982 271.969H50.875v6.331h5.881v13.689h6.509V278.3h5.955l-1.982-5.438a1.343 1.343 0 0 0-1.255-.895' class='cls-3' transform='translate(-49.875 -276.346)'/%3E%3Cpath id='패스_1269' d='M205.762 280.825l-6.279-8.214a1.617 1.617 0 0 0-1.284-.642h-3.92v19.8l.017.17h6.092l.166-.018v-8.843l6.271 8.217a1.62 1.62 0 0 0 1.287.644h3.925v-19.97h-6.274z' class='cls-3' transform='translate(-143.654 -276.346)'/%3E%3Cpath id='패스_1270' d='M117.444 272.879l-3.389 9.708-3.373-9.706a1.343 1.343 0 0 0-1.261-.913h-6l7.481 19.974h6.313l7.485-19.974h-6a1.342 1.342 0 0 0-1.26.911' class='cls-3' transform='translate(-84.201 -276.344)'/%3E%3Cpath id='패스_1271' d='M264.2 286.15h-3.754v-8.272h7.646a1.763 1.763 0 0 0 1.641-1.166l1.743-4.744h-17.359v19.975h17.148v-11.4H264.2z' class='cls-3' transform='translate(-182.798 -276.344)'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E%0A");
        // background-position: center;
        // background-size: 48%;
        // background-repeat: no-repeat;
        // // background-color: #191919;

        @media screen and(max-width: 768px) {
            border-radius: 3px;
        }

        &:before {
            content: '';
            display: block;
            padding-top: 144%;
            width: 100%;
            float: left;
            box-sizing: inherit;
        }
        &:after {
            content: '';
            display: block;
            clear: both;
        }
        &-25x36 {
            &:before {
                padding-top: 144%;
            }
        }
        &-16x9 {
            &:before {
                padding-top: 56.25%;
            }
        }
        &-1x1 {
            &:before {
                padding-top: 100%;
            }
        }
        &-200x87 {
            &:before {
                padding-top: 43.5%;
            }
        }
        &-200x10 {
            &:before {
                padding-top: 34.54%;
            }
        }
        &-10x1 {
            &:before {
                padding-top: 10%;
            }
        }
        &-10x1-2 {
            &:before {
                padding-top: 8.33%;
            }
        }
        &-10x1-2-mobile {
            &:before {
                padding-top: 16.4%;
            }
        }
        &-1x2 {
            border-radius: 6px;

            &:before {
                padding-top: 200%;
            }

            picture {
                &.error {
                    &:before {
                        background-size: 10rem 2.438rem;
                    }
                }
            }
        }

        img {
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            width: 100%;
            height: 100%;
        }
        // picture {
        //     img,
        //     source {
        //         opacity: 1;
        //         transition: opacity 0.1s;
        //     }
        //     &.error {
        //         &::before {
        //             content: '';
        //             position: absolute;
        //             top: 50%;
        //             left: 50%;
        //             width: 100%;
        //             height: 100%;
        //             background: #212121 url("/img/tving-empty.svg") no-repeat center center;
        //             background-size: 7.333rem 2.333rem;
        //             transform: translate(-50%, -50%);
        //         }
        //         img,
        //         source {
        //             opacity: 0;
        //             transition: opacity 0s;
        //         }
        //     }
        //     &.loaded {
        //         img,
        //         source {
        //             opacity: 1;
        //             transition: opacity 0.1s;
        //         }
        //     }
        // }
        &-16x9 {
            picture.error:after {
                background-size: 32%;
            }
        }
        &:not(&-16x9):not(&-1x1):not(&-200x87) + .item__info-rank {
            .item__rank-no {
                top: 0.17em;
            }
        }
    }

    &__play-btn {
        pointer-events: none;
        position: relative;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            display: flex;
            justify-content: center;
            align-items: center;
            width: 33%;
            height: 50%;
            background-image: url('data:image/svg+xml;base64, PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI2MCIgaGVpZ2h0PSI2MCIgdmlld0JveD0iMCAwIDYwIDYwIj4KICAgIDxnPgogICAgICAgIDxnIGZpbGw9InJnYmEoMCwwLDAsMC41KSIgc3Ryb2tlPSIjZmZmIiBzdHJva2Utd2lkdGg9IjEuNXB4IiBvcGFjaXR5PSIwLjciIHRyYW5zZm9ybT0idHJhbnNsYXRlKC0yLjM4NyAtMikgdHJhbnNsYXRlKDIuMzg3IDIpIj4KICAgICAgICAgICAgPGNpcmNsZSBjeD0iMzAiIGN5PSIzMCIgcj0iMzAiIHN0cm9rZT0ibm9uZSIvPgogICAgICAgICAgICA8Y2lyY2xlIGN4PSIzMCIgY3k9IjMwIiByPSIyOS4yNSIgZmlsbD0ibm9uZSIvPgogICAgICAgIDwvZz4KICAgICAgICA8cGF0aCBmaWxsPSIjZmZmIiBkPSJNOS44My4yNzFhLjIuMiAwIDAgMSAuMzM5IDBsOS42MzkgMTUuNDIzYS4yLjIgMCAwIDEtLjE3LjMwNkguMzYxYS4yLjIgMCAwIDEtLjE3LS4zMDZ6IiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMi4zODcgLTIpIHRyYW5zbGF0ZSg0My4zODcgMjIpIHJvdGF0ZSg5MCkiLz4KICAgIDwvZz4KPC9zdmc+Cg==');
            background-repeat: no-repeat;
            background-position: 50%;
            background-size: contain;
        }
    }
    &__thumb-title {
        position: absolute;
        top: 50%;
        left: 10%;
        transform: translatey(-50%);
        font-size: 1.135em;
        font-weight: 700;
    }
    &__progress {
        display: block;
        width: 100%;
        height: 0.25em;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 2;
        background-color: rgba(0, 0, 0, 0.1);
        &-bar {
            width: 50%;
            height: 100%;
            display: block;
            position: absolute;
            left: 0;
            bottom: 0;
            background-color: rgba(255, 22, 60, 0.9);
        }
    }
    &__info {
        padding: 0.6em 1em 0 0.05em;
        box-sizing: border-box;
        color: rgba(255, 255, 255, 0.7);
        @media (max-width: 1199px) {
            padding: 0.45em 0em 0 0.05em;
        }
        @media (max-width: 767px) {
            padding: 0.327em 0em 0 0.05em;
        }
        &-rank {
            display: inline-grid;
            grid-template-columns: auto 1fr;
            grid-template-rows: repeat(3, auto);
            align-items: flex-start;
            column-gap: 1.25em;
        }
    }
    &__title {
        position: relative;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-inline-box;
        max-width: 100%;
        font-size: em(17);
        // font-weight: 500;
        line-height: 1.47;
        color: rgba(255, 255, 255, 0.87);
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: color 0.1s;
        // background-color: #fafa0473;
        &-new {
            padding-right: 0.8em;
        }
        .is-mobile & {
            color: rgba(255, 255, 255, 0.64);
        }
    }
    &__point-new {
        width: 0.35em;
        height: 0.35em;
        display: block;
        position: absolute;
        right: 0em;
        top: 0.3em;
        background-color: #ff153c;
        border-radius: 50px;
    }
    &__subtitle {
        position: relative;
        word-wrap: break-word;
        overflow: hidden;
        text-overflow: ellipsis;
        overflow: hidden;
        margin-top: 0.1em;
        // text-overflow: ellipsis;
        // white-space: nowrap;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        font-size: em(16);
        color: rgba(255, 255, 255, 0.64);
        line-height: 1.5;
        transition: color 0.1s;
        // background-color: #c4275b6c;
        &__row {
            > p {
                margin-left: 0.5em;
                &:first-child {
                    margin-left: 0;
                }
            }
            .info {
                &-cancle {
                    text-decoration: line-through;
                    color: #808080;
                }
            }
        }
    }
    &__line {
        &-2 {
            -webkit-line-clamp: 2;
        }
        &-3 {
            -webkit-line-clamp: 3;
        }
        &-4 {
            -webkit-line-clamp: 4;
        }
        &-infinite {
            -webkit-line-clamp: initial;
        }
    }
    &__subinfo {
        overflow: hidden;
        margin-top: 0.3em;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: em(15);
        color: rgba(255, 255, 255, 0.43);
        transition: color 0.1s;
        // background-color: #c4275b3a;
    }
    &__rank-no {
        grid-row: 1 / last-line;
        position: relative;
        z-index: 2;
        top: 0.6em;
        text-align: left;
        line-height: 0;
        color: #fff;
        font-size: 3.6em;
        transform: skewX(-10deg) translateY(-0.2em);
        font-weight: 700;
        letter-spacing: -5px;
    }
    &__summary {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 2;
        height: 100%;
        background-color: rgba(25, 25, 25, 0.96);
        opacity: 0;
        font-size: 0.8em;
        transition: opacity 0.1s;
        dl {
            padding: 2.083em 1.45em;
            margin: 0 auto;
            dt {
                font-weight: 700;
                font-size: 2em;
                line-height: 1.3em;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                display: -webkit-box;
                word-wrap: break-word;
                overflow: hidden;
                text-overflow: ellipsis;
                color: rgba(255, 255, 255, 1);
            }
            dd {
                span {
                    font-size: 1.25em;
                    line-height: 1.75em;
                    color: rgba(255, 255, 255, 0.88);
                }
                margin: 0 auto;
                opacity: 0.5;
                p {
                    margin-top: 0.25em;
                    font-size: 1.175em;
                    line-height: 1.55em;
                    -webkit-line-clamp: 5;
                    -webkit-box-orient: vertical;
                    display: -webkit-box;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: rgba(255, 255, 255, 0.64);

                    @media screen and(min-width: 600px) {
                        -webkit-line-clamp: 4;
                    }

                    @media screen and(min-width: 850px) {
                        -webkit-line-clamp: 5;
                    }
                }
            }
        }
    }
}
