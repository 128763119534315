@charset "utf-8";

$vw-viewport: 1600;
$vw-enable: true;
$gnb-height-num: em(80);
$container-pt: 1.5em;
$browser-context: 16; // Default

@import './utils/_utils.scss';

// Common Import
@import './common/common.scss';
@import './common/mixin.scss';

html {
    &.hide-scrollbar {
        overflow: scroll;
        overflow-x: hidden;

        ::-webkit-scrollbar {
            width: 0;
            background: transparent;
        }
    }

    @media screen and (max-width: 768px) {
        // font-size: calc(0.8vw + 9px);
        font-size: 12px;
        &.is-laptop {
            font-size: calc(6px + 0.5vw);
        }
    }
    @media screen and (min-width: 768px) {
        font-size: 1.2vw;
        &.is-laptop {
            font-size: calc(6px + 0.5vw);
        }
    }
    @media screen and (min-width: 768px) and (orientation: portrait) {
        font-size: 1.5vw;
        &.is-laptop {
            font-size: calc(6px + 0.5vw);
        }
    }
    @media screen and (min-width: 1200px) {
        font-size: 1vw;
        &.is-laptop {
            font-size: calc(6px + 0.5vw);
        }
    }
    @media screen and (min-width: 2000px) {
        font-size: 16px;
        &.is-laptop {
            font-size: 16px;
        }
    }
    @media screen and (min-width: 2400px) {
        font-size: 18px;
        &.is-laptop {
            font-size: 18px;
        }
    }
}

// 푸터
@import './components/footer.scss';
@import './components/select.scss';

//버튼
@import './components/button.scss';

//팝업
@import './components/popup.scss';

// Main
@import './style.scss';

// Mypage
@import './page/my.scss';

// 상세페이지 SCSS
@import './page/detail.scss';

// 검색 SCSS
@import './page/search.scss';

// 검색 SCSS
@import './page/schedule.scss';

// 이용약관 SCSS
@import './page/board.scss';

// 결과 SCSS
@import './page/result.scss';

// date-picker SCSS
@import './components/datepicker.scss';

// 혼종
@import './components/tags.scss';
@import './components/list.scss';

@import './swal/sweetalert2.scss';

//
.swiper.swiper-horizontal > .swiper-scrollbar {
    left: 0;
    bottom: -3rem !important;
    width: 100%;
    height: 0.25rem;
    background-color: rgba(255, 255, 255, 0.1);

    .swiper-scrollbar-drag {
        background-color: rgba(255, 255, 255, 0.3);
    }
}

.slide_movie + article {
    border-top: 0 !important;
}

.swiper-scrollbar + .swiper-pagination {
    display: none;
}
