@charset "utf-8";

.result {
  margin: 5rem auto 18.5rem;
  max-width: 51rem;
  width: 100%;
  text-align: center;
  //상단타이틀
  .head {
    &-title {
      position: relative;

      &-wrap {
        margin: 0 auto 3rem;
        text-align: center;
      }

      color: #fff;
      font-size: 2.5rem;
      font-weight: bold;
      word-break: keep-all;

      img {
        vertical-align: -0.65rem;
      }

      p {
        padding: 0 2rem;
        margin-top: 1rem;
        color: #a3a3a3;
        line-height: 2.417rem;
        font-size: 1.667rem;
        font-weight: normal;
        word-break: keep-all;
      }

      &.middle {
        font-size: 1.667rem;
        font-weight: 500;
      }

      &-sub {
        color: #dedede;
        font-size: 2rem;
        font-weight: 500;

        p {
          margin-top: 1rem;
          color: #888;
          line-height: 1.5;
          font-size: 1.333rem;
          font-weight: normal;
        }
      }
    }

    //상단아이콘
    &-icon {
      margin-bottom: 2rem;
      width: 100%;
      height: 6rem;
      background-size: contain;
      background-position: 50% 100%;
      background-repeat: no-repeat;
      border: 0;
      top: 0;
      text-indent: -9999px;

      &-screamer {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' id='icon_search_nothing' width='55' height='55' viewBox='0 0 55 55'%3E%3Cdefs%3E%3Cstyle%3E .cls-2%7Bfill:%23a3a3a3%7D %3C/style%3E%3C/defs%3E%3Cg id='타원_6' fill='none' stroke='%23a3a3a3' stroke-width='2px'%3E%3Ccircle cx='27.5' cy='27.5' r='27.5' stroke='none'/%3E%3Ccircle cx='27.5' cy='27.5' r='26.5'/%3E%3C/g%3E%3Cpath id='사각형_2110' d='M0 0H3V19H0z' class='cls-2' transform='translate(26 14)'/%3E%3Cpath id='사각형_2111' d='M0 0H3V3H0z' class='cls-2' transform='translate(26 39)'/%3E%3C/svg%3E");
      }

      &-nineteen {
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='72'%3E%3Cg data-name='19_RED' transform='translate(-764 -257)'%3E%3Ccircle data-name='Ellipse 743' cx='36' cy='36' r='36' transform='translate(764 257)' style='fill:%23e00b0b'/%3E%3Ctext data-name='19' transform='translate(779.5 306)' style='fill:%23fff;font-size:36px;font-family:Roboto-Bold,Roboto;font-weight:700'%3E%3Ctspan x='0' y='0'%3E19%3C/tspan%3E%3C/text%3E%3C/g%3E%3C/svg%3E%0A");
      }

      &-legal {
        background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' width='72' height='72.002' viewBox='0 0 72 72.002'%3E%3Cpath data-name='패스 10' d='M1098.935 863.331v-20.986c-22.772 5.789-31-11.216-31-11.216s-8.223 17-31 11.216v20.986s-1.084 29.306 31 39.8c32.084-10.494 31-39.8 31-39.8z' transform='translate(-1031.939 -831.129)' style='fill:%23939393'/%3E%3Cpath data-name='패스 11' d='m1061.482 877.95-13.33-13.465 5.069-5.121 8.261 8.344 15.6-15.759 5.069 5.121z' transform='translate(-1029.152 -825.95)' style='fill:%23141414'/%3E%3Cpath data-name='사각형 4303' style='fill:none' d='M0 0h72v72H0z'/%3E%3C/svg%3E%0A");
      }

      &-check {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='55' height='55' viewBox='0 0 55 55'%3E%3Cg fill='none'%3E%3Cpath d='M27.5 0A27.5 27.5 0 1 1 0 27.5 27.5 27.5 0 0 1 27.5 0z'/%3E%3Cpath fill='%23a3a3a3' d='M27.5 2c-3.443 0-6.783.674-9.925 2.003-3.036 1.284-5.764 3.123-8.106 5.466-2.343 2.342-4.182 5.07-5.466 8.106C2.673 20.717 2 24.057 2 27.5c0 3.443.674 6.783 2.003 9.925 1.284 3.036 3.123 5.764 5.466 8.106 2.342 2.343 5.07 4.182 8.106 5.466C20.717 52.327 24.057 53 27.5 53c3.443 0 6.783-.674 9.925-2.003 3.036-1.284 5.764-3.123 8.106-5.466 2.343-2.342 4.182-5.07 5.466-8.106C52.327 34.283 53 30.943 53 27.5c0-3.443-.674-6.783-2.003-9.925-1.284-3.036-3.123-5.764-5.466-8.106-2.342-2.343-5.07-4.182-8.106-5.466C34.283 2.673 30.943 2 27.5 2m0-2C42.688 0 55 12.312 55 27.5S42.688 55 27.5 55 0 42.688 0 27.5 12.312 0 27.5 0z'/%3E%3C/g%3E%3Cpath fill='none' stroke='%23a3a3a3' stroke-width='2px' d='M-5223.028 3301.866l7.42 8.62 16.58-14.685' transform='translate(5238.528 -3274.302)'/%3E%3C/svg%3E");

        &-bg {
          // padding-top: 12.5rem;
          padding-top: 4.25rem;
          width: 100%;
          // height: 14.5rem;
          background-image: url("/img/bg-check.png");
          background-position: 50% 0;
          background-size: contain;
          background-repeat: no-repeat;
          // &::before{
          // }
        }
      }
    }
  }

  &.form-column {
    margin: 4.175em auto 2.75em; //50px auto 33px;
    width: 35.4em; //425px;
    .form-column__item-input {
      margin-top: 0.725em; //10px;
      margin-bottom: 1.5em;
      padding-bottom: 0;
      border-width: 0;
      flex-flow: nowrap;

      &:first-child {
        margin-top: 0;
      }

      h5 {
        width: 9.925em; //135px;
      }

      label {
        input {
          // margin: 0.666em auto;//10px;
          display: block;
        }
      }
    }
  }

  .btn__wrap {
    margin-top: 3rem;

    + .btn__wrap {
      margin-top: 1rem;
    }

    &.noborder {
      margin-top: 2rem;
    }

    a,
    button {
      width: 100%;
      max-width: 41.667rem;
      font-size: 1.5rem;
      font-weight: bold;
    }

  }

  .btn-position-bottom {
    .btn-wrap {
      margin-top: 0;
    }
  }
}

.box {
  &-wrap {
    h4 {
      color: #fff;
      font-size: 2rem;
      margin-bottom: 0rem;
    }
  }

  &-textline5 {
    // @include text-line(5);
  }

  &-textscroll {
    overflow: auto;
    height: 8.5rem;
  }

  &-gray {
    padding: 1.417rem 2rem;
    border-radius: 3px;
    background-color: #191919;
    text-align: left;

    ul {
      width: 100%;
      line-height: 1.75rem;

      li {
        + li {
          margin-top: 1.417rem;
          padding-top: 1.417rem;
          border-top: solid 1px #2e2e2e;
        }

        dl {
          dt, dd {

            font-size: 1.333rem;
          }

          dt {
            width: 5.5rem;
            color: #888;
          }

          dd {
            margin-left: 2rem;
            color: #fff;
          }

          &.icon-layout {
            &::before {
              margin-right: 1rem;
            }
          }
        }
      }
    }

    .text-line {
      text-decoration: underline;
      color: #b3b3b3;
    }

    &-simple {
      padding: 2.25rem;
      color: #a3a3a3;
      font-size: 1.167rem;

      h4 {
        margin-bottom: 0.75rem;
        font-size: 1.667rem;
      }

      ul {
        width: 100%;

        li {
          + li {
            margin-top: 0.75rem;
            padding-top: 0;
            border: none;
          }

          dl {
            dt {
              width: 6.75rem;
            }
          }
        }
      }
    }
  }

  &-black {
    margin: 0 auto;
    width: calc(100% - 5rem);
    padding: 2rem;
    background-color: #000;
    border-radius: 4px;
    box-sizing: border-box;
  }
}

//동의, 19인증
.confirm {
  .content-wrap {
    margin: 3rem auto 5rem;
    width: 70rem;
    width: 76%;

    .head-title-wrap {
      margin-bottom: 2rem;
    }

    .info-join {
      margin-top: 2rem;
    }

    .btn {
      &-pay {
        margin-top: 3rem;
        margin-bottom: 2rem;
        padding: 0 3rem;

        button {
          padding: 2.167rem 4.167rem;
          text-align: left;

          p {
            margin-left: 2rem;
            opacity: 0.6;
            color: #fff;

            span {
              margin-top: 0.65rem;
              display: block;
              color: #a3a3a3;
              font-size: 1.167rem;
              line-height: 1.43;
            }
          }

          &:hover,
          &:focus {
            border-color: #a3a3a3;
            color: #fff;

            p {
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .btn-checks-wrap {
    margin: 3rem auto 2rem;
    width: 30rem;

    li + li {
      margin-top: 0.15rem;
    }

    .btn-checks {
      input + label {
        font-size: 1.333rem;

        &::before {
          top: 0.35rem;
        }
      }
    }
  }

  .box {
    &-gray {
      margin-left: auto;
      margin-right: auto;
      padding: 2.5rem;
      width: 45.833rem;
      width: 65%;

      ul {
        li {
          color: #888;
          font-size: 1.167rem;
          letter-spacing: -0.025rem;

          + li {
            margin-top: 0.25rem;
          }
        }
      }
    }
  }
}