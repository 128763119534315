$css-vars-use-native: true;

@import '_css_vars.scss';
@import '_rem.scss';

$color-tvn: #ff1f45;
$color-dev: #009688;
$color-ad: #fcc800;

$color-cate-home: #ff1f45;
$color-cate-live: #ff584a;
$color-cate-vod: #387dff;
$color-cate-clip: #fd8163;
$color-cate-movie: #7d57fc;
$color-cate-kids: #ffc031;
$color-cate-style: #ff1492;

$con-height: 5.083rem;
$con-mobile-height: 46px;
$progress-height: 0.333333rem;
$control-pd: 2rem; // 컨트롤러 좌우 여백0 0.78125rem
$bdr-md: 4px;
$bdr-sm: 2px;
$font-en: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

@mixin colorwhite() {
    color: #fff !important;
    // @at-root #{'.cjp-root.window.chrome'} #{&} {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        // font-weight: 100;
        // color: #333 !important;
        // filter: contrast(0%) brightness(200%);
    }
}
@mixin colorwhiteShadow() {
    color: #fff;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);

    // @at-root #{'.cjp-root.window.chrome'} #{&} {
    @media screen and (-webkit-min-device-pixel-ratio: 0) {
        // font-weight: 100;
        // color: #333 !important;
        // text-shadow: none;
        // filter: contrast(0%) brightness(200%) drop-shadow(0px 0 3px rgba(0, 0, 0, 0.5));
    }
}
@mixin hideControl {
    .cjp-wrap.uihide.playing:not(.media-paused) .cjp__ui:not(.hover) & {
        @content;
    }
}
@mixin showControl {
    .cjp-wrap.uihide.playing:not(.media-paused) .cjp__ui.hover &,
    .cjp-wrap.playing.media-paused:not(.uihide) .cjp__ui & {
        @content;
    }
}
@mixin modeAd {
    .cjp-wrap.ca & {
        @content;
    }
}
// 우측 하단 미니플레이어
@mixin modeFixed {
    .cjp-root.cjp__res-s .cjp-wrap:not(.fullscreen) &,
    .player.fixed .cjp-wrap:not(.fullscreen) & {
        @content;
    }
}
