@charset "utf-8";

.search {
    &__page {
        .search__input {
            margin: 0 auto;
            padding: 3.75em 0;
            width: calc(100% - 15.7em);
        }
    }
    &__input {
        position: relative;
        display: block;
        margin: 0 auto;
        padding: 3.75em 0 1em;
        // width: calc(100% - 25em);
        border-bottom: solid 2px #fff;

        .icon__search {
            position: absolute;
            bottom: 1em;
            right: 0;
            margin: 0;
            width: 2.75em;
            height: 2.75em;
            opacity: 1;
        }
    }
    &__result {
        margin: 3.5rem auto;
        color: #b3b3b3;
        text-align: center;
        color: #b3b3b3;
        &.btn__line-small.border0 {
            margin: 0 0 0.5em auto;
            color: #808080;
            font-size: 1.125em;
        }
        &.icon__screamer {
            margin-bottom: 14em; //190px;
            &::before {
                margin-bottom: 1.7em; //20px;
            }
        }
        &-title {
            color: #fff;
            font-size: 2.5em; //30px;
        }
        &-subtitle {
            margin-top: 1em;
            font-size: 1.675em; //20px;
            color: #888;
        }
        &-recommend {
            font-size: 1.335em; //16px;
            li {
                margin-top: 1em;
                &:first-child {
                    margin-top: 1.875em; //30px;
                }
                &.active {
                    color: #fff;
                }
            }
        }
    }
    &__preview {
        &.layout__flex-left {
            align-items: flex-start;
        }

        &.title {
            cursor: pointer;
        }

        ul {
            padding-left: 3.35em; //40px;
            width: 50%;
            border-left: solid 1px #2e2e2e;
            &:first-child {
                padding-left: 0;
                border: none;
            }
            li {
                // line-height: 2.225em;
                // margin-top: 1rem;
                // button {
                    margin: 0.75rem 0;
                // }

                font-size: 1.335rem; //16px;
                font-weight: 400;
                color: rgba(222, 222, 222, 0.7);
                transition: color .1s;
                line-height: 1.5;
                > .count {
                    width: 1.85em;
                    display: inline-block;
                    color: #ff153c;
                }
                &.active {
                    color: rgba(222, 222, 222, 1);
                    font-weight: 400;
                    .icon__close-line {
                        opacity: 1;
                    }
                }
                &.search__title {
                    margin-bottom: 0.65em;
                    color: rgba(222, 222, 222, 1);
                    line-height: normal;
                    font-size: 1.666em; //20px;
                    font-weight: 500;
                }
                &.day {
                    font-size: 1.175em; //14px;
                    font-weight: 400;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 2.75em;
                    letter-spacing: normal;
                    text-align: left;
                    color: #646464;
                }
            }
        }
    }
}
