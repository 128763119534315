@charset "utf-8";

.topic-main-impotant {
    div,
    font,
    span,
    p,
    td {
        color: #888 !important;
        letter-spacing: -0.05rem !important;
        font-size: 1.25rem !important;
        line-height: 2rem !important;
    }
    b,
    strong {
        margin: 0.5rem 0 !important;
        display: inline-block !important;
    }
    b,
    strong,
    b > span,
    strong > span {
        font-weight: bold !important;
        color: #dedede !important;
        letter-spacing: normal !important;
    }
    p {
        padding: 0 !important;
        margin: 0 !important;
        text-indent: 0 !important;
    }
    a {
        text-decoration: underline !important;
        line-height: 2.5rem !important;
    }
    table {
        margin: 1.333rem auto !important;
        // width: 100% !important;
        td,
        p {
            text-align: left !important;
        }
        td {
            padding: 1.333rem !important;
            border-width: 1px !important;
            border-color: #212121 !important;
        }
    }
}
.topic- {
    h4,
    h5 {
        font-size: 1.25rem;
    }
    li {
        list-style: inside;
        a {
            text-decoration: underline;
        }
    }
}
//고객 게시판
.diagram {
    width: 100%;
    height: 19.375em; //250px;
    background-position: 50%;
    background-size: 100% auto;
    background-repeat: no-repeat;
    font-size: 1rem;
    text-indent: -9999px;
    &__guest-01 {
        background-image: url('/img/diagram01.svg');
    }
    &__guest-02 {
        background-image: url('/img/diagram02.svg');
    }
    &__guest-03 {
        background-image: url('/img/diagram03.svg');
    }
    &__table {
        height: 16.833rem;
        background-image: url('/img/diagram_table.svg');
    }
}
.board {
    &-use {
    }
    &-contents {
        h3 {
            font-size: 2rem; //24px;
            margin-bottom: 1.25em;
        }
        .page_select {
            margin-top: 2.5rem; //margin: 3.25em auto 2em;
            &_layout {
                margin-left: 1.25em;
                padding-left: 1.25em;
                line-height: 0.95em;
                font-size: 1.325rem; //16px;
                //margin-bottom: 2.5em
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                }
            }
        }
        .search {
            &__input {
                padding-top: 0;
                width: 47.75em; //580px;
                border-color: rgba(255, 255, 255, 0.5);
                input {
                    font-size: 1.825em; //22[x;
                }
                .icon__search {
                    opacity: 0.5;
                }
            }
        }
        .preview-list {
            color: #b3b3b3;
            font-size: 1.167rem;
            font-weight: 400;
            li {
                padding: 0 1.05em; //15px;
                border-left: solid 1px rgba(255, 255, 255, 0.3);
                line-height: 1;
                letter-spacing: -0.025em;
                &:first-child,
                &:nth-child(2) {
                    padding-left: 0;
                    border-width: 0;
                }
                &:first-child {
                    font-weight: 700;
                }
                &:last-child {
                    padding-right: 0;
                }
            }
        }
        .topic {
            margin-top: 2.5rem;
            color: #808080;//#b3b3b3;
            line-height: 1.55em; //22px;
            font-size: 1.235rem; //15px;//1/325rem;//16px;
            font-weight: 400;
            &-main {
                h4 {
                    margin: 3.333rem 0 1.667rem;
                    color: #fff;
                    font-size: 1.667rem;
                    font-weight: 500;
                }
            }
            &-sub {
                margin-bottom: 1.25em;
                h5 {
                    font-size: inherit;
                    font-weight: inherit;
                }
            }
            &-list {
                &-item {
                    border-style: solid;
                    border-color: #191919;
                    border-width: 1px 0 0 0;
                    &:last-child {
                        border-bottom-width: 1px;
                    }
                }
            }
            &-graybox{
                margin-top: 1.65rem;//2rem;
                padding:2.5rem;
                background-color: #191919;
                border-radius: 4px;
                color: #808080;
                line-height: 1.55em;
                font-size: 1.167rem;
                font-weight: 400;

                .emphasis-text{
                    font-weight: 600;
                    border-bottom: solid;
                    display: inline;
                }
            }
            .board__table {
                td {
                    &:first-child {
                        border-top: solid 1px #191919;
                    }
                }
            }
            &__open {
                &-main {
                    h4 {
                        color: #dedede;
                        font-size: 1.25em; //20px;
                        font-weight: 500;
                        span {
                            margin-left: auto;
                            &.num__day {
                                line-height: 3.27;
                                color: #888;
                                font-size: 15px;
                                font-weight: 400;
                            }
                        }
                    }

                }
                &-sub {
                    margin-top: 2em;
                    margin-bottom: 1.25em;
                    line-height: 1.5em;
                    h5 {
                        font-size: inherit;
                        font-weight: inherit;
                    }
                }
            }
            &-go {
                &-frame {
                    margin-top: 1.05em;
                    padding: 4.075em 0;
                    width: 100%;
                    background-color: #141414;
                    font-size: 1rem;
                    text-align: center;
                    p {
                        margin-bottom: 1.35em; //20px;
                        padding: 0 7em;
                        font-size: 1.235em; //15px;
                    }
                    &.search {
                        margin: 2.5em auto; //30px;
                        padding: 3.5em 0;
                    }
                }
            }
        }
        .design2 {
            &.topic {
                &-main {
                    h4 {
                        margin-top: 1.85em;
                        margin-bottom: 1.05em;
                        font-size: 1.65rem; //20px;
                        font-weight: 400;
                        color: #fff;
                    }
                }
            }
        }
        .design3 {
            &.topic {
                &-main {
                    h4 {
                        margin-top: 1.85em;
                        margin-bottom: 0.833em;
                        font-size: 1.333rem;
                        font-weight: 500;
                        color: #fff;
                    }
                    h5{
                        margin-bottom: 0.5rem;
                        font-size: inherit;
                        color: #fff;
                    }
                }
            }
        }
    }
    &__table {
        padding-top: 2.5em; //30px;
    }
}

.accordion {
    margin-top: 1.75em; //30px;
    margin-bottom: 17em;
    border-style: solid;
    border-color: #191919;
    border-width: 1px 0;
    font-size: 1.235rem; //15px;

    &-title {
        padding: 1.75em 1.335em;
        width: 100%;
        transition: color 0.1s;

        &:first-child {
            border-top: solid 1px #191919;
        }
        &:hover {
            color: #fff;
        }
        &-num {
            margin-right: 1.335em;
        }
        &-text {
            width: 5.25em;
            font-weight: 400;
            color: #808080;
        }
        h5 {
            font-size: inherit;
            font-weight: 400;
        }
        .arrow {
            display: inline-block;
            width: 2.35em; //28px;
            background-repeat: no-repeat;
            background-position: 75% 60%;
            background-image: url('/img/icon_arrow_down.svg');
            background-size: 150% auto;
            opacity: 0.5;
            font-size: 1rem;
            text-indent: -9999px;
        }
        &.active {
            td {
                border-bottom: 0;
            }
            h5 {
                color: #fff;
                font-size: 1.35em;
                font-weight: 500;
            }
            .arrow {
                background-image: url('/img/icon_arrow_top.svg');
                opacity: 1;
            }
            + .accordion-menu {
                display: block;
            }
        }
    }
    &-menu {
        display: none;
        padding-left: 3.25em;
        padding-bottom: 2.5em;
        border-bottom: solid 1px #191919;
        line-height: 1.575;
        &.text {
            padding: 0 11rem 2em;
        }
    }
}
.faq {
    &.accordion {
        margin-bottom: 23em;
    }
}

//입력폼
.form {
    &-layout {
        //margin-top: 1.25em;
        padding-top: 2em; //24px;
        // height: calc(100% - 16vw);
        position: relative;
        //border-top: solid 1px #2e2e2e;
        font-size: 1rem;
        margin-bottom: 2.5em;
        &:last-child {
            //margin-bottom: 3.5em;
            border-bottom: solid 1px #2e2e2e;
        }
        &__notice {
            margin-top: 2.5rem;
            margin-bottom: 1.25em;
            color: #808080;
        }
        > .layout__flex-left {
            align-items: flex-start;
        }
        form {
            width: 100%;
        }
        h4 {
            //margin: 0.25em 5em 0 0;
            margin-top: 0.25em;
            font-size: 1.675em; //20px;
            font-weight: 500;
            + .form-column {
                width: calc(100% - 15em);
                margin-left: auto;
            }
            > .title-notice {
                margin: 0.833rem 2.333rem 5.333rem 0.083rem;
                color: #808080;
                font-size: 0.917rem;
            }
        }
        a,
        button {
            background-color: transparent;
            // border: solid 1px rgb(86, 86, 86);
            color: rgb(86, 86, 86);
            font-size: 1.175rem; //14px;//3.75vw;
            font-weight: 500;
            border-radius: 0.15em; //2px;
            font-weight: 600;
            cursor: pointer;
            &:hover,
            &:focus {
                border: 1px #dedede solid;
                background-color: #dedede;
                color: #000;
            }
            &.disabled,
            &:disabled {
                opacity: 0.5;
                // pointer-events: none;
                background-color: transparent;
                border: solid 1px #565656;
            }
        }
        &.result {
            height: 100%;
        }
        .content {
            &-wrap {
                padding: 0;
            }
        }
        .payment-title {
            &::before {
                display: none;
            }
        }
        .payment-subtitle {
            font-size: 1.175rem; //14px;
        }
        + .btn_wrap {
            margin-bottom: 10em; //120px;
            a + a {
                margin-left: 1em;
            }
        }
    }
    &-column {
        input[type='text']:not(:valid) ~ .close-icon,
        input[type='password']:not(:valid) ~ .close-icon {
            display: none;
        }
        input {
            &[type='radio'] {
                // position: relative;
                // top: 1px;
                margin: 0 0.675em; //8px;
                -webkit-appearance: none;
                -moz-appearance: none;
                appearance: none;
                outline: 0;
                box-shadow: none;
                border: none;
                background-color: transparent;
                font-size: 1rem;
                &:after {
                    content: '';
                    display: inline-block;
                    width: 1.35em; //18px;
                    height: 1.35em; //18px;
                    box-sizing: border-box;
                    border: 1px solid #4d4d4d;
                    border-radius: 100%;
                    vertical-align: middle;
                    cursor: pointer;
                }
                &:checked::after {
                    background: radial-gradient(circle, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 45%, rgba(0, 0, 0, 0) 45%);
                    border-color: #fff;
                }
            }
            + label {
                margin-right: 2em; //30px;
                width: auto;
                font-size: 1.25rem; //15px;
                font-weight: 400;
                color: #b3b3b3;
            }
        }
        &__item-input {
            position: relative;
            margin: 1.725em 0;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-flow: wrap;
            font-size: 1.175rem; //14px;
            color: #b3b3b3;
            // &:first-child {
            //     margin-top: 0;
            // }

            //certify-입력폼
            .input-info {
                margin: 0.75em 0 -0.75em 8.25em;
                width: 100%;
            }
            &.num {
                &-email {
                    margin-top: 1.725em;
                    label,
                    input {
                        ///width: 10em; //150px;
                        + span {
                            margin-right: 0.625em;
                        }
                        &.with-button {
                            margin-right: 0.625em;
                            width: 10em; //150px;
                        }
                    }
                    button,
                    ul,
                    li {
                        width: 10.15em; //150px;
                    }
                    .button-select {
                        width: 100%;
                        &-wrap {
                            button {
                                margin-left: 0;
                            }
                        }
                    }
                }
                &-mobile {
                    margin-top: -1.25em; //0.5em;
                    &:first-child {
                        margin-top: 1.725em;
                    }
                    &.minus-mg {
                        margin-top: 0.5em;
                        margin-bottom: 1.725em;
                    }
                    label {
                        width: 19.75em; //296px;
                        position: relative;
                    }
                    .button-select-wrap,
                    .datainput-pw {
                        width: 7em; //100px;
                    }
                    .button-select-wrap {
                        button,
                        .button-select {
                            width: 100%;
                            font-size: 1.25rem; //15px;
                            margin-left: 0;
                        }
                    }
                    .hyphen {
                        padding: 0 0.575em; //8px;
                    }
                    & + .input-info {
                        margin: -1em 0 1.75em 8.25em;
                    }
                }
            }
            &.divice {
                .button-select-wrap {
                    width: 12.5em; //178px;
                    .selct-arrow,
                    .button-select {
                        width: 100%;
                    }
                    .selct-arrow {
                        margin-left: 0;
                    }
                }
                .datainput-divice {
                    margin-left: 0.525em; //8px;
                    width: 15.75em; //240px;
                }
            }

            .hyphen {
                padding: 0 0.575em; //8px;
            }
            > h5 {
                min-width: 7em; //105px;
                font-size: 1.065em; //15px;
                font-weight: 400;
            }
            .data-id,
            .data-name,
            label {
                width: 24.175em; //100%;//calc(100% - 105px);//420px;
                font-size: 1.25rem; //15px;
                &:first-child {
                    margin-top: 1.6em; //24px;
                }
                > input {
                    margin: 0;
                    padding: 0.575em 1.066em; //9px 16px;
                    width: 100%;
                    border-color: #191919;
                    background-color: #191919;
                    line-height: normal;
                    &:focus {
                        border-color: #808080;
                    }
                }
                &.with-button {
                    width: calc(100% - 160px);
                    &-120 {
                        width: calc(100% - 128px);
                    }
                }
            }
            .data {
                &-id {
                    // width: 100%;
                    text-indent: 2.5em;
                }
                &-name {
                    // width: 100%;
                }
            }
            button {
                margin-left: 0.625em; //8px;
                padding: 0.75em 1.65em;
                width: auto;
                font-size: 1.075rem; //13px;
                font-weight: 400;
                text-align: center;
                &:hover,
                &:focus {
                    border-color: #808080;
                    background-color: transparent;
                    color: #808080;
                }
                &.icon-hide,
                &.close-icon {
                    margin-top: 0;
                    min-width: auto;
                    border-width: 0;
                    &:hover,
                    &:focus {
                        border: none;
                        background-color: transparent;
                    }
                }
                &.icon-hide {
                    width: 1.725em; //1.425em;
                    background-color: transparent;
                }
                &.close-icon {
                    width: 1.65em;
                }
            }

            .button-select {
                display: none;
                padding: 0.725em 0;
                width: calc(10.25em + 1px);
                position: absolute;
                // top: 2.275em;
                right: 0;
                z-index: 1;
                background-color: #191919;
                border: solid 1px #4d4d4d;
                box-sizing: border-box;
                &-wrap {
                    position: relative;
                    button {
                        color: #4e4e4e;
                        &:hover,
                        &:focus,
                        &:active {
                            color: #4e4e4e;
                        }
                    }
                    .selct-arrow {
                        // padding: 0.55em 1em;
                        padding: 0.55em 2.5em 0.55em 1em;
                        background: url('../../img/icon_arrow_down.svg') no-repeat 100% 20%;
                        background-size: 1.8em;
                        text-align: left;
                        // width: 9.65em; //150px;
                        border: solid 1px #565656;
                        font-size: 1.25rem; //15px;
                        &:hover,
                        &:focus {
                            border: solid 1px #808080;
                            color: #808080;
                            & + .button-select {
                                display: block;
                            }
                        }
                    }
                }
                &:hover {
                    display: block;
                    background-color: #191919;
                    border-color: inherit;
                }
                & > li {
                    list-style: none;
                    text-indent: 1.05em;
                    &:hover {
                        background-color: #dedede;
                        color: #4e4e4e;
                    }
                }
                &-radio {
                    margin-top: -1rem;
                    width: 78%;
                    label {
                        margin-top: 1rem;
                        margin-right: 2.5rem;
                        float: left;
                        width: auto;
                        &:last-child {
                            margin-right: 0;
                        }
                        input {
                            margin-left: 0;
                            padding: 0;
                            width: auto;
                        }
                    }
                }
            }
            .time {
                position: absolute;
                top: 0.55em;
                right: 3%;
                // transform:translateY(-50%);
                font-size: 1.25rem; //15px;
                color: rgb(0, 143, 232);
            }
        }
    }
    //문의 내용
    &-qna {
        border-top: 0;
        margin-top: 0;
        .form-column__item-input {
            margin-top: 0;
            align-items: flex-start;
            h5 {
                margin-top: 0.5em;
            }
            label,
            textarea {
                width: calc(100% - 7.1em);
            }
            .datainput {
                &-text {
                    margin: 0;
                    padding: 0.575em 1.066em;
                    min-height: 20em;
                    border-color: #191919;
                    background-color: #191919;
                    font-size: 1.25rem;
                    &:focus {
                        border-color: #808080;
                    }
                    + .form-layout__notice {
                        margin: 1em 0 0.25em 7.25em;
                    }
                }
            }
        }
    }
}
