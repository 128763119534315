// HTML 콘텐츠에 담겨오는 인라인 스타일 중 일부를 강제로 덮어 씌워야 합니다. :/
.override-popup-contents {
    * {
        font-family: inherit !important;
        font-size: 1.25rem;
        line-height: 150%;
        white-space: normal;

        padding: 0 !important;
        margin: 0 !important;
    }

    table {
        border-width: 1px !important;
        border-color: #99999940 !important;
        font-size: 1rem;

        * {
            font-size: inherit;
        }
    }

    // SH*T @https://stackoverflow.com/a/31633969
    br {
        display: block;
        content: '';
        margin-top: 1.25rem !important;
    }
}
