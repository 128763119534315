@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
    .original-only-gradient {
        @apply after:bg-[linear-gradient(180deg,_rgba(0,0,0,0)_80.5%,_rgba(0,0,0,0.5)_100%)];
    }
    .has-labels-gradient {
        @apply after:bg-[linear-gradient(180deg,_rgba(0,0,0,0)_0%,_rgba(0,0,0,0)_60%,_rgba(0,0,0,0.5)_100%)];
    }
    .live-label-animation {
        @apply before:content-[''] before:absolute before:top-1/2 before:translate-y-[-50%]
    before:sm:left-[0.33rem] before:sm:w-[0.33rem] before:sm:h-[0.33rem]
    before:md:left-[0.53rem] before:md:w-[0.458rem] before:md:h-[0.458rem]
    before:rounded-full before:animate-blink before:bg-[#FF153C] before:z-[20];
    }
    .item-hover-animation {
        @apply transition transform ease-in-out hover:translate-y-[-0.75rem] duration-300;
    }
    .after-inset-0 {
        @apply after:content-[''] after:absolute after:top-0 after:bottom-0 after:left-0 after:right-0;
    }
    .flex-center {
        @apply flex justify-center items-center;
    }
    .absolute-center {
        @apply absolute left-1/2 top-1/2 translate-x-[-50%] translate-y-[-50%];
    }
    .absolute-full {
        @apply absolute top-0 right-0 bottom-0 left-0;
    }
    .absolute-left-top {
        @apply absolute left-0 top-0;
    }

    .vertical-item-poster-text-center {
        @apply block text-center absolute left-[1.333rem] right-[1.333rem] top-1/2 translate-y-[-50%] leading-normal sm:text-[1.083rem] md:text-[1.5rem] line-clamp-6 font-normal text-[#B3B3B3];
    }

    .horizontal-item-poster-text-center {
        @apply block text-center absolute left-[2rem] right-[2rem] top-1/2 translate-y-[-50%] leading-normal sm:text-[1.083rem] md:text-[1.5rem] line-clamp-4 font-normal text-[#B3B3B3];
    }

    .original-item-poster-text-center {
        @apply block text-center absolute left-[2rem] right-[2rem] top-[10.416rem] leading-normal sm:text-[1.083rem] md:text-[1.5rem] line-clamp-6 font-normal text-[#B3B3B3];
    }

    .shorts-item-poster-text-center {
        @apply block text-center absolute left-[2rem] right-[2rem] top-[10.416rem] leading-normal sm:text-[1.083rem] md:text-[1.5rem] line-clamp-6 font-normal text-[#B3B3B3];
    }

    /* .free-label-wrapper {
    @apply absolute sm:w-[2rem] sm:h-[1.333rem] sm:top-[0.166rem] sm:left-[0.166rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[2.75rem] md:h-[1.833rem] z-[30];
  } */

    /* .newepisode-label-wrapper {
    @apply absolute sm:w-[2rem] sm:h-[1.333rem] sm:top-[0.166rem] sm:left-[0.166rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[2.75rem] md:h-[1.833rem] z-[30];
  } */

    /* .live-label-wrapper{
    @apply absolute top-[0.25rem] left-[0.25rem] z-[30] w-[6.416rem];
  } */

    /* .quickVod-label-wrapper {
    @apply absolute sm:top-[1.333rem] sm:left-[1.333rem] sm:w-[3.333rem] sm:h-[1.333rem] md:top-[0.25rem] md:left-[0.25rem] md:w-[4.583rem] md:h-[1.833rem] z-[30];
  } */

    .atom-poster-img {
        @apply rounded-[4px] z-[20];
    }

    .atom-logo-img {
        @apply z-[10];
    }

    .atom-progressBar-wrapper {
        @apply absolute left-0 bottom-0 h-[0.3rem] bg-[#000]/10 w-full z-[20];
    }

    .atom-progressBar-percent {
        @apply h-full bg-[#FF153C]/90;
    }

    /* .subtitle-label-wrapper {
    @apply sm:w-[2rem] sm:h-[1.333rem] md:w-[2.648rem] md:h-[1.83rem];
  } */

    /* .dubbing-label-wrapper {
    @apply sm:w-[2rem] sm:h-[1.333rem] md:w-[2.648rem] md:h-[1.83rem];
  } */

    /* .age19-label-wrapper {
    @apply sm:w-[1.333rem] sm:h-[1.333rem] md:w-[1.833rem] md:h-[1.833rem];
  } */

    /* .age18-label-wrapper {
    @apply sm:w-[1.333rem] sm:h-[1.333rem] md:w-[1.833rem] md:h-[1.833rem];
  } */

    /* original item의 경우만 basic item 경우와 디자인이 달라서 only, original 라벨 크기와 bottom 값만 별도 처리 */
    .originalItem-wrapper > .only-label-wrapper {
        @apply sm:h-[0.916rem] sm:w-[3.541rem] sm:bottom-[7.583rem] md:w-[4.125rem] md:h-[1.083rem] md:bottom-[9.833rem];
    }

    .originalItem-wrapper > .original-label-wrapper {
        @apply sm:h-[0.916rem] sm:w-[5.25rem] sm:bottom-[7.583rem] md:w-[6.266rem] md:h-[1.083rem] md:bottom-[9.833rem];
    }

    .basic-padding {
        @apply pl-[1rem] pr-[1rem] md:pl-[4rem] md:pr-[4rem] lg:pl-[1.333rem] lg:pr-[1.333rem];
    }
}

@layer utilities {
    .scrollbar-hidden {
        -ms-overflow-style: none !important; /* For IE */
        &::-webkit-scrollbar {
            display: none !important;
            width: 0 !important; /* 수직 스크롤바의 너비를 0으로 설정하여 숨김 */
        }
        scrollbar-width: none !important; /* For Firefox */
        scrollbar-color: transparent transparent !important; /* For Firefox */
        &::-webkit-scrollbar-track {
            display: none !important; /* 스크롤바 트랙을 숨김 */
            background-color: transparent !important;
        }
        &::-webkit-scrollbar-thumb {
            background-color: transparent !important; /* 스크롤바 썸 배경색을 설정 (생략하면 기본 값) */
        }
    }

    .default-scrollbar {
        /* width */
        &::-webkit-scrollbar {
            width: 0.333rem !important;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: transparent !important;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: #404040 !important;
            border-radius: 0.167rem !important;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: #404040 !important;
        }
    }
}
