//재석 작업
html {
    font-size: 1vw; //1.33vw;//16px;
}
html,
body {
    height: 100%;
    -webkit-overflow-scrolling: touch;
}
//제민님 작업
body {
    position: relative;
    background-color: rgba(0, 0, 0, 1);
    color: rgba(255, 255, 255, 1);
    > div#__next {
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: stretch;

        & > main {
            flex-grow: 1;
        }
    }
}
header {
    top: 0;
}
main {
    // margin-top: 6.667rem;
}

html,
body a,
button,
dd,
div,
dl,
dt,
html,
input,
label,
li,
p,
span,
textarea,
ul,
th,
td {
    font-family: 'Pretendard', -apple-system, BlinkMacSystemFont, 'Roboto', Segoe UI, Oxygen, Ubuntu, Cantarell, Open Sans, Helvetica Neue, sans-serif;
}
blockquote,
body,
dd,
dl,
dt,
fieldset,
figure,
hr,
html,
iframe,
legend,
li,
ol,
p,
pre,
textarea,
a,
button,
ul,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
}
h2 {
    margin: 0.83em 0;
    padding: 0;
}
li {
    list-style: none;
}

input,
textarea {
    color: #fff;
    background-color: #2e2e2e;
    border-style: solid;
    border-color: #525252;
    border-width: 0.5px;
    border-radius: 3px;
    box-sizing: border-box;
    &::placeholder {
        color: #4d4d4d;
    }
    &:hover,
    &:focus {
        outline: none;
        border-color: #ababab;
    }
}
textarea {
    resize: none;
}

.underline {
    text-decoration: underline;
}

//BG컬러
.gray-bg {
    background-color: #4e4e4e;
    border-color: #4e4e4e;
    color: #a3a3a3;
}
.white-bg {
    background-color: #dedede;
    border-color: #fff;
    color: #000;
}
//폰트컬러
.gray {
    color: #888;
}
.font-bold {
    font-weight: bold;
}
//레이아웃
.mt-30 {
    margin-top: 30px !important;
}
.mt-40 {
    margin-top: 40px;
}

.mb-30 {
    margin-bottom: 30px !important;
}
.mb-40 {
    margin-bottom: 40px !important;
}

.pt-0 {
    padding-top: 0px !important;
}
.pt-30 {
    padding-top: 30px !important;
}

.pb-50 {
    padding-bottom: 50px !important;
}
.pb-120 {
    padding-bottom: 120px !important;
}

.right15 {
    right: 15px !important;
}
//기타
.skip {
    display: inline-block;
    text-indent: -9999px;
}
.clear::after {
    content: '';
    display: block;
    clear: both;
}

.border0 {
    border-width: 0 !important;
}

a,
button {
    cursor: pointer;
}
a {
    // color: #000;
    text-decoration: none;
    color: inherit;
    // letter-spacing: -0.64px;
}
button {
    background-color: transparent;
    border: none;
    color: inherit;
    text-align: left;
    border-radius: 0;
}

//버튼스타일
.red {
    color: #ff153c !important;

    &-hover:hover,
    &-hover:focus {
        background-color: #ff153c;
        border-color: #ff153c;
        color: #fff;
    }
    &-bg {
        background-color: #ff153c;
        color: #fff;
    }
}
.blue {
    color: #008fe8;
}
.white-hover:hover,
.white-hover:focus {
    background-color: #fff;
    color: #000;
}
.gray-hover:hover,
.gray-hover:focus {
    background-color: #4e4e4e;
    border-color: #4e4e4e;
    color: #fff;
}

.mt-70 {
    margin-top: 6.667rem; //70px;
}
hr {
    width: 100%;
    height: 1px;
    border: none;
    background-color: #2e2e2e;
}
.bar-phrase {
    display: flex;
    align-items: center;
    &::after {
        content: '';
        display: inline-block;
        margin-left: 1.885em; //30px;
        // margin-right: 1em; //16px;
        padding-left: 1.885em; //30px;
        width: 1px;
        height: 1.333rem; //16px;
        border-left: solid 1px #4a4a4a;
    }
}

.layout__flex {
    display: flex;
    align-items: center;
    justify-content: center;
    &-title {
        h2,
        h3,
        h4,
        h5 {
            margin-bottom: 0 !important;
        }
    }
    &-left {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .layout__flex-lastright {
            margin-left: auto;
            margin-right: 0;
        }
    }
}

.icon {
    &-check {
        &::before {
            content: '';
            display: inline-block;
            width: 1.45em; //20px;
            height: 1.05em; //15px;
            background-image: url('../../img/icon_select_check_white.svg');
            background-size: 100% auto;
            background-position: 50% 70%;
            background-repeat: no-repeat;
            vertical-align: middle;
            opacity: 0.7;
        }
        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
}

.ReactModal__Body--open,
.ReactModal__Html--open {
    overflow: hidden;
}

.pagination {
    top: initial;
    bottom: 11em;
    right: initial;
    left: 4em;
    z-index: 1;
    > span {
        margin-right: 0.75em;
    }
    &_dot {
        width: 0.67em;
        height: 0.67em;
    }
}
.slide {
    &_lay {
        background-position: 50% 50%;
        background-size: 120% auto;
    }
    &_left {
        left: 0;
        // transform: rotateY(180deg) translateY(-50%);
        background-image: url('/img/icon_slide_left.svg');
    }
    &_right {
        right: 0;
        background-image: url('/img/icon_slide_right.svg');
    }
}

.layout {
    &__flex {
        display: flex;
        justify-content: center;
        align-items: center;
        &-left {
            justify-content: flex-start;
        }
        &-right {
            justify-content: flex-end;
        }
    }
}

.scrollbar-none {
    -ms-overflow-style: none;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }
}
