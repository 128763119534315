.Layout__safe-area {
    padding-right: var(--safe-area-inset-right, 0);
    padding-bottom: var(--safe-area-inset-bottom, 0);
    padding-left: var(--safe-area-inset-left, 0);
}

.Layout__safe-area--android {
    padding-top: 25px;

    @media (orientation: landscape) {
        padding-bottom: 25px;
        padding-left: 25px;
    }
}

.KBO-Header {
    padding-top: var(--safe-area-inset-top, 0);

    &:after {
        content: ' ';
    }
}

.KBO-Header--android {
    padding-top: 25px;
}

// iOS Safe Area 이슈를 임시 대응합니다. :(...
.KBO-Header--webview {
    visibility: hidden;
    opacity: 0;

    &.visible {
        visibility: visible;
        opacity: 1;
    }
}
