.select_layout1 {
    // width: 50%;
    z-index: 10;
    .select_layout__single-value,
    .select_layout__placeholder {
        position: static;
        top: auto;
        left: auto;
        transform: none;
        max-width: none;
        color: rgba(255, 255, 255, 1);
    }

    .select_layout__control {
        opacity: 0.7;
        background-color: transparent;
        .select_layout__indicator {
            &:after {
                content: '';
                width: 0.95em;
                height: 0.95em;
                background-image: url('/img/icon_arrow_down.svg');
                background-size: 190% auto;
                background-position: 70% 60%;
                background-repeat: no-repeat;
                // transition: transform 0.3s;
                transform-origin: center;
            }
        }
        .select_layout__indicator-separator {
            display: none;
        }
        svg {
            transition: 0.2s transform;
            display: none;
        }
        &:hover,
        &--is-focused,
        &--menu-is-open {
            opacity: 1;
            font-weight: 500;
            border-color: #fff;
            border: 0;
            box-shadow: none;
        }
        &--menu-is-open {
            svg {
                transform: rotate(180deg);
            }
            .select_layout__indicator {
                &:after {
                    transform: rotate(180deg);
                }
            }
        }
    }
    .select_layout__menu {
        width: auto;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.7);
        border: solid 1px #4d4d4d;
        border-radius: 0;
        background-color: #191919;
        font-size: 0.95em;

        &-list {
            padding: 1em 0.75em;
            // padding: 1em;
            display: grid;
            grid-template: repeat(6, auto) / auto;
            grid-auto-flow: column;
            // gap: 0.85em 3em;
        }
    }
    .select_layout__option {
        padding: 0.5em 1.5em;
        color: rgba(255, 255, 255, 0.7);
        white-space: nowrap;
        background-color: transparent;
        &:active,
        &:hover {
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            background-color: transparent;
        }
        &--is-selected,
        &--is-focused {
            font-weight: 500;
            color: rgba(255, 255, 255, 1);
            background-color: transparent;
        }
        &--is-selected {
            &::after {
                content: '';
                display: inline-block;
                position: absolute;
                margin-left: 0.3em;
                width: 0.95em;
                height: 0.95em;
                transform: translate3d(0, 0.2em, 0);
                background-image: url('/img/icon_select_check.svg');
                background-size: 190% auto;
                background-position: 50% 60%;
                background-repeat: no-repeat;
                z-index: 10;
            }
        }
    }
    .css-46a2gz-control {
        border-width: 0;
        .btn_brand_go.css-1wa3eu0-placeholder {
            color: rgba(255, 255, 255, 0.6);
            &::after {
                content: '+';
                display: inline-block;
                margin-left: 0.75vw;
            }
        }
    }
    .css-m1lywi-control {
        // border-color: rgba(255, 255, 255, 1);
    }
    .select_layout__value-container {
        padding: 2px 0;
    }
}
