.SocialMediaShareList {
    display: flex;

    position: absolute;
    right: 0;
    bottom: -1.875rem;
    opacity: 0;

    padding: 1.5rem 1.167rem;

    border-radius: 0.25rem;

    transition: opacity 200ms, transform 200ms;
    transform: translateY(110%);

    background-color: rgba(255, 255, 255, 0.9);

    // backdrop-filter 사용 시 DRM 이미지 캡쳐되는 이슈가 있습니다. -- https://tving.atlassian.net/browse/TVING-13241
    //filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.00));

    &.position-start {
        right: auto;
        left: 0;
    }

    &.position-end {
        right: 0;
        left: auto;
    }

    &:after {
        content: ' ';

        position: absolute;
        top: 0.5px;
        right: 0;
        width: 1.4rem;
        height: 0.9rem;

        background-color: inherit;

        transform: translate(-0.7rem, -98%);

        clip-path: polygon(50% 0%, 100% 100%, 0% 100%);
        //filter: inherit;
    }

    &.position-start:after {
        right: auto;
        left: 0;

        transform: translate(0.7rem, -98%);
    }

    & > svg {
        z-index: -1;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        width: 100%;
        height: 100%;

        & > g > path {
            //background-color: rgba(255, 255, 255, 0.15);
            //backdrop-filter: blur(25px);
            //filter: drop-shadow(0px 5px 10px rgba(0, 0, 0, 0.00));
        }
    }

    & > button {
        width: 3rem;
        height: 3rem;
        margin: 0 0.5rem;
    }


}

.SocialMediaShareList--active {
    opacity: 1;

    transform: translateY(100%);
}

.clip {
    height: 6.75rem;
    transform: translateY(110%);

    @media (orientation: landscape) {
        transform: translateY(-100%);

        &:after {
            top: auto;
            bottom: 0;
            transform: translate(-0.7rem, 98%) rotate(180deg);
        }

        &.position-start:after {
            transform: translate(0.7rem, 98%) rotate(180deg);
        }
    }
}

.ClipSocialMediaShareList--active {
    opacity: 1;
    transform: translateY(100%);

    @media (orientation: landscape) {
        //bottom: auto;
        transform: translateY(-110%);
    }
}
