// @import './assets/_reset';
@import './assets/_var';
@import './assets/_resolution';
@import './assets/_transition';
@import './assets/page';
@import './assets/player.scss';
@import './assets/contextmenu';

.cjp-root {
    position: relative;
    width: 100%;
    height: 100%;
    //noinspection CssInvalidPropertyValue
    overflow: -moz-scrollbars-none;
    user-select: none;
}
.cjp-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    color: #fff;
    background-color: #000;
    @include mobile {
        * {
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
    }

    ul,
    p,
    li {
        // margin: 0;
        // padding: 0;
    }
    em {
        font-style: normal;
    }

    .cjp__video {
        transition: opacity 0.1s;
    }

    &.mode-audio:not(.ca) {
        .cjp__video {
            .cjp-container {
                opacity: 0;
            }
        }
        .cjp__ui {
            .cjp__ui-poster {
                opacity: 1;
            }
        }
    }

    &.mode-tv {
        width: 100%;
        height: 100%;
        padding-bottom: 0;
    }

    &.playing {
        .cjp__video {
            /* transition-delay: 1s; */
            transition: opacity 0.1s;
            opacity: 1;
        }
        .cjp__ui-poster {
            opacity: 0;
        }
    }
    &.cjp-loading,
    &.cjp-loading,
    &.media-loading {
        .cjp__video {
            opacity: 0;
        }
    }
    &.playlist.seeking {
        .cjp__video {
            transition-delay: 0s;
            transition: opacity 0s;
            opacity: 0;
        }
    }
    &.startseek {
        .cjp__video {
            transition-delay: 0s !important;
            transition: opacity 0s !important;
            opacity: 0 !important;
        }
    }

    .cjp__video {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        opacity: 0;
        .cjp-ad-id3.show {
            opacity: 0;
        }
    }
    :after,
    :before,
    * {
        box-sizing: border-box;
    }
}
/* Contorl 재생중에 기 */
.cjp__ui {
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
    bottom: -1px;
    width: 100%;

    .cjp-wrap.before-ready & {
        > * {
            visibility: hidden;
        }
    }

    .cjp-wrap.playing.fullscreen & {
        cursor: none;
    }
    &.hover-ui-hide:hover {
        cursor: default !important;
    }
    .cjp__ui-bottom-con {
        transition-delay: 0s;
        transition: $in-motion;
        /* .cjp-wrap.media-loading &, */
        .cjp-wrap.uihide & {
            transition: $out-motion;
            transition-delay: 0s;
            transform: translate(0, $con-height);
            opacity: 0;
            .progress-bar:before {
                visibility: hidden;
            }
        }
        .cjp-wrap.uihide.pc & {
            $con-height: $con-height + 8;
            transform: translate(0, $con-height);
        }
    }
    .cjp__ui-control,
    .cjp__ui-ad .ca-bottom {
        transition-delay: 0s;
        transition: $in-motion;
        /* .cjp-wrap.media-loading &, */
        .cjp-wrap.uihide & {
            transition: $out-motion;
            transition-delay: 0s;
            transform: translate(0, 0);
            opacity: 0;
            .progress-bar:before {
                visibility: hidden;
            }
        }
        .cjp-wrap.uihide.pc & {
            $con-height: $con-height + 8;
            transform: translate(0, 0);
        }
    }
    .cjp__ui-top-con {
        transition-delay: 0s;
        transition: $in-motion;
        /* .cjp-wrap.media-loading &, */
        .cjp-wrap.uihide & {
            transition: $out-motion;
            transition-delay: 0s;
            transform: translate(0, 0);
            opacity: 0;
        }
        .cjp-wrap.uihide.pc & {
            transform: translate(0, 0);
        }
    }
    .cjp__ui-bottom-con {
        @include mobile {
            transition-delay: 0s;
            transition: $in-motion;
        }
        .cjp-wrap.uihide & {
            @include mobile {
                transition: $out-motion;
                transition-delay: 0s;
                transform: translate(0, 38px);
                opacity: 0;
            }
        }
    }
    .cjp__ui-ad .ca-bottom {
        @include mobile {
            transition-delay: 0s;
            transition: $in-motion;
        }
        .cjp-wrap.uihide & {
            @include mobile {
                transition: $out-motion;
                transition-delay: 0s;
                transform: translate(0, 0);
                opacity: 0;
            }
        }
    }
    .cjp__ui-top-con {
        @include mobile {
            transition-delay: 0s;
            transition: $in-motion;
        }
        .cjp-wrap.uihide & {
            @include mobile {
                transition: $out-motion;
                transition-delay: 0s;
                transform: translate(0, -0px);
                opacity: 0;
            }
        }
    }
    .cjp__ui-bottom-con,
    .cjp__ui-ad .ca-bottom {
        .cjp-wrap.playing & {
            opacity: 1 !important;
        }
    }
    /* &.hover-ui-hide:hover, */
    &.hover-ui-hide.hover {
        .cjp__ui-control,
        .cjp__ui-bottom-con,
        .cjp__ui-top-con,
        .cjp__ui-ad .ca-bottom {
            .cjp-wrap.uihide & {
                transition-delay: 0s;
                transition: $in-motion;
                transform: translate(0, 0);
                opacity: 1;
                .progress-bar:before {
                    visibility: visible;
                }
            }
        }
    }
}
.cjp__ui-top-con {
    position: absolute;
    top: 0;
    z-index: 10;
    width: 100%;

    &__right {
        position: absolute;
        top: 0;
        right: 10px;
        height: 48px;
        display: flex;
        align-items: center;
        .cjp-live-timemachine {
            svg {
                width: 40px;
                height: 40px;
            }
        }
        svg {
            filter: drop-shadow(0px 0 3px rgba(0, 0, 0, 0.5));
        }
    }
}
.cjp__ui-bottom-con {
    position: absolute;
    bottom: $con-height;
    width: 100%;
    pointer-events: none;

    .cjp-wrap.pc & {
        bottom: calc(#{$con-height} + 4px);
    }
    @include mobile {
        bottom: 38px;
    }
    .skip-opening {
        position: absolute;
        right: $control-pd;
        bottom: 1em;
        pointer-events: visible;
        border-radius: $bdr-sm;
    }
    .next-episode {
        position: absolute;
        right: $control-pd;
        bottom: 1em;
        border-radius: $bdr-sm;
        pointer-events: visible;
        button {
            padding: 0 0.5em 0 1em;
        }
    }
    .action-view {
        position: absolute;
        right: 1em;
        bottom: 1em;
        height: 30px;
        display: flex;
        align-items: center;
        padding: 0 0.5em;
        background-color: rgba(15, 15, 15, 0.85);
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        border-radius: $bdr-sm;
        outline: none;
        font-weight: 700;
        font-size: 0.9em;
        color: rgba(255, 255, 255, 1);
        z-index: 200;

        &__volume {
            height: 40px;
            padding: 0 1.25em;
            border-radius: 100px;
            .volume-icon {
                display: flex;
                align-items: center;
                /* transform: scale(0.8); */
            }
            .volume-bar {
                position: relative;
                width: 50px;
                height: 2px;
                margin: 0 0 0 0.25em;
                background-color: rgba(255, 255, 255, 0.5);

                .volume-per {
                    position: absolute;
                    left: 0;
                    height: 2px;
                    background-color: rgba(255, 255, 255, 1);
                    /* transition: width 0.1s; */
                }
            }
        }
    }
    .bottom-btn {
        height: 40px;
        display: flex;
        align-items: center;
        padding: 0 1em;
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        outline: none;
        font-weight: 500;
        font-size: 0.9em;
        color: rgba(255, 255, 255, 1);
        cursor: pointer;
        @include mobile {
            height: 30px;
            padding: 0 0.75em;
            font-size: 0.7em;
            i {
                font-size: 1em;
            }
        }
    }
}
.cjp__ui-bigplay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    flex-direction: column;
    pointer-events: none;
    user-select: none;

    .cjp-wrap:not(.first):not(.mobile) & {
        display: none;
    }
    .cjp-wrap.pc.playing.media-paused.playlist & {
        display: flex;
    }
    .cjp-wrap.mode-audio & {
        background-color: transparent;
    }

    .cjp-wrap.ca &,
    .cjp-wrap.preview.playing &,
    .cjp-wrap.seeking &,
    .cjp-wrap.cjp-loading &,
    .cjp-wrap.media-loading & {
        visibility: hidden !important;
        opacity: 0 !important;
        transition: visibility 0s linear 0s, opacity 0s, transform 0s !important;
        transform: perspective(1px) translate3d(0, 0, 0) !important;
    }
    .cjp-wrap.mobile.media-paused & {
        visibility: visible !important;
        opacity: 1 !important;
        transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s !important;
        transform: perspective(1px) translate3d(0, 0, 0) !important;
    }

    .bigplay-inner {
        display: flex;
        .bigplay-btn-prev {
            &.ani {
                svg > path.st0 {
                    transition: transform 0s;
                    transform: rotate(360deg) scale(1);
                }
            }
        }
        .bigplay-btn-next {
            &.ani {
                svg > path.st0 {
                    transition: transform 0s;
                    transform: rotate(-360deg) scale(1);
                }
            }
        }
        .bigplay-btn-prev,
        .bigplay-btn-next {
            display: none;
            border-width: 22px;
            svg > path.st0 {
                transform: rotate(0) scale(1);
                transform-origin: 20px 22px;
                transition: transform 0.4s cubic-bezier(0.68, 0.005, 0.265, 1.25);
            }

            @include res-s {
                width: rem(62px);
                height: rem(62px);
                border-width: rem(12px);
            }
            svg {
                width: rem(42px);
                height: rem(42px);
            }
            &.is-music {
                border-width: 25px;

                @include res-s {
                    width: 66px;
                    border-left-width: 15px;
                    border-right-width: 15px;
                }
                svg {
                    width: 36px;
                    height: 36px;
                }
            }
        }
        .cjp-wrap.pc:not(.live):not(.preview):not(.playlist):not(.first):not(.mode-live) &,
        .cjp-wrap.pc.mode-timemachine & {
            .bigplay-btn-prev,
            .bigplay-btn-next {
                display: block;
            }
        }
        .bigplay-btn-toggle {
            // padding: 0.25em 0;
            border-width: rem(22px);
            @include res-s {
                width: rem(66px);
                height: rem(66px);
                border-width: rem(12px);
            }
            svg {
                width: rem(42px);
                height: rem(42px);
            }
        }
        @include modeFixed {
            transform: scale(0.8);
        }
        @include mobile-and-xs {
            // transform: scale(0.8);
        }
        @include res-s {
            button {
                // width: 72px;
                // height: 72px;
                border-left-width: 15px;
                border-right-width: 15px;
            }
        }

        > * {
            opacity: 0.85;
            transition: opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);

            .cjp-wrap:not(.touch) & {
                &:hover {
                    opacity: 1;
                    transform: scale(1.05);

                    @include res-s {
                        transform: scale(0.95);
                    }
                    @include res-xs {
                        transform: scale(0.85);
                    }
                }
                &:active {
                    outline: 0;
                    transform: scale(0.8);

                    @include res-s {
                        transform: scale(0.8);
                    }
                    @include res-xs {
                        transform: scale(0.7);
                    }
                }
            }
        }
    }
    button {
        overflow: visible;
        position: relative;
        width: 86px;
        height: 86px;
        margin: 0 10px;
        border: 25px solid transparent;
        border-radius: 9px;
        svg {
            width: 100%;
            height: 100%;
            text {
                color: #fff;
            }
        }
    }
    button {
        position: relative;
        pointer-events: visible;
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        transform: scale(1);
        // outline: 0;
        cursor: pointer;
        transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;

        @include mobile-and-s {
            /* transform: scale(0.8); */
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        @include res-s {
            transform: scale(0.9);
        }
        @include res-xs {
            transform: scale(0.8);
        }
        @include modeFixed {
            padding: 0;
            /* transform: scale(0.8); */
            -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
        }
        svg {
            path,
            rect,
            polyline {
                fill: #fff;
            }
            // filter: drop-shadow(0px 0 3px rgba(0, 0, 0, 0.5));
            transition: transform 0.4s cubic-bezier(0.215, 0.61, 0.355, 1);
        }
        &.hover {
            svg {
                transition: transform 0.2s cubic-bezier(0.215, 0.61, 0.355, 1);
                /* transform: scale(0.7); */
            }
        }
    }
    button div {
        display: none;
        position: absolute;
        bottom: -1.75em;
        left: 50%;
        font-weight: 700;
        /* font-family: sans-serif; */
        font-size: 1em;
        color: #fff;
        transition: opacity 0.2s;
        transform: translate(-50%, 0);
        -webkit-overflow-scrolling: touch;
        @include colorwhiteShadow();
        @include mobile {
            font-size: rem(15px);
        }
        @include modeFixed {
            font-size: 0.9em;
        }
        .cjp-wrap.first & {
            display: block;
        }
        /* font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif; */
    }

    &:hover {
        button,
        div {
            opacity: 1;
        }
    }

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
    transform: perspective(1px) translate3d(0, 0, 0);

    .cjp-wrap.before-ready &,
    .cjp-wrap.pc.startseek & {
        visibility: hidden !important;
        opacity: 0 !important;
        transition: visibility 0s linear 0s, opacity 0s, transform 0s !important;
        transform: perspective(1px) translate3d(0, 0, 0) !important;
    }
    .cjp-wrap.pc.first:not(.before-ready) &,
    .cjp-wrap.pc.media-paused &,
    .cjp-wrap.pc.playing:not(.uihide):not(.ca) &,
    .cjp-wrap.pc.playing:not(.mobile):not(.ca):not(.preview):not(.playlist) .hover-ui-hide.hover:not(.is-starter-autoplay) &,
    .cjp-wrap.pc.media-loading:not(.ca):not(.mobile):not(.preview):not(.playlist) .hover-ui-hide.hover:not(.is-starter-autoplay) & {
        visibility: visible !important;
        opacity: 1 !important;
        transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s !important;
        transform: perspective(1px) translate3d(0, 0, 0) !important;
    }
    .cjp-wrap.pc.playing:not(.media-paused) & {
        button {
            // cursor: default !important;
        }
    }
    .cjp-wrap.mobile.first &,
    .cjp-wrap.mobile.media-loading &,
    .cjp-wrap.mobile.media-paused &,
    .cjp-wrap.mobile.playing .hover-ui-hide.hover &,
    .cjp-wrap.mobile.media-loading .hover-ui-hide.hover & {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
        transform: perspective(1px) translate3d(0, 0, 0);
    }
}
.cjp__ui-dimm {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.cjp__ui-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    pointer-events: none;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: opacity 0.5s, transform 0.5s;
        opacity: 0;
        transform: scale(1.1);

        .cjp-wrap.ie & {
            height: auto;
            transform: scale(1);
        }
    }
    img[lazy='loaded'] {
        opacity: 1;
        transform: scale(1);
    }

    .cjp-wrap.first & {
        opacity: 1;
    }
}
.cjp__ui-control {
    position: absolute;
    left: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    width: 100%;
    height: $con-height;
    padding: 0 15px;
    box-sizing: border-box;
    font-size: 0.9em;
    user-select: none;
    visibility: visible;
    opacity: 1;
    z-index: 10;
    transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
    transform: translate(0, 0);
    .cjp-wrap.new-ui & {
        padding: 0 rem(12.5px);
        &.mini {
            padding: 0;
        }

        @include res-xs {
            padding: 0 ($control-pd - 15);
        }

        @include mobile {
            padding: 0 1em;
        }
    }
    .cjp-wrap.pc & {
        height: $con-height;
        padding-bottom: 2px;

        &:after {
            pointer-events: none;
            // content: '';
            position: absolute;
            top: $con-height * 2;
            width: 100%;
            height: 1px;
            background-color: rgb(0, 255, 123);
        }
    }

    /* 마지막 버튼 툴팁 왼쪽으로 좀 옮기기 */
    > * {
        &:first-child {
            > button[aria-label],
            &[aria-label] {
                &:before {
                    left: 7px !important;
                    .cjp-wrap.ie & {
                        transform: translate(0, -15px) !important;
                    }
                }
            }
        }
        @mixin rightToolTip {
            &:last-child,
            .player.fixed .cjp-wrap:not(.fullscreen) &.con__fullscreen {
                > button[aria-label],
                &[aria-label] {
                    &:before {
                        left: auto !important;
                        right: 7px !important;
                        .cjp-wrap.ie & {
                            transform: translate(0, -15px) !important;
                        }
                    }
                }
            }
        }
    }

    .cjp-wrap.dragging & {
        > * {
            pointer-events: none;
        }
    }

    .cjp-wrap.new-ui & {
        @include mobile {
            height: $con-mobile-height;
        }
    }

    .cjp-wrap.first & {
        @include res-xs {
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
            transform: translate(0, 0);
        }
    }

    .cjp-wrap.before-ready & {
        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
        transform: translate(0, 0);
    }
    .cjp-wrap.mobile-quality & {
        &:not(.cjp__ui-control-bg) {
            visibility: hidden;
            opacity: 0 !important;
        }
    }

    &-bg {
        z-index: auto;
        &:before {
            content: '';
            position: absolute;
            bottom: -1px;
            left: 0;
            width: 100%;
            height: 13.33333rem;
            flex-shrink: 0;
            pointer-events: none;
            user-select: none;
            opacity: 1;
            // background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==) 0 120px;
            background-image: linear-gradient(to top, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 0) 100%);
            transition: $in-motion;
            z-index: -1;

            .cjp-wrap.mobile-quality & {
                /* transition: $out-motion;
          transform: translate(0, -$con-height); */
            }
            .cjp-wrap.first.mode-mobile-no-quality.mobile & {
                opacity: 0;
            }
        }
    }
    &:after {
        /* content: ''; */
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: red;
    }
    button {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        // min-width: 40px;
        height: 2.33333rem;
        margin: 0;
        color: #fff;
        background: transparent;
        border-radius: 3px;
        transition: color 0.2s;
        overflow: visible;
        border: none;

        min-width: 0;
        padding: 0 rem(10.5px);

        .cjp-wrap.new-ui & {
            // height: 28px;
            // height: $con-height;
            // min-width: 50px;
            // width: 3.5em;
            padding: 0 0.835rem;
        }
        > * {
            transition: color 0.2s;
        }
        @include mobile {
            svg {
                /* transform: scale(.8); */
            }
        }
        .cjp-wrap.mode-keyboard-focus & {
            &:focus {
                box-shadow: 0 0 0 3px rgba($color-tvn, 0.3);
                outline: 0;
            }
        }
        &:hover {
            &.control-button:before {
                content: '';
                position: absolute;
                width: auto;
                height: auto;
                top: -1.666666rem;
                bottom: -0.5rem;
                left: -0.5rem;
                right: -0.5rem;
            }

            svg {
                // animation-name: pulse;
                // animation-timing-function: ease-in-out;
                // animation-duration: .5s;
                // animation-fill-mode: both;
            }
            > * {
                transform: scale(1.2) translateZ(0);
            }
        }
        &.is-link.is-active,
        &:active {
            border-color: transparent;
            > * {
                transform: scale(1) translateZ(0);
            }
        }
        > * {
            transition: transform 0.2s !important;
            transform: translateZ(0);
        }

        > .not-transform {
            transition: none !important;
            transform: none !important;
        }

        svg {
            position: relative;
            pointer-events: none;
            top: 1px;
            filter: drop-shadow(0px 0 3px rgba(0, 0, 0, 0.5));
            -webkit-backface-visibility: hidden;
            // transition: transform .2s cubic-bezier(0.42, 0, 0.58, 1);
            transform: translateZ(0);

            width: auto;
            height: 2.33333rem;

            path,
            rect,
            polygon {
                fill: #fff;
                transition: fill 0.1s;
            }
        }
    }
    .con__volume button svg {
        width: 1.936rem;
    }
    .con__togglePlay {
        .cjp-wrap.new-ui & {
            position: relative;
            // top: -0.4px;
            // min-width: 30px;
            // padding: 0 0.75em 0 0;
        }
        .svg-play {
            position: relative;
            // top: 1px;
        }
        -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    }
    .con__space-center {
        .cjp-wrap.new-ui & {
            display: block !important;
            flex: 1;
        }
    }
    .cjp-con-progress {
        flex: 1;

        &.live {
            .progress-bar-dimm-bg,
            .progress-bar {
                width: 100%;
            }
        }

        .remain-time {
            color: #ffffff;
            font-size: 1.33333rem;
            font-style: normal;
            font-weight: 400;
            line-height: normal;

            position: absolute;
            right: -6rem;
            top: -1rem;
        }

        // pointer-events: none;
        .cjp-wrap.playing & {
            pointer-events: visible;
        }

        .cjp-wrap.new-ui & {
            position: absolute;
            top: -1px;
            left: $control-pd;
            right: $control-pd;
            right: ($control-pd + 6);
            z-index: -1;

            &.is-live {
                right: $control-pd;
            }

            &.mini {
                left: 1rem;
                right: 1rem;
            }

            @include res-xs {
                left: ($control-pd - 6);
                right: ($control-pd - 6);
            }

            @include mobile {
                left: ($control-pd);
                right: ($control-pd);
            }

            .progress-bar {
                left: 0;
                right: 0;
            }
        }
        .cjp-wrap.new-ui.mode-live &,
        .cjp-wrap.new-ui.mode-progress-hide & {
            display: none;
        }

        &.dragging {
            .progress-bar {
                cursor: grabbing;
                //noinspection CssInvalidPropertyValue
                cursor: -moz-grabbing;
                //noinspection CssInvalidPropertyValue
                cursor: -webkit-grabbing;
                .progress-per {
                    .progress-info {
                        visibility: visible;
                        opacity: 1;
                        transition: visibility 0s linear 0s, opacity 0.2s, top 0.2s;
                    }
                }
            }
        }

        // 오버시 프로그래스바 두꺼워지는 효과
        &:hover,
        &.dragging {
            .progress__white-dot {
                transform: scale(1.5);
            }
            .progress-bar-dimm-bg {
                transform: scaleY(1.5);
            }
            .progress-bar {
                &:after {
                    transform: scaleY(1.5);
                }
                .progress__yellow-dot,
                .progress__point-dot,
                .progress__game-start-dot {
                    transform: scale(1.5);
                }
                .progress-per {
                    &:before {
                        transform: scaleY(1.5);
                    }
                    .progress-pointer {
                        transform: scale(1.2) translate(0, -50%);
                    }
                    .progress-info {
                        visibility: visible;
                        opacity: 1;
                        transition: visibility 0s linear 0s, opacity 0.2s, top 0.2s;
                        &:after {
                            transform: translateY(-5px);
                        }
                    }
                }
            }
            .progress-timeline {
                div {
                    &:before {
                        transform: scale(1.5);
                    }
                }
            }
        }

        .progress-timeline {
            pointer-events: none;
            div {
                position: absolute;
                top: 0;
                left: 0;
                width: $progress-height;
                height: $progress-height;
                transition: all 0.2s;
                // z-index: 1x0;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background-color: #fff;
                    border-radius: 100px;
                    transition: all 0.2s;
                }

                &:after {
                    content: attr(aria-value);
                    position: absolute;
                    left: -50px;
                    bottom: 0.75em;
                    width: 100px;
                    text-align: center;
                    white-space: nowrap;
                    font-size: rem(12px);
                    opacity: 0.5;
                    transition: all 0.2s;
                }

                &:nth-child(1) {
                    left: 0;
                    &:before {
                        transform-origin: left;
                    }
                    &:after {
                        left: 0;
                        text-align: left;
                    }
                }
                &:nth-child(2) {
                    left: 25%;
                }
                &:nth-child(3) {
                    left: 50%;
                }
                &:nth-child(4) {
                    left: 75%;
                }
                &:nth-child(5) {
                    left: auto;
                    right: 0;
                    &:before {
                        transform-origin: right;
                    }
                }
            }
        }
        .progress-bar-dimm-bg {
            content: '';
            position: absolute;
            top: -$progress-height / 2;
            left: 0;
            right: 0;
            // width: calc(100% - 6rem);
            height: $progress-height;
            background-color: rgba(255, 255, 255, 0.1);
            z-index: -1;
            transition: all 0.2s;

            @include mobile {
                top: -1px;
                height: 2px;
            }
            &:before {
                content: '';
                position: absolute;
                top: -15px;
                left: -10px;
                right: -10px;
                bottom: -15px;
                background-color: transparent;
            }
        }
        .progress-bar {
            position: absolute;
            top: -$progress-height / 2;
            left: 10px;
            right: 10px;
            // width: calc(100%);
            height: $progress-height;
            z-index: 1;
            transition: all 0.2s;
            /* cursor: -webkit-grab; */

            /* fallback if grab cursor is unsupported */
            /* cursor: move; */
            cursor: grab;
            //noinspection CssInvalidPropertyValue
            cursor: -moz-grab;
            //noinspection CssInvalidPropertyValue
            cursor: -webkit-grab;

            @include mobile {
                top: -1px;
                height: 2px;
            }

            .cjp-wrap.live:not(.mode-timemachine) &,
            .cjp-wrap.preview & {
                pointer-events: none;
            }

            &:before {
                content: '';
                position: absolute;
                top: -15px;
                left: -2px;
                right: -2px;
                bottom: -15px;
                background-color: transparent;
            }
            &:after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba(255, 255, 255, 0.4);
                z-index: -1;
                transition: all 0.2s;
            }

            .progress__yellow-dot {
                width: $progress-height;
                height: $progress-height;
                flex-shrink: 0;
                background-color: #ffdc3e;
                position: absolute;
                left: 0;
                top: 0;
                transition: transform 0.2s;
            }

            .progress__white-dot {
                width: 0.16667rem;
                height: $progress-height;
                flex-shrink: 0;
                background-color: #ffffff;
                position: absolute;
                left: 0;
                top: 0;
                transition: transform 0.2s;
                z-index: 1;
            }

            .progress-per {
                position: relative;
                max-width: 100%;
                height: 100%;
                pointer-events: none;

                &:before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: #{$color-tvn};
                    transition: all 0.2s;
                }

                $circleSize: 1.5rem;
                .progress-pointer {
                    position: absolute;
                    top: 50%;
                    right: -($circleSize / 2);
                    width: $circleSize;
                    height: $circleSize;
                    background-color: #{$color-tvn};
                    border-radius: $circleSize;
                    transform: translate(0, -50%);
                    opacity: 1;
                    transform-origin: 0 1px;
                    transition: all 0.2s;
                    z-index: 1;
                    @include mobile {
                        // $circleSize: 9px;
                        // right: -($circleSize / 2);
                        // width: $circleSize;
                        // height: $circleSize;
                        // border-radius: $circleSize;
                    }
                    .cjp-wrap.playing & {
                        opacity: 1;
                    }
                    .cjp-wrap.live:not(.mode-timemachine) &,
                    .cjp-wrap.preview & {
                        opacity: 0;
                    }
                }
                .progress-info {
                    position: absolute;
                    bottom: 12px;
                    pointer-events: none;
                    visibility: hidden;
                    opacity: 0;
                    transition: visibility 0s linear 0.2s, opacity 0.2s, top 0.2s;

                    &:after {
                        // content: attr(aria-value);
                        display: block;
                        bottom: 0;
                        height: 2em;
                        line-height: 2em;
                        padding: 0 0.5em;
                        font-size: rem(12px);
                        white-space: nowrap;
                        background-color: rgba(15, 15, 15, 0.85);
                        border-radius: 2px;
                        color: rgba(255, 255, 255, 1);
                        box-shadow: 0 4px 5px -2px rgba(0, 0, 0, 0.2), 0 7px 10px 1px rgba(0, 0, 0, 0.14), 0 2px 16px 1px rgba(0, 0, 0, 0.12) !important;
                        transition: transform 0.2s;
                    }

                    .cjp-wrap.mode-timemachine & {
                        visibility: visible;
                        opacity: 1;
                        &:after {
                            content: attr(aria-value);
                        }
                        // transform: translate(0%, 0);
                    }

                    @include mobile {
                        top: -50px;
                    }
                }
            }
        }
    }
    .con__mobile-currentTime,
    .con__mobile-duration {
        position: relative;
        font-size: 0.7em;
        color: #fff;
        padding: 0 10px 0 0;
        .cjp-wrap.new-ui & {
            display: none !important;
        }
    }
    .con__mobile-currentTime {
        padding: 0 0 0 0;
    }
    .con__time-new {
        .cjp-wrap.mobile &,
        .cjp-wrap.preview &,
        .cjp-wrap.playlist & {
            display: none;
        }
        .cjp-wrap.new-ui & {
            display: flex;
        }
        .cjp-wrap.new-ui.mode-live & {
            // display: none;
            &:after {
                content: 'LIVE';
                color: rgba(255, 255, 255, 0.8);
                @include colorwhiteShadow();
            }
            > div {
                display: none;
            }
        }

        align-items: center;
        top: 0;
        left: 1em;
        // height: 28px;
        display: flex;
        font-size: 1.5em;
        margin-left: 1em;
        padding: 0 rem(12.5px);
        color: rgba(255, 255, 255, 0.5);
        pointer-events: none;
        line-height: 1.25;
        /* text-shadow: 0 0 3px rgba(0, 0, 0, 0.5); */

        @include res-xs {
            font-size: rem(13px);
        }
        @include mobile {
            font-size: rem(13px);
            margin-left: 0;
        }

        transition: transform 0.2s;
        transform: translate(0, 0);
        &.volume-size {
            transform: translate(70px, 0);

            @include mobile-and-xs {
                transform: translate(50px, 0);
            }
        }

        .currentTime {
            color: rgba(255, 255, 255, 0.8);
            @include colorwhiteShadow();

            &:after {
                content: '/';
                padding: 0 0.4em;
                @include colorwhiteShadow();
                opacity: 0.5;

                .cjp-wrap.live:not(.mode-timemachine) &,
                .cjp-wrap.playlist & {
                    opacity: 0.8;
                    content: '~';
                }
                .cjp-wrap.mode-timemachine & {
                    content: ' ';
                }
            }
        }
        .duration {
            @include colorwhiteShadow();
            opacity: 0.5;
            .cjp-wrap.live &,
            .cjp-wrap.playlist & {
                opacity: 0.8;
            }
        }
    }
    .cjp-time {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        left: 10px;
        height: 24px;
        font-size: rem(11px);
        color: rgba(255, 255, 255, 0.7);

        .cjp-wrap.new-ui & {
            display: none;
        }
        .cjp-wrap.mobile & {
            display: none;
        }
        .currentTime {
            &:after {
                content: '/';
                padding: 0 0.2em;

                .cjp-wrap.live &,
                .cjp-wrap.playlist & {
                    content: '~';
                }
            }
        }
    }
    .con {
        $volume-container-width: 2.3333333333rem;
        $volume-container-height: 12.16667rem;
        &__volume {
            position: relative;
            display: flex;
            justify-content: center;
            align-items: center;
            //height: 3rem;

            & + .con__time-new {
                margin-left: 0;
            }

            .cjp-wrap.new-ui & {
                // height: 28px;
            }
            .cjp-wrap.mobile & {
                display: none;
            }
            > button {
                display: flex;
                justify-content: center;
                align-items: center;
                .icon-volume-mute {
                    position: relative;
                    top: 2px;
                    left: 1px;
                }
            }
            &:after {
                /* content: ''; */
                display: inline-block;
                width: 30px;
            }
            &:before {
                /* content: ''; */
                position: absolute;
                top: 17px;
                right: 15px;
                height: 15px;
                width: 1px;
                background-color: #5f6062;
            }
            &.dragging,
            &.hover,
            &:hover {
                button {
                    svg {
                        opacity: 1;
                    }
                }

                .con__volume--layer {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
                    /* transform: perspective(1px) translate3d(-50%, 0, 0); */
                    transform: translate(-50%, 0);
                }
                .con__volume--layer-horiz {
                    visibility: visible;
                    opacity: 1;
                    transition: visibility 0s, opacity 0.2s, transform 0.2s;
                    transition-delay: 0s, 0.1s;
                    /* transform: perspective(1px) translate3d(-50%, 0, 0); */
                    //transform: translate(0, -50%);
                    .volume-bar {
                        z-index: 2;
                    }

                    &:before {
                        content: '';
                        position: absolute;

                        width: $volume-container-width;
                        height: $volume-container-height + 3rem;
                        top: 0;

                        background-color: transparent;
                        z-index: 1;
                    }
                }
                & + .con__time-new {
                    transform: translate(5em, 0);

                    @include mobile-and-xs {
                        transform: translate(50px, 0);
                    }
                }
            }
            &.dragging {
                .volume-bar {
                    cursor: grabbing;
                    //noinspection CssInvalidPropertyValue
                    cursor: -moz-grabbing;
                    //noinspection CssInvalidPropertyValue
                    cursor: -webkit-grabbing;
                }
                .volume-per {
                    transition: height 0s !important;
                }
            }
            &--layer {
                position: absolute;
                left: 50%;
                bottom: 34px;
                padding: 2.1em 1.25em 0.75em;
                background-color: rgba(15, 15, 15, 0.95);
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
                transform: translate(-50%, 10px);
                box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;

                .volume-txt {
                    position: absolute;
                    top: 5px;
                    left: 50%;
                    color: rgba(255, 255, 255, 0.5);
                    font-size: 0.8em;
                    /* transform: perspective(1px) translate3d(-50%, 0, 0); */
                    transform: translate(-50%, 0);
                }
                .volume-bar {
                    position: relative;
                    height: 70px;
                    width: 2px;
                    background-color: rgba(255, 255, 255, 0.3);
                    cursor: grab;
                    //noinspection CssInvalidPropertyValue
                    cursor: -moz-grab;
                    //noinspection CssInvalidPropertyValue
                    cursor: -webkit-grab;

                    .volume-per {
                        position: absolute;
                        bottom: 0;
                        height: 50%;
                        width: 2px;
                        background-color: #{$color-tvn};
                        will-change: height;
                        transition: height 0.2s;

                        $circleSize: 10px;
                        .volume-pointer {
                            position: absolute;
                            top: -($circleSize / 2);
                            left: -($circleSize / 2 - 1);
                            width: $circleSize;
                            height: $circleSize;
                            background-color: #{$color-tvn};
                            border-radius: $circleSize;
                        }
                    }
                }
            }

            &--layer-horiz {
                position: absolute;
                //left: 4rem;

                bottom: -1.333rem;
                width: $volume-container-width;
                height: $volume-container-height;

                display: flex;
                flex-shrink: 0;
                align-items: center;
                visibility: hidden;
                opacity: 0;
                transition: visibility 0s, opacity 0.2s, transform 0.2s;
                transition-delay: 0s, 0s;
                transform: translate(0, -50%);

                background-color: #262626;
                border-radius: 5px;

                &:before {
                    // content: '';
                    position: absolute;
                    bottom: -20px;
                    left: 0;
                    width: 100%;
                    height: 20px;
                    z-index: -1;
                    background-color: red;
                }
                $volume-bar-width: 0.3333333333rem;
                $volume-bar-height: 9.5rem;
                .volume-bar {
                    position: relative;
                    left: ($volume-container-width - $volume-bar-width) / 2;
                    width: $volume-bar-width;
                    height: $volume-bar-height;
                    background-color: rgba(255, 255, 255, 0.4);
                    cursor: grab;
                    //noinspection CssInvalidPropertyValue
                    cursor: -moz-grab;
                    //noinspection CssInvalidPropertyValue
                    cursor: -webkit-grab;

                    @include mobile-and-xs {
                        width: 40px;
                    }
                    &:before {
                        $volume-height: $con-height - 12;
                        content: '';
                        position: absolute;
                        top: -1rem;
                        left: -2em;
                        right: -2em;
                        bottom: -1rem;
                        background-color: transparent;
                    }
                    .volume-per {
                        position: absolute;
                        bottom: 0;
                        left: 0;
                        width: 100%;
                        background-color: rgba(255, 255, 255, 1);
                        will-change: width;
                        transition: width 0.2s;

                        $circleSize: 1rem;
                        .volume-pointer {
                            position: absolute;
                            right: (($volume-bar-width - $circleSize) / 2);
                            top: -($circleSize / 2);
                            width: $circleSize;
                            height: $circleSize;
                            background-color: rgba(255, 255, 255, 1);
                            border-radius: $circleSize;
                        }
                    }
                }
            }
        }
    }
    .cjp-quality {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 3rem;
        // padding: 0 10px;
        font-size: 1.5rem;
        font-weight: 900;
        font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: #fff;

        .cjp-wrap.new-ui & {
            // height: 28px;
        }
        .cjp-wrap.mode-audio:not(.ca) & {
            display: none;
        }

        @include mobile-and-xs {
            // display: none;
        }

        @include mobile {
            padding: 0 0.5em;
        }
        button {
            font-weight: 900;
            font-family: Roboto, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
            &:before {
                font-weight: 400;
            }
            .cjp-wrap.new-ui & {
                @include colorwhiteShadow();
            }
            transition: color 0s;
            span {
                display: flex;
                align-items: flex-start;
                line-height: 0.7;
                transition: color 0.2s;
                em {
                    line-height: 0.7;
                    transition: color 0.2s;
                }
            }
            @include mobile {
                font-size: rem(13px);
                /* font-weight: 700; */
                em {
                    /* transform-origin: left top;
            transform: scale(0.9); */
                }
            }
            /* line-height: 1.215; */
            em {
                @include colorwhiteShadow();
                /* opacity: .75; */
                position: relative;
            }
        }

        em {
            padding: 0 0.5em 0 0.1em;
            font-size: 1rem;
            font-style: normal;
            color: rgba(255, 255, 255, 0.5);
        }
        .cjp-wrap.mobile & {
            svg {
                display: none;
            }
            button {
                color: #fff !important;
                span {
                    font-weight: 700;
                    line-height: 0.7;
                    em {
                        font-size: rem(10px);
                        font-weight: 700;
                        line-height: 0.7;
                        padding: 0 0 0 0.1em;
                        color: #fff !important;
                    }
                }
            }
        }

        &.is-show {
            button {
                // color: #{$color-tvn} !important;
                // color: var(--color-tvn) !important;
                &:before {
                    opacity: 0 !important;
                }
                em {
                    // color: #{$color-tvn} !important;
                    // color: var(--color-tvn) !important;
                }
                svg {
                    path,
                    polygon {
                        // fill: #{$color-tvn};
                        // fill: var(--color-tvn);
                    }
                }
            }
            .cjp-quality-menu {
                visibility: visible;
                opacity: 1;
                transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
                /* transform: perspective(1px) translate3d(-50%, 0, 0); */
                transform: translate(-50%, 0);
            }
        }
        .cjp-quality-menu {
            position: absolute;
            left: 50%;
            bottom: 6rem;
            padding: 0.75rem 1.5rem;
            border-radius: 3px;
            background-color: rgba(15, 15, 15, 0.95);
            visibility: hidden;
            opacity: 0;
            transition: visibility 0s linear 0.2s, opacity 0.2s, transform 0.2s;
            /* transform: perspective(1px) translate3d(-50%, 10px, 0); */
            transform: translate(-50%, 10px);
            box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.2), 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12) !important;

            &:before {
                content: '';
                position: absolute;
                bottom: -3rem;
                left: 0;
                width: 100%;
                height: 3rem;
                z-index: -1;
            }

            > button {
                position: relative;
                height: 2.6rem;
                margin: rem(4px) 0;
                transition: color 0.1s;
                cursor: default;
                display: flex;
                align-items: center;
                color: rgba(255, 255, 255, 0.5);
                line-height: 1;
                white-space: nowrap;
                font-size: 1.5rem;
                span {
                    display: flex;
                    align-items: flex-start;
                }
                em {
                    color: rgba(255, 255, 255, 0.5);
                    line-height: 1.2;
                }
                &:before {
                    content: '';
                    position: absolute;
                    top: -0.325rem;
                    bottom: -0.325rem;
                    left: -1.5rem;
                    right: -1.5rem;
                    background-color: #fff;
                    opacity: 0;
                    transition: opacity 0.1s;
                }
                &.selected,
                &:hover {
                    color: #fff;
                    em {
                        color: #fff;
                    }
                }
                &:hover:before {
                    opacity: 0.1;
                }
            }
        }
        @include modeFixed {
            display: none;
        }
        .con__quality-ani {
            display: flex;
            align-items: center;
        }
    }
    .con__popup {
        @include mobile-and-xs {
            display: none;
        }
        @include mobile {
            display: none;
        }
        @include modeFixed {
            display: none;
        }
    }
    .con__mosaic {
        @include mobile {
            display: none;
        }
        @include modeFixed {
            display: none;
        }
    }
    .con__fullscreen {
        @include res-xs {
            // display: none;
        }
        polygon {
            fill: #fff;
        }
        .cjp-wrap.new-ui & {
            @include mobile {
                // margin-right: -0.6em;
            }
        }
        @include mobile {
            /* margin-right: -10px; */
            svg {
                position: relative;
                top: 0;
                path,
                polygon {
                    fill: #fff !important;
                }
            }
        }
    }
}
.cjp__ui-topinfo {
    position: absolute;
    top: rem(20px);
    left: rem(20px);
    right: rem(20px);
    /* height: 40px; */
    z-index: 100;
    display: flex;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.3s, opacity 0.3s, transform 0.3s;
    transform: perspective(1px) translate3d(0%, -50%, 0);
    pointer-events: none;
    > * {
        pointer-events: visible;
    }
    @include res-xs {
        top: 12px;
        left: 12px;
        right: 12px;
    }

    @include mobile {
        top: 0.75em;
        left: 0.75em;
        right: 0.75em;
        transition-delay: 0s;
        transition: $out-motion;
        transform: translate(0, 0);
        @include tablet {
            top: 0.75em;
            left: 0.75em;
        }
    }

    .cjp-wrap.first &,
    .cjp-wrap.playing & {
        visibility: visible;
        opacity: 1;
        transition: visibility 0s linear 0s, opacity 0.3s, transform 0.3s;
        transform: perspective(1px) translate3d(0, 0, 0);
    }
    @include media-mobile {
        .cjp-wrap.mobileheader & {
            transition-delay: 0s;
            transition: $in-motion;
            transform: translate(0, 43px);
        }
        .cjp-wrap.mobileheader.playing & {
            transform: translate(0, 0);
        }
        .cjp-wrap.mobileheader.media-paused &,
        .cjp-wrap.mobileheader.media-loading & {
            transition-delay: 0s;
            transition: $in-motion;
            transform: translate(0, 43px);
        }
        .cjp-wrap.mobileheader .cjp__ui.hover & {
            transition-delay: 0s;
            transition: $in-motion;
            transform: translate(0, 43px);
        }
    }

    &:before {
        /* content: ''; */
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100px;
        pointer-events: none;
        user-select: none;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAADGCAYAAAAT+OqFAAAAdklEQVQoz42QQQ7AIAgEF/T/D+kbq/RWAlnQyyazA4aoAB4FsBSA/bFjuF1EOL7VbrIrBuusmrt4ZZORfb6ehbWdnRHEIiITaEUKa5EJqUakRSaEYBJSCY2dEstQY7AuxahwXFrvZmWl2rh4JZ07z9dLtesfNj5q0FU3A5ObbwAAAABJRU5ErkJggg==)
            0 0;
        /* background-color: rgba(0, 0, 0, .5); */
    }
    svg {
        fill: #fff;
        @include mobile {
            width: 1.1em;
        }
    }
    /* 모바일에서 본편보기 버튼 */
    .mobile-vodlink {
        background-color: rgba(0, 0, 0, 0.9);
        /* border-radius: .4em; */
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        position: absolute;
        display: flex;
        right: 0;
        height: 40px;
        align-items: center;
        padding: 0 0.5em 0 1em;
        font-size: 0.8em;
        white-space: nowrap;

        @include mobile {
            padding: 0 0 0 0.25em;
            font-size: 0.7em;
            height: 30px;
        }

        > a {
            display: flex;
            height: 100%;
            align-items: center;
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            i {
                font-size: 1.5em;
                text-decoration: none !important;
            }

            @include mobile {
                padding: 0 0.25em 0 0.5em;
                font-size: 0.7em;
                text-decoration: none !important;
                &:hover {
                    span {
                        text-decoration: none !important;
                    }
                }
            }
        }
    }
    .livead {
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        position: relative;
        height: 40px;
        align-items: center;
        margin-right: 0.5em;
        padding: 0 0.5em 0 1em;
        font-size: 0.85em;
        display: none;
        white-space: nowrap;
        cursor: pointer;

        @include res-xs {
            height: 35px;
            font-size: rem(12px);
        }
        @include mobile {
            padding: 0 0 0 0.25em;
            font-size: 0.7em;
            height: 30px;
        }
        &.show {
            display: block;
        }

        > a {
            display: flex;
            height: 100%;
            align-items: center;
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            em {
                color: #fcc800;
            }
            i {
                font-size: 1.5em;
                text-decoration: none !important;
            }

            @include mobile {
                padding: 0 0.25em 0 0.5em;
                font-size: 0.7em;
                text-decoration: none !important;
                &:hover {
                    span {
                        text-decoration: none !important;
                    }
                }
            }
        }
    }
    .limit {
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 1em;
        font-size: 0.8em;
        font-weight: 500;
        height: 40px;
        margin-right: 0.5em;
        line-height: 1;
        white-space: nowrap;

        @include mobile {
            padding: 0 0.75em;
            font-size: 0.7em;
            height: 30px;
        }

        &-time {
            padding: 0 0.5em 0 0;
            font-weight: 700;
        }
        &-txt {
            position: relative;
            padding: 0 0 0 0;
            /* font-weight: 700; */
            color: rgba(255, 255, 255, 0.75);
        }
        &-action {
            position: relative;
            height: 100%;
            padding: 0 0 0 2em;
            display: flex;
            align-items: center;
            color: rgba(255, 255, 255, 0.5);

            @include mobile-and-s {
                display: none;
            }

            &:hover {
                cursor: pointer;
            }

            &:after {
                content: '';
                display: inline-block;
                width: 0;
            }
            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 1em;
                height: 14px;
                width: 1px;
                background-color: #5f6062;
                transform: translate(0, -50%);
            }
        }
    }
    .topbox {
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 1em;
        font-size: 0.8em;
        height: 40px;
        white-space: nowrap;
        font-weight: 500;

        @include mobile {
            padding: 0 0.75em;
            font-size: 0.7em;
            height: 30px;
            i {
                font-size: 1em;
            }
        }
    }
    .muted-info {
        margin-right: 0.5em;
        svg {
            margin: 0 0.5em 0 0;

            @include mobile {
                margin: 0 0.25em 0 0;
            }
        }
    }
}
.cjp__ui-bottom-con {
    svg {
        fill: #fff;
        @include mobile {
            width: 1.1em;
        }
    }
    .tmp {
        opacity: 0;
        pointer-events: none;
    }
    .ad-overlay {
        position: absolute;
        left: 0;
        bottom: 1em;
        width: 100%;
        display: flex;
        justify-content: center;
        pointer-events: none;
        .inner {
            position: relative;
            display: inline-block;
            pointer-events: visible;

            @include mobile-and-s {
                width: 100%;
                padding: 0 1em;
                a {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
            }

            .close-btn {
                position: absolute;
                top: 0;
                right: 0;
                width: 20px;
                height: 20px;
            }
        }
    }
}
.cjp-quality-mobile {
    position: absolute;
    left: 0;
    bottom: calc(0.5em);
    width: 100%;
    display: flex;
    justify-content: center;
    z-index: 10;

    visibility: hidden;
    opacity: 0;
    transition: visibility 0s linear 0.2s, $in-motion;
    transform: translate(0, 10px);
    .cjp-wrap.mobile-quality & {
        visibility: visible;
        opacity: 1;
        transform: translate(0, 0);
        transition: visibility 0s linear 0s, $out-motion;
    }
    .cjp-quality-menu {
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row-reverse;
        height: 48px;
        align-items: center;
        overflow: hidden;
        button {
            height: 3em;
            padding: 0 1em;
            color: rgba(255, 255, 255, 0.95);
            background-color: rgba(15, 15, 15, 0.6);
            transition: color 0.1s;
            cursor: default;
            display: flex;
            align-items: center;
            font-size: 0.8em;
            font-weight: 700;
            line-height: 1.5;
            /* font-weight: 700; */
            &:first-child {
                border-bottom-right-radius: $bdr-sm;
                border-top-right-radius: $bdr-sm;
            }
            &:last-child {
                border-bottom-left-radius: $bdr-sm;
                border-top-left-radius: $bdr-sm;
            }

            &.selected {
                background-color: rgba(0, 0, 0, 1);
                color: #{$color-tvn};
                em {
                    color: #{$color-tvn};
                }
            }
            span {
                display: flex;
                align-items: flex-start;
                line-height: 0.7;
                /* border: 1px solid red; */
            }
            em {
                padding: 0 0 0 0.1em;
                font-size: rem(10px);
                font-weight: 700;
                font-style: normal;
                /* line-height: 9px; */
                line-height: 0.7;
                color: rgba(255, 255, 255, 0.5);
            }
        }
    }
}
.ca__bottom-right {
    pointer-events: none;
    position: absolute;
    right: 2rem;
    bottom: 7rem;
    @include res-xs {
        left: 12px;
    }
    @include mobile {
        left: 0.75em;
    }
    .next-adshow {
        position: absolute;
        right: 0;
        bottom: 0;
        pointer-events: none;
        font-weight: 500;
        font-size: rem(18px);
        color: rgba(255, 255, 255, 1);
        text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
        white-space: nowrap;
    }
}
.ca__bottom-left {
    pointer-events: visible;
    position: absolute;
    left: rem(20px);
    bottom: $con-height + 4;
    margin: 0 0 1em 0;
    @include res-xs {
        left: $control-pd - 6;
    }
    @include mobile {
        left: $control-pd;
        bottom: $con-mobile-height;
        margin: 0 0 0.75em 0;
    }
    .livead {
        background-color: rgba(0, 0, 0, 0.9);
        border-radius: $bdr-sm;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
        position: relative;
        height: 40px;
        align-items: center;
        margin-right: 0.5em;
        padding: 0 0.5em 0 1em;
        font-size: 0.85em;
        display: none;
        white-space: nowrap;
        cursor: pointer;

        @include res-xs {
            height: 35px;
            font-size: rem(12px);
        }
        @include mobile {
            padding: 0 0 0 0.25em;
            font-size: 0.7em;
            height: 30px;
        }
        &.show {
            display: block;
        }
        svg {
            margin: 0 8px 0 5px;
            fill: #fff;
        }
        > a {
            display: flex;
            height: 100%;
            align-items: center;
            color: #fff;
            font-weight: 500;
            text-decoration: none;
            em {
                color: #fcc800;
            }
            i {
                font-size: 1.5em;
                text-decoration: none !important;
            }

            @include mobile {
                padding: 0 0.25em 0 0.5em;
                font-size: 0.7em;
                text-decoration: none !important;
                &:hover {
                    span {
                        text-decoration: none !important;
                    }
                }
            }
        }
    }
}
// 광고
.cjp__ui-ad {
    pointer-events: none;
    display: block;
    .ca-bottom {
        display: none;
    }
}
.cjp-wrap.ca:not(.ca-ls) {
    .cjp__ui-ad {
        position: relative;
        z-index: 10;
        height: 100%;
        .ad-total-info {
            position: absolute;
            right: 0;
            bottom: 3.5rem;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 0;
            font-size: 1.2rem;
            font-weight: 500;
            color: rgba(255, 255, 255, 0.7);
            text-shadow: 0 0 1px rgba(0, 0, 0, 0.15);
            transition: transform 0.2s;
            transform: translate(0, 0);

            &.mode-skip {
                transform: translate(0, rem(-16px));
            }
        }
        .ca-bottom {
            display: block;
            pointer-events: visible;
            position: absolute;
            bottom: 6rem;
            left: 2rem;
            right: 2rem;
            &.mini {
                left: 1rem;
                right: 1rem;
            }
            margin: 0 0 1em 0;
            user-select: none;
            border: 1px solid transparent;
            /* display: block !important; */
            /* z-index: 100; */

            @include res-xs {
                left: $control-pd - 6;
                right: $control-pd - 6;
            }
            @include mobile {
                left: $control-pd;
                right: $control-pd;
                bottom: $con-mobile-height;
                margin: 0 0 0.75em 0;
            }
            .ad-playing {
                position: absolute;
                right: 0;
                bottom: 0;
                /* height: 30px; */
                /* line-height: 30px; */
                /* padding: 0 1.5em 0; */
                font-weight: 700;
                font-size: 1.25em;
                color: #{$color-ad};
                white-space: nowrap;
                text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);
                vertical-align: middle;
                /* border: 1px solid red; */

                @include res-xs {
                    font-size: rem(16px);
                }

                @include mobile {
                    /* height: 20px; */
                    /* line-height: 20px; */
                    font-size: 1em;
                }
                span.n {
                    position: absolute;
                    bottom: 3.25em;
                    right: 0;
                    display: none;
                    justify-content: center;
                    align-items: center;
                    width: 2.2em;
                    height: 2.2em;
                    margin: -1.1em 0 0 0;
                    padding: 0 0.25em;
                    background-color: #{$color-ad};
                    color: #111;
                    /* font-weight: 400; */
                    font-size: rem(12px);
                    border-radius: 100px;

                    em {
                        font-weight: 700;
                    }
                }
            }
            .ad-skipinfo {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 100%;
                display: flex;
                justify-content: flex-end;
                align-items: center;
                padding: 0;
                font-size: 1.5rem;
                font-weight: 500;
                text-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

                & > div {
                    text-shadow: 0px 0px 6px rgba(0, 0, 0, 0.5);
                }
                @include mobile {
                    padding: 0;
                    font-size: 0.75em;
                }
                em {
                    color: #{$color-ad};
                    padding: 0 1px 0 0;
                    font-style: normal;
                    line-height: 1.2;
                }
                span.d {
                    position: absolute;
                    top: -1.45em;
                    right: 0;
                    em {
                        position: relative;
                        display: inline-block;
                        width: 5px;
                        height: 5px;
                        margin: 0 0 0 3px;
                        border-radius: 100px;
                        background-color: rgba(255, 255, 255, 0.3);
                        box-shadow: 0 0 2px rgba(0, 0, 0, 0.15);

                        &.active {
                            background-color: #{$color-ad};
                        }
                    }
                }
                .info {
                    white-space: nowrap;
                    position: absolute;
                    right: 0;
                    bottom: 0.833rem;

                    .is-minimode & {
                        font-size: 1.167rem;
                    }
                    @include res-xs {
                        bottom: 0;
                        /* font-size: 13px; */
                    }
                    @include mobile {
                        bottom: 0;
                    }
                }
                .ad-skip-btn {
                    position: absolute;
                    right: 0;
                    bottom: 0.833rem;

                    color: #ffffff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 4rem;
                    padding: 1rem 1.67rem;
                    border-radius: 0.33333rem;
                    background-color: rgba(0, 0, 0, 0.5);

                    font-size: 1.33333rem;
                    font-style: normal;
                    font-weight: 400;

                    .is-minimode & {
                        font-size: 1.12rem;
                    }

                    @include res-xs {
                        height: 35px;
                        padding-left: 12px;
                        font-size: 0.75em;
                    }

                    @include mobile {
                        /* top: -1em; */
                        height: 30px;
                        // line-height: 30px;
                        // padding: 0 1.15em 0 0.75em;
                        span {
                            line-height: inherit;
                            font-size: 0.75em;
                            vertical-align: inherit;
                        }
                    }
                }
            }
        }
    }
    .cjp__ui-control {
        > *:not(.con__time-new, .con__fullscreen, .con__togglePlay) {
            display: none;
            pointer-events: none !important;
            /* opacity: 0.5; */
        }
        //> button:first-child {
        //    pointer-events: visible !important;
        //    opacity: 1;
        //    display: none;
        //}
        .con__mobile-currentTime,
        .con__mobile-duration,
        .cjp-con-progress {
            display: block;
            opacity: 1;
        }
        .con__mobile-duration {
            padding-right: 0;
        }
        .con__volume {
            display: flex;
            pointer-events: visible !important;
            opacity: 1;

            @include mobile {
                display: none;
            }
        }
        .cjp-con-progress .progress-bar {
            .progress-per {
                &:before {
                    background-color: #{$color-ad};
                }
                .progress-pointer {
                    display: none;
                    background-color: #{$color-ad};
                }
            }
        }
    }
}

.no-transform:hover {
    transform: none !important;
  }

.icon-volume-mute {
    opacity: 0.5;
}

@keyframes bouncing-loader2 {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0.2;
        transform: translateY(-16px);
    }
}

@import './_talk.scss';
