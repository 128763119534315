@import 'toasts-body';

@mixin sweetalert2-body() {
  &.swal2-shown {
    @include not('.swal2-no-backdrop', '.swal2-toast-shown') {
      overflow: hidden; // not overflow-y because of Sarari, #1253
    }
  }

  &.swal2-height-auto {
    height: auto !important; // #781 #1107
  }

  &.swal2-no-backdrop {
    .swal2-container {
      background-color: transparent !important;
      pointer-events: none;

      .swal2-popup {
        pointer-events: all;
      }

      .swal2-modal {
        box-shadow: 0 0 10px $swal2-backdrop;
      }
    }
  }

  @media print {
    &.swal2-shown {
      @include not('.swal2-no-backdrop', '.swal2-toast-shown') {
        overflow-y: scroll !important;

        > [aria-hidden='true'] {
          display: none;
        }

        .swal2-container {
          position: static !important;
        }
      }
    }
  }
}

.is-chat-component {
  @media (orientation: landscape) {
    .swal2-popup.swal2-modal {
      position: fixed;
      top: 50%;
      right: calc((100vw - var(--kbo-player-width)) / 2);
      transform: translate(50%, -50%);
      animation: none !important;
      transition: none !important;
    }
  }
}
