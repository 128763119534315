.Layout__safe-area {
    padding-top: var(--safe-area-inset-top);
    padding-bottom: var(--safe-area-inset-bottom);
    padding-left: var(--safe-area-inset-left);
    padding-right: var(--safe-area-inset-right);
}

.Layout__safe-area--android {
    padding-top: 25px;

    @media (orientation: landscape) {
        padding-bottom: 25px;
        padding-left: 25px;
    }
}

.Sports-Header {
    padding-top: var(--safe-area-inset-top);

    &:after {
        content: ' ';
    }
}

.Sports-Header--android {
    padding-top: 25px;
}

// iOS Safe Area 이슈를 임시 대응합니다. :(...
.Sports-Header--webview {
    visibility: hidden;
    opacity: 0;

    &.visible {
        visibility: visible;
        opacity: 1;
    }
}
