$tablet-width: 768px;
$mobile-width: 1024px;
$pc-width: 1025px;

@mixin phonexs {
  @media only screen and (max-device-width: 320px) {
    @content;
  }
}
@mixin phone {
  @media (min-width: 480px) {
    @content;
  }
}
/* 
사용법
@include mobile {
    @include tablet {
    }
}
*/
@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    // .mode-mq & {
    @content;
    // }
  }
}

@mixin media-mobile {
  @media (max-width: #{$mobile-width}) {
    @content;
  }
}

@mixin mobile {
  // @media (max-width: #{$mobile-width}) {
  @if & {
    .cjp__device-mobile & {
      @content;
    }
  } @else {
    .cjp__device-mobile {
      @content;
    }
  }
  // }
}
@mixin desktop {
  @media (min-width: #{$pc-width}) {
    // .mode-mq & {
    @content;
    // }
  }
}

@mixin res-l {
  @if & {
    .cjp__res-l & {
      @content;
    }
  } @else {
    .cjp__res-l {
      @content;
    }
  }
}

@mixin res-m {
  @if & {
    .cjp__res-m & {
      @content;
    }
  } @else {
    .cjp__res-m {
      @content;
    }
  }
}

@mixin res-s {
  @if & {
    .cjp__res-s & {
      @content;
    }
  } @else {
    .cjp__res-s {
      @content;
    }
  }
}
@mixin res-xs {
  @if & {
    .cjp__res-xs & {
      @content;
    }
  } @else {
    .cjp__res-xs {
      @content;
    }
  }
}

@mixin mobile-and-xs {
  @include mobile {
    @content;
  }
  @include res-xs {
    @content;
  }
}

@mixin mobile-and-s {
  @include mobile {
    @content;
  }
  @include res-s {
    @content;
  }
}
