.bottom-sheet-overlay-animation {
    opacity: 0;

    &.after-open {
        opacity: 1;
        transition: opacity 200ms ease-in;
    }

    &.before-close {
        opacity: 0;
        transition: opacity 150ms ease-out;
    }
}

.bottom-sheet-body-animation-provider {
    .bottom-sheet-body {
        transform: translateY(100%);
    }

    &.after-open {
        .bottom-sheet-body {
            transform: translateY(0);
            transition: transform 250ms ease;
        }
    }

    &.before-close {
        .bottom-sheet-body {
            transform: translateY(100%);
            transition: transform 150ms ease-out;
        }
    }
}

.bottom-sheet-body-scroll {
    &::-webkit-scrollbar {
        width: 0.333rem;
        background: transparent;
    }

    &::-webkit-scrollbar-track {
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: #404040;
        border-radius: 0.167rem;
    }

    &::-webkit-scrollbar-track-piece:end {
        background: transparent;
    }

    &::-webkit-scrollbar-track-piece:start {
        background: transparent;
    }
}
