$pd: 1rem;

.cjp__ui-chat-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    // max-height: 700px;
    pointer-events: none;
    @include modeFixed {
        // display: none;
    }
}
.cjp__ui-chat {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 20vw;
    min-width: 290px;
    padding: 0;
    pointer-events: visible;

    &:before {
        content: '';
        position: absolute;
        top: -100px;
        left: -20px;
        right: -$pd;
        bottom: -($pd);
        background-color: rgba(21, 21, 21, 0.5);
        opacity: 0;
        transition: opacity 0.2s, transform 0.2s;

        @include hideControl {
            transition: opacity 0.4s, transform 0.4s;
            transform: translate(0, $con-height);
        }
    }

    &:hover {
        &:before {
            opacity: 1;
        }
    }
    [data-simplebar] {
        transition: transform 0.3s;
        mask-image: -webkit-linear-gradient(top, transparent 0px, #000 (25em), #000 calc(100%), transparent calc(100%));
        @include hideControl {
            transition: transform 0.4s;
            transform: translate(0, $con-height);
        }
    }

    .new-msg {
        position: absolute;
        left: 50%;
        top: -5em;
        height: 3em;
        padding: 0 1.5em;
        font-size: rem(16px);
        font-weight: 500;
        width: auto;
        margin: 0;
        white-space: nowrap;
        background-color: rgba($color-tvn, 0.85);
        color: #fff;
        border-radius: 100px;
        box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.8);
        visibility: hidden;
        opacity: 0;
        transform: translate(-50%, 50px);
        transition: visibility 0s linear 0.4s, opacity 0s, transform 0s, background-color 0.2s;

        &:hover {
            background-color: rgba($color-tvn, 1);
        }

        &.is-active {
            visibility: visible;
            opacity: 1;
            transform: translate(-50%, 0);
            transition: visibility 0s linear 0s, opacity 0.4s, transform 0.4s cubic-bezier(0.175, 0.885, 0.32, 1.275), background-color 0.2s;
        }
        @include modeFixed {
            visibility: hidden;
        }
    }
    .simplebar-content {
        min-height: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: flex-end;
    }
}
.ui-chat {
    &__confirm {
        /* position: absolute; */
        z-index: 1000;
        min-width: 320px;
        padding: rem(37px) rem(12px) rem(30px);
        background-color: rgba(15, 15, 15, 0.9);
        border-radius: $bdr-md;
        box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
        display: block;
        text-align: center;
        pointer-events: visible;

        visibility: hidden;
        opacity: 0;
        transition: visibility 0s linear 0s, opacity 0.2s, transform 0.2s;
        transform: scale(0.5);

        &.is-show {
            visibility: visible;
            opacity: 1;
            transition: visibility 0s linear 0s, opacity 0.3s, transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: scale(1);
        }

        p {
            width: 100%;
            text-align: center;
            padding: 0 0 rem(21px);
            font-size: rem(14px);
            white-space: pre-line;
            font-size: rem(15px);
            color: #ededed;
        }
        button {
            height: 40px;
            font-size: rem(14px);
            margin: 0 4px;
            padding: 0 rem(24px);
            font-size: rem(14px);
            color: #969696;
            background-color: #3c3c3c;
            border-radius: 100px;

            &.btn-ok {
                background-color: #ededed;
                color: #1a1a1a;
            }
        }
    }
    &__noti {
        display: none;
        position: absolute;
        top: $pd;
        right: 0;
        z-index: 1;
        width: 100%;
        padding: rem(16px);
        transform: translateX(0);
        transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        &:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: $bdr-md;
            background-color: #0f0f0f;
            opacity: 0.9;
            // box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12) !important;
            box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
            transition: width 0.2s, height 0.2s, border-radius 0.3s, transform 0.3s;
        }
        &.is-close {
            transform: translateX(250px);
            &:before {
                width: 40px;
                height: 40px;
                border-radius: 100px;
                background-color: #1e1e1e;
            }
            .ui-chat__noti-btn-close {
                opacity: 0;
            }
            .ui-chat__noti-info > button {
                left: -12px;
                top: -12px;
                width: 40px;
                height: 40px;
                opacity: 1;
                span {
                    top: 13px;
                    left: 11px;
                }
            }
            .ui-chat__noti-info > span {
                opacity: 0;
                transition: opacity 0.1s;
            }
            .ui-chat__noti-content {
                opacity: 0;
                transition: opacity 0.1s;
            }
        }
        &-btn-close {
            position: absolute;
            top: 10px;
            right: 7px;
            z-index: 1;
            opacity: 1;
            transition: 0.1s;
        }
        &-info {
            position: relative;
            padding: 0 0 0.5em 0;
            font-size: rem(16px);
            color: rgba(255, 255, 255, 0.5);
            button {
                position: absolute;
                z-index: 1;
                top: 3px;
                left: 0;
                width: 19px;
                height: 11px;
                opacity: 0.5;
                transition: all 0.3s;
                span {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 19px;
                    height: 13px;
                    transition: all 0.3s;
                    path,
                    rect,
                    polygon {
                        fill: #fff;
                    }
                }
            }
            > span {
                padding: 0 0.5em 0 rem(29px);
                transition: opacity 0.3s;
            }
        }
        &-content {
            font-size: rem(17px);
            line-height: 1.35;
            transition: opacity 0.3s;
        }
    }
    &__list {
        position: relative;
        // top: 0;
        // bottom: 13em;
        // right: 0;
        // width: 100%;
        // text-align: right;
        transition: transform 0.3s;
        // overflow-y: scroll;
        // mask-image: -webkit-linear-gradient(top, transparent 0px, #000 (25em), #000 calc(100%), transparent calc(100%));

        display: flex;
        flex-wrap: wrap;
        align-items: flex-end;
        align-content: flex-end;
        padding-top: 10rem;
        padding-bottom: .2rem;
        min-height: 100%;

        // @include hideControl {
        //     transition: transform 0.4s;
        //     transform: translate(0, $con-height);
        // }

        .cjp__ui-loading {
            bottom: 30px;
            height: auto;
        }

        &-none {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            text-align: left;
            padding: rem(2px) $pd rem(2px) 0;
            &.is-me &-wrap {
                background-color: rgba(22, 22, 22, 0.9);
            }
            &-wrap {
                display: inline-block;
                padding: rem(16px);
                border-radius: $bdr-md;
                background-color: rgba(22, 22, 22, 0.5);
            }
            &-info {
                padding: 0 0 0.25em 0;
                font-size: rem(16px);
                color: rgba(255, 255, 255, 0.5);
            }
            font-size: rem(17px);
            word-break: break-all;
            color: rgba(255, 255, 255, 0.9);
        }

        &-item {
            width: 100%;
            display: flex;
            justify-content: flex-end;
            text-align: left;
            padding: rem(2px) $pd rem(2px) 0;
            &:nth-child(2) {
                // padding-top: rem($con-height + 10);
            }
            &:last-child {
                // padding-bottom: rem(10px);
            }
            &.is-me &-wrap {
                background-color: rgba(22, 22, 22, 0.9);
            }
            &.is-noti &-wrap {
                background-color: rgba(15, 15, 15, 0.9);
            }
            &.is-noti &-content {
                font-weight: 500;
                p {
                    padding: 0.25em 0 0 0;
                    color: #ededed;
                }
                &-noti {
                    padding: 0.25em 0 0 0;
                    font-weight: 400;
                    color: #999;
                }
            }
            &-wrap {
                display: inline-block;
                padding: rem(16px);
                border-radius: $bdr-md;
                background-color: rgba(22, 22, 22, 0.5);
            }
            &-info {
                display: flex;
                align-items: center;
                padding: 0 0 0.25em 0;
                font-size: rem(16px);
                color: rgba(255, 255, 255, 0.5);

                .noti {
                    position: relative;
                    display: flex;
                    margin: 0;
                    svg {
                        width: 19px;
                        height: 14px;

                        &:last-child {
                            margin: 1px 0 0 8px;
                            width: 43px;

                            // path,
                            // rect,
                            // polygon {
                            //     fill: #fff;
                            // }


                        }
                    }
                     > path,
                    rect,
                    polygon {
                        fill: #ff112f;
                    }
                }

                span,
                button {
                    padding: 0 0 0 0;
                    &.time {
                        position: relative;
                        margin: 0 0 0 rem(7px);
                        padding: 0 0 0 rem(7px);
                        &:before {
                            content: '';
                            position: absolute;
                            top: 50%;
                            left: 0;
                            width: 2px;
                            height: 2px;
                            margin: -1px 0 0 0;
                            border-radius: 100px;
                            background-color: rgba(255, 255, 255, 0.5);
                        }
                    }
                    &.report {
                        position: relative;
                        display: inline-block;
                        padding: 0 0 0 rem(7px);

                        &:after {
                            content: '';
                            position: relative;
                            display: inline-block;
                            width: 9px;
                            height: 10px;
                            background-repeat: no-repeat;
                            background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI5IiBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgOSAxMCI+ICA8ZGVmcz4gICAgPHN0eWxlPiAgICAgIC5jbHMtMSB7ICAgICAgICBmaWxsOiAjZmZmOyAgICAgICAgZmlsbC1ydWxlOiBldmVub2RkOyAgICAgICAgb3BhY2l0eTogMC41OyAgICAgIH0gICAgPC9zdHlsZT4gIDwvZGVmcz4gIDxwYXRoIGlkPSJub3RpLWljb24iIGNsYXNzPSJjbHMtMSIgZD0iTTE1MTcuMSwyMzMuMDA4YTAuODYyLDAuODYyLDAsMCwwLC45LDF2MWgtOXYtMC41ODZhMS41LDEuNSwwLDAsMCwuOS0xLjQwNkMxNTA5LjksMjI2LjM3MSwxNTE3LjEsMjI2LjM3MSwxNTE3LjEsMjMzLjAwOFpNMTUxMywyMjVoMXYyaC0xdi0yWm0tNCwxLjY2NywwLjY3LS42NjUsMS4zMywxLjMzMS0wLjY3LjY2NVptOSwwLTAuNjctLjY2NS0xLjMzLDEuMzMxLDAuNjcsMC42NjVaIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTUwOSAtMjI1KSIvPjwvc3ZnPg==);
                        }
                    }
                    &.del {
                        position: relative;
                        display: inline-block;
                        padding: 0 0 0 rem(7px);

                        &:after {
                            content: '';
                            position: relative;
                            display: inline-block;
                            width: 9px;
                            height: 9px;
                            background-size: 9px;
                            background-repeat: no-repeat;
                            background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48c3ZnIHZlcnNpb249IjEuMSIgaWQ9IuugiOydtOyWtF8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIgdmlld0JveD0iMCAwIDkgOSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgOSA5OyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHN0eWxlIHR5cGU9InRleHQvY3NzIj4uc3Qwe29wYWNpdHk6MC41O2ZpbGw6I0ZGRkZGRjtlbmFibGUtYmFja2dyb3VuZDpuZXcgICAgO308L3N0eWxlPjxnPjxwb2x5Z29uIGNsYXNzPSJzdDAiIHBvaW50cz0iOSwwLjcgOC4zLDAgNC41LDMuOCAwLjcsMCAwLDAuNyAzLjgsNC41IDAsOC4zIDAuNyw5IDQuNSw1LjIgOC4zLDkgOSw4LjMgNS4yLDQuNSAiLz48L2c+PC9zdmc+);
                        }
                    }
                }
            }
            &-content {
                font-size: rem(17px);
                // line-height: 1.35;
                word-break: break-all;
                p {
                    overflow-wrap: anywhere;
                }
            }
        }
    }
    &__write {
        display: flex;
        position: absolute;
        right: 1rem;
        bottom: 7em;
        width: 100%;
        height: 4em;
        font-size: rem(16px);
        background-color: rgba(15, 15, 15, 0.9);
        border: 1px solid #000;
        border-radius: $bdr-md;
        transition: opacity 0.3s, transform 0.3s;


        @include hideControl {
            transition: opacity 0.2s, transform 0.4s;
            transform: translate(0, $con-height);
        }

        .room-name {
            position: absolute;
            top: 50%;
            left: -10px;
            display: flex;
            align-items: center;
            height: 2.25em;
            padding: 0 1em;
            font-size: 12px;
            font-weight: 500;
            color: #fff;
            background-color: $color-tvn;
            border-radius: 100px;
            transform: translate(-100%, -50%);
            pointer-events: none;
            &:before {
                content: 'Room :';
                padding: 0 5px 0 0;
            }
        }

        input {
            width: 100%;
            display: inline-block;
            height: 100%;
            border: 0;
            padding: rem(16px);
            font-size: rem(18px);
            color: #6e6e6e;
            background-color: #181818;
            outline: none;
            &::-webkit-input-placeholder {
                color: #6e6e6e;
            }
            &:-ms-input-placeholder {
                color: #6e6e6e;
            }
            &::-moz-selection {
                color: #fff;
                background-color: #{$color-tvn};
                background-color: var(--color-tvn);
            }
            &::selection {
                color: #fff;
                background-color: #{$color-tvn};
                background-color: var(--color-tvn);
            }
        }
        button {
            margin: 1px;
            width: 8rem;
            font-size: rem(18px);
            color: #fff;
            background-color: #323232;
            border-radius: $bdr-md;
            text-align: center;
        }
    }
}
.cjp {
    &__ui-control {
        .con__talk {
            @include mobile {
                // display: none;
            }
            &.is-dimm {
                opacity: 0.5;
                pointer-events: none;
            }
            @include modeFixed {
                // display: none;
            }
            &.is-active,
            .cjp-wrap.mode-chat & {
                svg {
                    path,
                    polygon {
                        fill: #{$color-tvn};
                        fill: var(--color-tvn);
                    }
                }
            }
        }
    }
}
