.spinner {
  position: absolute;
  z-index: 999;
  top: 50%;
  left: 50%;
  margin: -25px 0 0 -25px;
  pointer-events: none;
  width: 4.167rem;
  height: 4.167rem;
  border: .333rem solid #FF153C;
  border-left-color: transparent;

  border-radius: 50%;
  animation: loading-spin 750ms linear infinite;
}

@keyframes loading-spin {
  100% {
    transform: rotate(360deg);
  }
}
