@charset "utf-8";

//My 페이지
// ::before,
// ::after {
//     display: inline-block;
//     background-repeat: no-repeat;
//     background-position: 0;
//     background-size: 100% auto;
// }

.text-omit {
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-inline-box;
    word-wrap: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
}

.menu__tab {
    justify-content: flex-start;
    border-bottom: solid 1px #2e2e2e;
    button {
        margin: 0 0 0 2.5em;
        padding-bottom: 0.9em;
        border-bottom-style: solid;
        border-bottom-width: 0.19em;
        border-bottom-color: transparent;
        font-size: 1.333rem; //16px;
        text-align: left;
        color: #a3a3a3;
        &:hover {
            color: #dedede;
        }
        &.click_on {
            opacity: 1;
            font-weight: 700;
            color: #fff;
            border-bottom-color: #fff;
        }
        &:first-child {
            margin-left: 0;
        }
    }
}

.my {
    &-page {
        &.popup {
            &__layer-content {
                ul {
                    border-style: solid;
                    border-color: #2e2e2e;
                    border-width: 1px 0;
                    &:first-child {
                        border-bottom-width: 0;
                    }
                }
            }
        }
    }
    &profile {
        &__banner {
            height: 6.666rem; //80px;
            display: block;
            > img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__wrap {
            padding: 5em 3.835em; //60px 46px;
            background-color: #191919;
        }
        &__thumb_img {
            width: 10.025em; //120px;
            height: 10.025em; //120px;
            overflow: hidden;
            border-radius: 0.335em; //4px;
            cursor: pointer;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &.item__image {
            width: 10.025em; //120px;
            height: 10.025em; //120px;
            overflow: hidden;
            border-radius: 0.335em; //4px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &__myinfo {
            margin-left: 1.5em; //24px;
            margin-right: auto;
            font-size: 1.333rem; //16px;
        }
        &__nickname {
            max-width: 9.25em; //300px;
            vertical-align: middle;
            font-size: 2.675rem; //32px;
            font-weight: 500;
            &-wrap {
                margin-bottom: 1.5em; //24px;
                .btn__line-small {
                    padding: 0.675em 0.755em;
                }
            }
        }
        &__detail {
            justify-content: flex-start;
            font-size: 1.333rem; //16px;
            &-title {
                margin-right: 1em; //16px;
                color: #b3b3b3;
                &:first-child {
                    margin-left: 0;
                    padding-left: 0;
                    border: none;
                }
                &::before {
                    content: '';
                    width: 1.5em; //24px;
                    height: 1.5em;
                    background-position: 50% -0.085em;
                    background-repeat: no-repeat;
                    background-size: contain;
                }
                &.utilize {
                    &::before {
                        background-image: url('/img/icon_utilize.svg');
                    }
                }
                &.tvingcash {
                    &::before {
                        background-image: url('/img/icon_tvingcash.svg');
                    }
                }
            }
        }
        &__contents {
            padding: 1.166rem 3.85rem 0; //30px 46px;
            .page_select {
                margin: 2.5rem auto 1.8rem; //32px; auto 22px;
                .btn__line-small {
                    margin-left: auto;
                }
            }
            .lists__columns {
                margin-top: 1.85em; //22px;
            }
            &.page_grid {
                .item {
                    margin: 0 1.675em; //20px;
                    grid-column: auto / span 6;
                    justify-items: flex-start;
                    align-items: flex-start;
                    &:nth-child(odd) {
                        margin-left: 0;
                    }
                    &:nth-child(even) {
                        margin-right: 0;
                    }
                }
            }
            .btn-right__wrap {
                margin-bottom: 3.35em; //50px;
            }
        }
        //아이템

        &.info_position {
            margin-right: auto;
            width: 27.777em; //330px;
            .item_info {
                font-size: 1rem; //0.666rem;//1600px시
            }
        }
        .page_notice > h3 {
            margin-bottom: 1em;
        }
    }
}

table {
    margin-bottom: 2.7em; //40px;
    border-spacing: 0;
    width: 100%;
    color: #b3b3b3;
    &.table {
        &__layout {
            text-align: left;
            font-size: 1.25rem; //15px;
            margin-bottom: 2.5em;
            line-height: 1.4;
            tr {
                &.active {
                    td {
                        color: #b3b3b3;
                        transition: color 0.1s;

                        &:hover {
                            & > a {
                                color: #dedede;
                            }
                        }
                    }
                }
                &.deactivate {
                    td {
                        color: #6e6e6e;
                        // border: none;
                    }
                }
                th {
                    height: 5.167rem; //62px;
                    background-color: #191919;
                    color: #888;
                    font-weight: 400;
                }
                td {
                    padding: 2rem 0; //24px 0;
                    border-bottom: solid 1px #191919;
                    white-space: nowrap;
                    a:hover {
                        color: #b3b3b3;
                    }
                }
            }
        }
        &-colunm3 {
            tr {
                th,
                td {
                    padding-left: 2em; //30px;;
                    &:last-child {
                        padding-right: 2em; //30px;
                    }
                }
                td {
                    color: #a3a3a3;
                    &:nth-child(1) {
                        width: 4.05em; //90px;
                    }
                    &:nth-child(2) {
                        width: auto;
                        transition: color 0.1s;

                        &:hover {
                            color: #dedede;
                        }
                    }
                    &:nth-child(3) {
                        width: 4.05em; //90px;
                    }
                }
            }
        }
        &-colunm4 {
            tr {
                th,
                td {
                    padding-left: 2em; //30px;;
                }
                th {
                    &:nth-child(1) {
                        width: 4.05em; //90px;
                    }
                    &:nth-child(2) {
                        width: 4.05em; //90px;
                    }
                    &:nth-child(3) {
                        width: auto;
                    }
                    &:nth-child(4) {
                        width: 16em; //240px;
                    }
                }
                td {
                    color: #808080;
                }
            }
        }
        &-colunm5 {
            color: #808080;
            tr {
                &.active {
                    color: #b3b3b3;
                }
                th {
                    background-color: #191919;
                    &:nth-child(1) {
                        width: 2.05em; //30px;
                        // background-color: rgba(88, 255, 249, 0.36);
                    }
                    &:nth-child(2) {
                        width: 6.05em; //90px;
                        // background-color: rgba(236, 88, 255, 0.36);
                    }
                    &:nth-child(3) {
                        width: 8em; //120px;
                        // background-color: rgba(88, 255, 249, 0.36);
                    }
                    &:nth-child(4) {
                        width: auto;
                        // background-color: #191919;
                    }
                    &:nth-child(5) {
                        width: 16em; //240px;
                        // background-color: rgba(88, 255, 249, 0.36);
                    }
                }
            }
        }
        &-colunm7 {
            th {
                background-color: #191919;
                &:nth-child(1) {
                    width: 2.5rem; //30px;
                    // background-color: rgba(88, 255, 249, 0.36);
                }
                &:nth-child(2) {
                    width: 7.5rem; //90px;
                    // background-color: rgba(236, 88, 255, 0.36);
                }
                &:nth-child(3) {
                    width: auto;
                    // background-color: #191919;
                }
                &:nth-child(4) {
                    width: 11.667rem; //140px;
                    // background-color: rgba(236, 88, 255, 0.36);
                }
                &:nth-child(5) {
                    width: 11.667rem; //120px;
                    // background-color: rgba(88, 255, 249, 0.36);
                }
                &:nth-child(6) {
                    width: 9.167rem; //240px;
                    // background-color: rgba(236, 88, 255, 0.36);
                }
                &:nth-child(7) {
                    width: 15rem; //240px;
                    // background-color: rgba(88, 255, 249, 0.36);
                }
            }
            td {
                &:nth-child(3) {
                    padding-right: 1.333rem;
                }
                &:nth-child(4) {
                    padding-right: 1.333rem;
                    white-space: pre-line;
                }
                &:nth-child(5) {
                    padding-right: 1.333rem;
                    white-space: pre-line;
                }
            }
        }
        &-faq-column {
            tr {
                th,
                td {
                    &:last-child {
                        padding-right: 2em; //30px;
                    }
                }
                td {
                    color: #a3a3a3;
                    &:nth-child(1) {
                        width: 11rem; //90px;
                        text-align: center;
                    }
                    &:nth-child(2) {
                        width: auto;
                        transition: color 0.1s;

                        &:hover {
                            color: #dedede;
                        }
                    }
                    &:nth-child(3) {
                        width: 4.05em; //90px;
                    }
                }
            }
        }
    }
}

.text {
    &__wrap {
        margin-bottom: 7.5em;
    }
    &__layer {
        margin: 2.5rem 0;
        font-size: 1rem;
        h3 {
            color: #dedede;
            font-size: 1.667rem; //20px;
            span {
                margin-left: auto;
                &.num__day {
                    line-height: 3.27;
                    color: #888;
                    font-size: 1.25rem;
                    font-weight: 400;
                    // font-weight: 300;
                }
            }
        }
        ul {
            margin: 0.667rem -1rem 0;
            li {
                position: relative;
                color: #888;
                font-size: 1.25rem;
                padding: 0 1rem;
                &::before {
                    content: '';
                    position: absolute;
                    top: 50%;
                    right: 0;
                    height: 1.083rem;
                    width: 1px;
                    background-color: #232323;
                    transform: translate(0, -50%);
                }
                &:last-child {
                    &::before {
                        display: none;
                    }
                }
            }
        }
        p {
            margin-top: 2.5rem;
            color: #a3a3a3;
            line-height: 1.5;
            font-size: 1.33rem; //16px;
            // font-weight: 300;
        }
    }
}

//결과
.result {
    position: relative;
    text-align: center;
    &-icon {
        &::before {
            content: '';
            display: block;
            margin: 0 auto 0.825em; //20px;
            width: 2.835em; //72px;
            height: 2.835em; //72px;
            border: 0.085em #848484 solid; //2px;
            border-radius: 100%;
            background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 55 55'%3E%3Cpath fill='%23848484' d='M26 14h3v19h-3V14zm0 25h3v3h-3v-3z'/%3E%3C/svg%3E");
            // 체크표시 background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3E%3Cpath fill='%23848484' d='M3.3,8.5l4,4.6l9.5-8.4l1,1.1L7.2,15.3l-5-5.8L3.3,8.5z'/%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: 50%;
        }
    }
    &-title {
        padding-top: 1.75em; //42px;
        margin-bottom: 4.175em; //100px;
        color: #808080;
        font-size: 2rem;
        line-height: 1.45;
    }
}
