@mixin sweetalert2-toasts-body() {
  &.swal2-toast-shown {
    .swal2-container {
      box-sizing: border-box;
      width: auto;
      max-width: 100%;
      background-color: transparent;
      pointer-events: none;
      width: 100%;
      > div {
        width: auto;
      }

      &.swal2-top {
        top: 1rem;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-top-end,
      &.swal2-top-right {
        top: 0;
        right: 1;
        top: 6.667rem;
        right: 3.888rem;
        bottom: auto;
        left: auto;
      }

      &.swal2-top-start,
      &.swal2-top-left {
        top: 0;
        right: auto;
        bottom: auto;
        left: 0;
      }

      &.swal2-center-start,
      &.swal2-center-left {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        transform: translateY(-50%);
      }

      &.swal2-center {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        transform: translate(-50%, -50%);
      }

      &.swal2-center-end,
      &.swal2-center-right {
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        transform: translateY(-50%);
      }

      &.swal2-bottom-start,
      &.swal2-bottom-left {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0;
      }

      &.swal2-bottom {
        top: auto;
        right: auto;
        bottom: 2.5rem;
        left: 50%;
        transform: translateX(-50%);
      }

      &.swal2-bottom-end,
      &.swal2-bottom-right {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto;
      }
    }
  }
}
