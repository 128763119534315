@charset "utf-8";

//편성표
.progress {
    &__bar {
        position: relative;
        padding: 1em 0 1em 1em; //16px;
        min-height: 5.275em;
        box-sizing: border-box;
        border-style: solid;
        border-width: 0.05em; //1px;
        border-color: #212121;
        overflow: hidden;
        color: #6e6e6e;
        font-size: 1.325rem; //16px;
        font-weight: 500;
        > p {
            word-break: keep-all;
            white-space: nowrap;
        }
        &:hover {
            z-index: 1;
            border-color: #a3a3a3;
            color: #888;
            .btn__alarm {
                &:after {
                    opacity: 0.7;
                }
            }
        }
        &.live {
            color: #dedede;
            .progress__title-sub {
                color: #888;
            }
            &:hover {
                color: #fff;
            }
        }
        &.next {
            color: #a3a3a3;
            .progress__title-sub {
                color: #6e6e6e;
            }
            &:hover {
                color: #dedede;
            }
        }
        .btn__alarm {
            position: absolute;
            top: 50%;
            right: 2em;
            transform: translateY(-50%);
            transition: opacity .1s;
            background-image: none;
            opacity:  1;
            &:before {
                content: '';
                position: absolute;
                top:  -2rem;
                left: -.5rem;
                right: -2rem;
                bottom: -2rem;
                background-color: #000;
                // border-radius: 100px;
                box-shadow: 0 0 20px rgba(0, 0, 0, 1);
            }
            &:after {
                content: '';
                position: absolute;
                top:  0;
                left: 0;
                width: 2.5em;
                height: 2.5em;
                background-repeat: no-repeat;
                background-position: 50%;
                background-size: 100% auto;
                background-image: url(/img/icon_alarm_off.svg);
                opacity: 0.7;
            }
            &.active {
                // opacity: 1;
                &:after {
                    // opacity: 1;
                    background-image: url(/img/icon_alarm_on.svg);
                }
            }
            &:hover {
                opacity: 1;
                &:after {
                    opacity: 1;
                }
            }
        }
        a {
            padding-right: 5rem;
            p {
                white-space: nowrap;
            }
        }
        .progress__titlewrap {
            p {
                // padding-right: 7.5rem;
                white-space: nowrap;
            }
        }
        &.pass {
            .progress__title-sub {
                color: #4e4e4e;
            }
            span.progress__titlewrap {
                color: #4e4e4e;
            }
            a.progress__titlewrap {
                color: #6E6E6E;
                &:hover {
                    color: #888888;
                }
            }
        }
    }
    &__title {
        pointer-events: none;
        display: inline-flex;
        margin-right: 1em;
        // color: #a3a3a3;
        // color: #dedede;
        &-sub {
            pointer-events: none;
            margin-bottom: 1em;
            color: #6e6e6e;
            font-size: 1.155rem; //14px;
            font-weight: 400;
        }
        .tag_adult {
            display: inline-block;
            margin-left: 0.75em;
            width: 2em; //20px;
            height: 2em;
            line-height: 2em;
        }
    }
    &__gauge {
        position: absolute;
        top: 0;
        left: 0;
        z-index: -1;
        height: 100%;
        background-image: linear-gradient(to right, rgba(255, 21, 60, 0.15), rgba(255, 21, 60, 0));
    }
}
.slide__num {
    // padding: 4.15rem 0; //50px;//3.295em40px;
    height: 13.333rem;
    background-color: #191919;
    z-index: 100;
    &-title {
        position: relative;
        display: inline-block;
        text-align: center;
        h3 {
            font-size: 3.295rem; //40px;
            font-weight: 400;
        }
        &sub {
            margin-top: 1em;
            color: #a3a3a3;
            font-size: 1.325rem; //16px;
            white-space: nowrap;
            p {
                font-weight: 400;
            }
        }
        .slide {
            &_lay {
                width: 4.5em;
                background-position: 50% -10%;
            }
            &_left {
                left: -3.5em;
            }
            &_right {
                right: -3.5em;
            }
        }
    }
}
.line {
    &__column {
        position: absolute;
        top: 0;
        z-index: 110;
        display: flex;
        align-items: flex-start;
        justify-content: center;
        width: 0.085em;
        height: 100vh;
        background-color: rgba(255, 21, 60, 0.3); //#ff153c;
        font-size: 1.25rem;
        &::before {
            content: '';
            position: sticky;
            top: -4rem;
            display: inline-block;
            border-style: solid;
            border-color: #ff153c transparent transparent transparent;
            border-width: 0.25em 0.25em 0;
        }
    }
}
.table {
    &__flow {
        position: relative;
        font-size: 1rem;
        &-row {
            position: relative;
            width: 100%;
            height: 7em; //85px;
            border-style: solid;
            border-width: 0 0 0.05em; //1px;
            border-color: #212121;
            &:first-child {
                border-width: 0 0 0.05em; //1px;
            }
            &.table__flow-head {
                display: -webkit-inline-box;
                height: 3em; //45px;
                background-color: #191919;
                color: #6e6e6e;
                border-top-width: 0.05em; //1px;
                font-size: 1.25em; //15px;
                > div {
                    border-width: 0;
                    &:first-child {
                        width: 9em;
                    }
                }
            }
            > div {
                margin-left: -0.05em;
                height: 100%;
                float: left;
                box-sizing: border-box;
                &:first-child {
                    margin-left: 0;
                    // padding: 20px;
                    width: 11.235em; //136px;
                }
            }
            &::after {
                content: '';
                display: block;
                clear: both;
            }
            .img-wrap {
                display: inline-block;
                width: 8.25em; //100px;
                height: 3.325em; //40px;
                > img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }
        &-column {
            float: left;
            width: auto;
            position: relative;
            width: 100%;
            height: 7em; //85px;
            &:nth-child(2) {
                // width: calc(100% - 11.25em);
                margin-left: -0.05em;
                overflow: overlay;
                width: calc(100% - 11.25em);
                display: -webkit-inline-box;
            }
            &::-webkit-scrollbar {
                // display: none; /* Chrome, Safari, Opera*/
                height: 0.5em;
                font-size: 1rem;
                &-thumb {
                    background-color: hsla(0, 0%, 42%, 0.49);
                    border-radius: 100px;
                }
            }
            .progress-bar {
                border-color: transparent #212121;
                &:hover {
                    z-index: 1;
                    border-color: #a3a3a3;
                    color: #888;
                }
            }
        }
        &-slide {
            position: relative;
            width: calc(100% - 9em);
            &.layout__flex {
                justify-content: space-evenly;
            }
        }
        .slide {
            &_lay {
                width: 2.25em;
                background-position: 50% 50%;
                font-size: 1rem;
            }
            &_left {
                left: 0.75em;
            }
            &_right {
                right: 0.75em;
            }
        }
    }
}
