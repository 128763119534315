// Animations
@keyframes swal2-toast-show {
  0% {
    transform: translateY(+.625em);
  }

  33% {
    transform: translateY(0);
  }

  // 66% {
  //   transform: translateY(.0) rotateZ(0deg);
  // }

  100% {
    transform: translateY(0);
  }
}

@keyframes swal2-toast-hide {
  100% {
    opacity: 0;
  }
}

@keyframes swal2-toast-animate-success-line-tip {
  0% {
    top: .5625em;
    left: .0625em;
    width: 0;
  }

  54% {
    top: .125em;
    left: .125em;
    width: 0;
  }

  70% {
    top: .625em;
    left: -.25em;
    width: 1.625em;
  }

  84% {
    top: 1.0625em;
    left: .75em;
    width: .5em;
  }

  100% {
    top: 1.125em;
    left: .1875em;
    width: .75em;
  }
}

@keyframes swal2-toast-animate-success-line-long {
  0% {
    top: 1.625em;
    right: 1.375em;
    width: 0;
  }

  65% {
    top: 1.25em;
    right: .9375em;
    width: 0;
  }

  84% {
    top: .9375em;
    right: 0;
    width: 1.125em;
  }

  100% {
    top: .9375em;
    right: .1875em;
    width: 1.375em;
  }
}
