@charset "utf-8";

.btn {
    &__wrap{
        margin-top:3.5rem;
        &.bottom{
            a,button{
                +a,+button{
                    margin-left: 1rem;
                }
            }
        }
    }
    &-right {
        float: right;
        &__wrap {
            overflow: hidden;
        }
    }
    &-layout {
        border-radius: 0.25em; //3px;
        &:hover,
        &:focus {
            opacity: 1;
        }
    }
    &__long{
        width: 100%;
        box-sizing: border-box;
    }
    &__small {
        font-size: 1.17rem; //14px;
        font-weight: 500;
        &.arrow {
            display: none;
            &.active {
                display: block;
            }
            &::after {
                content: '';
                display: inline-block;
                // margin-bottom: 3em;
                // width: 0.25em;
                padding-right: 1.5em;
                height: 0.75em;
                background-image: url('/img/icon_arrow_top.svg');
                background-repeat: no-repeat;
                background-position: 50% 47.5%;
                background-size: 100% auto;
            }
            &:hover {
                &:after {
                    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cpath fill='%23fff' d='M6.661 7.33v-6H.666a.666.666 0 0 1 0-1.33h6.662a.667.667 0 0 1 .666.667V7.33a.666.666 0 0 1-1.332 0z' transform='translate(10 16.347) rotate(-45)'/%3E%3C/svg%3E%0A");
                }
            }
            &.arrow-down {
                &:after {
                    background-image: url('/img/icon_arrow_down.svg');
                }
                &:hover {
                    &:after {
                        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='28' height='28' viewBox='0 0 28 28'%3E%3Cg%3E%3Cpath fill='%23fff' d='M.665 8a.666.666 0 0 1 0-1.333H6.66v-6a.666.666 0 1 1 1.332 0v6.668A.667.667 0 0 1 7.327 8z' transform='translate(-4.25 -4) translate(19.905 11.999) rotate(45)'/%3E%3C/g%3E%3C/svg%3E%0A");
                    }
                }
            }
        }
    }
    &__line {
        padding: 0.825em 2em; //12px 30px;
        display: inline-block;
        vertical-align: middle;
        opacity: 0.7;
        border-radius: 0.25em; //3px;
        border: solid 1px #4d4d4d;
        font-size: 1.25rem;
        display: inline-block;
        text-align: center;
        box-sizing: border-box;
        transition: opacity 0.1s;
        &:hover,
        &:focus {
            opacity: 1;
        }
        &-small {
            margin-left: 1.25em; //14px;
            padding: 0.42em 0.58em; //5px 8px;
            font-size: 1rem; //12px;
            &-midium {
                margin-left: 1.25em; //14px;
                padding: 0.65em 1.305em; //8.5px 17px;
                font-size: 1.085rem; //13px;
                opacity: 0.4;
                border-color: #a3a3a3;
            }
        }
        @media screen and (max-width:799px) {
            padding:1.6rem;
            font-size: 1.5rem;
            width: 100%;
        }
    }
    &__red{
        background-color: #fe153c;
        border-color: #fe153c;
        opacity: 1;
        color:#fff;
        // font-weight: bold;
    }
    &__white {
        display: inline-block;
        margin: 0 auto;
        padding: 1em;
        min-width: 17em; //270px;
        background-color: #fff;
        opacity: 0.8;
        border-radius: 3px;
        color: #000;
        text-align: center;
    }

    &-190 {
        min-width: 11.85em;
        margin: 0;
        padding: 1.25em;
        border-radius: 3px;
        opacity: 1;
        box-sizing: border-box;
        font-size: 1.325rem; //16px;
    }
    &__nickname-change {
        margin-right: 0.825em; //13px;
        width: 2.944em; //47px;
        height: 2.944em;
        opacity: 0.7;
        background-image: url('/img/icon_profile_edit.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        &:hover {
            opacity: 1;
        }
    }
    &__myinfo-revision,
    &__manage {
        margin-left: 2.133em; //30px;
        margin-bottom: auto;
        font-size: 1.175rem; //14px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        &::before {
            margin-right: 0.5rem; //6px;
            width: 1.083rem; //20px;
            height: 1.083rem; //20px;
            opacity: 0.5;
            vertical-align: bottom;
        }
        &:hover {
            &::before {
                opacity: 1;
            }
        }
    }
    &__myinfo-revision {
        margin-left: auto;
        &::before {
            content: '';
            background-image: url('/img/icon_cogwheel.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 0rem;
            background-size: 119%;
        }
    }
    &__manage {
        &::before {
            content: '';
            background-image: url('/img/icon_mark.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 0;
            background-size: 126%;
        }
    }
    &__alarm {
        width: 2.5em; //30px;
        height: 2.5em;
        @include background;
        background-image: url('/img/icon_alarm_off.svg');
        opacity: 0.5;
        font-size: 1rem;
        &:hover {
            opacity: 1;
        }
        &.active {
            background-image: url('/img/icon_alarm_on.svg');
        }
    }
    //페이지네이션
    &__pagenum {
        width: 2.275em; //32px;
        height: 2.275em; //32px;
        margin-left: 1.835em; //22px;
        // padding: 5px 12px 7px;
        display: flex;
        justify-content: center;
        align-items: center;
        opacity: 0.7;
        background-color: transparent;
        border-radius: 0.333rem;
        color: #fff;
        font-size: 1.17rem; //14px;
        &:first-child {
            margin-left: 0;
        }
        &:hover {
            opacity: 1;
        }
        &-wrap {
            margin-bottom: 4.175em; //50px;
        }
        &.click_on {
            opacity: 1;
            background-color: #191919;
        }
    }
    &-check{
        margin-top: 1.5rem;
        position: relative;
        +.btn-check{
            margin-left: 1.5rem;
        }
        input{
            position: absolute;
            width: 1px;
            height: 1px;
            padding: 0;
            margin: -1px;
            overflow: hidden;
            clip: rect(0, 0, 0, 0);
            border: 0;
            line-height: 110%;
            &[type='checkbox']{
                +label{
                    padding-left: 2.25rem;
                    &::before{
                        content: ' ';
                        display: block;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        transform: translateY(-50%);
                        width: 1.5rem;
                        height: 1.5rem;
                        text-align: center;
                        border-radius: 100%;
                        background-color: #4d4d4d;
                        background-image: url("/img/icon-check-unsel.svg");
                        background-size: 100% auto;
                        background-repeat: no-repeat;
                        background-position: 50%;
                    }
                }
                &:checked{
                    +label{
                        color: #fff;
                        &::before{
                            border-color: #ff1744;
                            background-color: #ff1744;
                            background-image: url("/img/icon-check-sel.svg");
                        }
                    }
                }
            }
        }
    }
}

.icon {
    &__search {
        margin-right: 2.333em;
        width: 2.333em;
        height: 2.333em;
        background-image: url("/img/icon_search.svg");
        background-repeat: no-repeat;
        background-position: 50% 72%;
        background-size: contain;
        opacity: 0.7;
        font-size: 1rem;
        text-indent: -9999px;
    }
    &__close {
        &:hover {
            opacity: 1;
        }
        &-line {
            margin-left: 1em; //12px;
            width: 1em; //12px;
            height: 1em; //12px;
            background-image: url("/img/icon_x.svg");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            opacity: 0.7;
            line-height: normal;
            font-size: 1rem;
            text-indent: -9999px;
            &.close-thin {
                width: 3.45em;
                height: 3.45em;
                background-image: url("/img/icon_x_thin.svg");
                opacity: 0.7;
            }
        }
        &-circle {
            margin-left: 0.5em; //6px;
            width: 1.175em; //14px;
            height: 1.175em; //14px;
            display: inline-block;
            background-image: url("/img/icon_x_circle.svg");
            background-repeat: no-repeat;
            background-position: 50% 50%;
            background-size: contain;
            line-height: normal;
            font-size: 1rem;
            text-indent: -9999px;
        }
    }
}
