@import './_var';
@import './_resolution';
@import './_transition';
@import './_typography';
@import './scrollbar';

:root {
    --color-tvn: #{$color-tvn};
    --color-ad: #{$color-ad};
    --color-cate-home: #{$color-cate-home};
    --color-cate-live: #{$color-cate-live};
    --color-cate-vod: #{$color-cate-vod};
    --color-cate-clip: #{$color-cate-clip};
    --color-cate-movie: #{$color-cate-movie};
    --color-cate-kids: #{$color-cate-kids};
}

@keyframes bouncing-loader {
    from {
        opacity: 1;
        transform: translateY(0);
    }

    to {
        opacity: 0.2;
        transform: translateY(-1rem);
    }
}
.cjp-container {
    overflow: hidden;
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #000;
}

.cjp-engine {
    $max-padding: 0px;
    position: absolute;
    top: $max-padding;
    left: $max-padding;
    right: $max-padding;
    bottom: $max-padding;
    pointer-events: none;
    width: 100%;
    height: 100%;

    transform-origin: left top;
    transition: transform 0.5s cubic-bezier(0.77, 0, 0.175, 1);

    .mode-multiview & {
        opacity: 1;
        transform: scale(1.5);
    }

    @include mobile {
        $max-padding: 0;
        position: absolute;
        top: $max-padding;
        left: $max-padding;
        right: $max-padding;
        bottom: $max-padding;
        pointer-events: none;
    }

    .video-js {
        position: relative;
        width: 100%;
        height: 100%;

        > * {
            display: none;
        }

        .vjs-tech {
            display: block;
        }
    }

    // opacity: 0;
    &.playing {
        opacity: 1;
    }

    .cjp-wrap.cjp-loading & {
        // transition: opacity 0s;
        // opacity: 0;

        // Opacity 0 인경우 익스에서 영상재생이 터지지 않아 left 로 화면 노출 막기
        left: -9999px;
    }

    .cjp-engine-vjs {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        transition: opacity 0.1s;

        > * {
            display: none;
            pointer-events: none;
        }

        > video {
            display: block;
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }

    .iniplayer-element {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }

    video {
        .cjp-wrap.movie-ratio-change & {
            object-fit: fill;
        }
        .mode-ad-livesmr & {
            opacity: 0;
        }
        .mode-ad-muted & {
            opacity: 0;
        }
    }
}

.cjp-blockinfo {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cjp-nontmp {
    $max-padding: 0px;
    position: absolute;
    top: $max-padding;
    left: $max-padding;
    right: $max-padding;
    bottom: $max-padding;
    pointer-events: none;
    display: none;
}
.cjp__video-ad {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 900;
    pointer-events: none;

    .cjp__device-mobile &,
    &.mode-mobile {
        position: relative;
        height: auto;
        .cjp__video-ad__companion {
            position: relative;
            top: 0;
            right: auto;
            z-index: 110;
            width: 100%;
            img {
                width: 100%;
            }
        }
    }

    // 컴페니언 광고
    &__companion {
        pointer-events: visible;
        position: absolute;
        top: 0;
        right: -180px;
        z-index: 110;

        // width: 160px;
        // height: 510px;
        // background-color: $color-ad;
        // cursor: pointer;

        .page-clip & {
            right: -560px;
        }
        img {
            display: block;
            &.is-link {
                cursor: pointer;
            }
            &.is-none-link {
                pointer-events: none;
            }
        }
    }
}
.page-clip {
    .player-container {
        overflow: visible;
    }
}

.cjp-ad-filler {
    $max-padding: 0px;
    position: absolute;
    top: $max-padding;
    left: $max-padding;
    right: $max-padding;
    bottom: $max-padding;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    background-color: #000;
    background-size: 100%;
    display: none;
    // background-image: url('./assets/images/tving-filler.png');

    .mode-ad-livesmr.mode-filler & {
        display: flex;
    }

    p {
        display: none;
        width: 100%;
        padding: 0;
        margin: 0;
        text-align: center;
        font-weight: 700;
        font-size: 30px;
        word-break: keep-all;
        color: rgba(250, 250, 250, 1);
        @include modeFixed {
            font-size: 20px;
        }
    }
}
.cjp-ad {
    $max-padding: 0px;
    position: absolute;
    top: $max-padding;
    left: $max-padding;
    right: $max-padding;
    bottom: $max-padding;
    pointer-events: none;

    .cjp-wrap.mode-dv .mode-ad-livesmr & {
        &:after {
            // content: 'SMR AD';
            position: absolute;
            left: 50%;
            top: 50%;
            display: flex;
            align-items: center;
            padding: 0 1rem;
            font-weight: 900;
            font-size: 10vw;
            white-space: nowrap;
            color: transparent;
            pointer-events: none;
            transform: translate(-50%, -50%);
            opacity: 0.9;
            -webkit-text-stroke-width: 3px;
            -webkit-text-stroke-color: #1d1d2c;
            text-shadow: 6px 6px rgba(255, 255, 255, 0.9);
        }
    }
    .cjp-wrap.mode-dv .mode-ad-livetap & {
        &:after {
            // content: 'TAP AD';
        }
    }
    .cjp-wrap.ca.pc & {
        background-color: #000;
    }

    .mode-ad & {
    }

    video {
        position: relative;
        width: 100%;
        height: 100%;
    }
}

// Live ID3 광고
.cjp-ad-id3 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: none;

    &__href {
        // 광고중..
        position: absolute;
        top: 1em;
        left: 1em;
        display: inline-block;
        border-radius: 100px;
        font-weight: 700;
        color: rgba(255, 229, 0, 1);

        &:after {
            content: '광고중';
        }
    }

    &__more {
        display: none;

        &.show {
            display: block;
        }

        &-href {
            position: absolute;
            top: 0.75em;
            right: 0.75em;
            display: inline-block;
            padding: 0.5em;
            border-radius: 3px;
            font-weight: 700;
            color: #000;
            font-size: 0.8em;
            background-color: rgba(255, 229, 0, 1);

            &:after {
                content: '상품 보러 가기';
            }
        }
    }
}

.cjp__ui {
    button {
        // outline: none;
        &:focus {
            box-shadow: 0 0 0 3px rgba($color-tvn, 0.5);
        }
        &:focus:not(.focus-visible) {
            // outline: none;
            box-shadow: none;
        }
    }
    input {
        &:focus {
            // outline: none;
            // box-shadow: 0 0 0 1px rgba($color-tvn, .5);
        }
    }
}
div.section-vod-player__player {
    z-index: 101;
}

/*
.cjp-ad {
  right: 50%;
}
.mode-ad-livetap .cjp-engine video {
  opacity: .5 !important;
}
*/

.cjp-wrap {
    &:after {
        // content: '';
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        height: 1px;
        background-color: #64ffda;
    }
    .cjp__ui {
        &:after {
            // content: '';
            position: absolute;
            top: 0;
            left: 50%;
            width: 1px;
            height: 100%;
            background-color: #64ffda;
        }
    }
}
