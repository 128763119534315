.fade-bottom-enter-active,
.fade-bottom-leave-active {
    transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
        transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.fade-bottom-enter,
.fade-bottom-leave-to {
    opacity: 0;
    transform: translate(0, 10px);
    transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1),
        transform 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}
// move Top
.fade-top-enter-active,
.fade-top-leave-active {
    transition: opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1),
        transform 0.3s cubic-bezier(0.42, 0, 0.58, 1);
}

.fade-top-enter,
.fade-top-leave-to {
    opacity: 0;
    transform: translate(0, -10px);
    transition: opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1),
        transform 0.2s cubic-bezier(0.42, 0, 0.58, 1);
}

// Oversize move in
.fade-over-enter-active,
.fade-over-leave-active {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fade-over-enter,
.fade-over-leave-to {
    opacity: 0;
    transform: scale(1.1);
    transition: opacity 0.2s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}
// move Top
.fade-opacity-enter-active,
.fade-opacity-leave-active {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fade-opacity-enter,
.fade-opacity-leave-to {
    opacity: 0;
}
.fade-opacity-leave {
    opacity: 0;
    transition: opacity 0s;
}

// move Top
.fade-opacity-fastout-enter-active,
.fade-opacity-fastout-leave-active {
    transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
        transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.fade-opacity-fastout-enter,
.fade-opacity-fastout-leave-to {
    opacity: 0;
    transition: opacity 0s;
}
.fade-opacity-fastout-leave {
    opacity: 0;
    transition: opacity 0s;
}

$in-motion: transform 0.2s cubic-bezier(0.42, 0, 0.58, 1),
    opacity 0.2s cubic-bezier(0.42, 0, 0.58, 1);
$out-motion: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1),
    opacity 0.3s cubic-bezier(0.42, 0, 0.58, 1);
$in-slow-motion: transform 0.2s cubic-bezier(0.42, 0, 0.58, 1),
    opacity 0.4s cubic-bezier(0.42, 0, 0.58, 1);
$out-slow-motion: transform 0.3s cubic-bezier(0.42, 0, 0.58, 1),
    opacity 0.5s cubic-bezier(0.42, 0, 0.58, 1);

@keyframes pulse {
    from {
        transform: scale3d(1, 1, 1);
    }

    50% {
        transform: scale3d(1.2, 1.2, 1.2);
    }

    to {
        transform: scale3d(1, 1, 1);
    }
}
