html, body {
    background: #000000;
}

body {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    user-select: none;
    overflow-x: hidden;
    cursor: default;
    width: 100%;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
p {
    word-break: break-all;
}

#nprogress .spinner {
    display: none !important;
}

@import '../src/styles/app.scss';
@import '../src/styles/player/player.scss';
@import '../src/styles/reset.scss';
@import '../src/styles/common/effect.scss';
@import '~swiper/css';
@import '~swiper/css/scrollbar';
@import '~swiper/css/effect-fade';
