@import '_var';
@import '_resolution';
@import '_transition';

.cjp-wrap {
}

.cjp-log {
    display: none;
}

// GNB...
#gnb-header {
    transition-delay: 0s;
    transition: $in-motion;

    &:not(.sub) {
        &:before {
            content: '';
            position: absolute;
            top: 0px;
            left: 0;
            width: 100%;
            height: 53px;
            pointer-events: none;
            user-select: none;
            background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAYAAACtWK6eAAAFUklEQVR4Xu3T+esfAgCA4c8XsRxhjRyhjZhGjpiQI0doaNbQNqGNHCEmpk1YyMgIOWJCjmZCbCGmIWTkCBlhsRyZHA2NmOT37/sfPO+f8NQ7NBgM/h2IAIFhBYYGg8E6NgQIDC/w/yD/wCFAoAf5Gw4BAj3IX3AIEOhB/oRDgEAPshYOAQI9yB9wCBDoQX6HQ4BAD/IbHAIEepA1cAgQ6EF+hUOAQA/yCxwCBHqQn+EQINCD/ASHAIEe5Ec4BAj0IKvhECDQg/wAhwCBHuR7OAQI9CDfwSFAoAf5Fg4BAj3IN3AIEOhBVsEhQKAH+RoOAQI9yFdwCBDoQVbCIUCgB/kSDgECPcgXcAgQ6EE+h0OAQA/yGRwCBHqQT+EQINCDrIBDgEAP8gkcAgR6kI/hECDQg3wEhwCBHuRDOAQI9CAfwCFAoAd5Hw4BAj3Ie3AIEOhB3oVDgEAP8g4cAgR6kLfhECDQgyyHQ4BAD/IWHAIEepA34RAg0IO8AYcAgR7kdTgECPQgr8EhQKAHeRUOAQI9yCtwCBDoQZbBIUCgB3kZDgECPchSOAQI9CAvwSFAoAd5EQ4BAj3IC3AIEOhBnodDgEAP8hwcAgR6kCVwCBDoQRbDIUCgB3kWDgECPcgzcAgQ6EGehkOAQA/yFBwCBHqQJ+EQINCDPAGHAIEeZBEcAgR6kMfhECDQgyyEQ4BAD/IYHAIEepBH4RAg0IM8AocAgR7kYTgECPQgD8EhQKAHeRAOAQI9yANwCBDoQe6HQ4BAD7IADgECPch9cAgQ6EHuhUOAQA9yDxwCBHqQu+EQINCD3AWHAIEe5E44BAj0IHfAIUCgB7kdDgECPchtcAgQ6EFuhUOAQA9yCxwCBHqQ+XAIEOhBboZDgEAPchMcAgR6kBvhECDQg8yDQ4BAD3IDHAIEepDr4RAg0INcB4cAgR7kWjgECPQgc+EQINCDXAOHAIEe5Go4BAj0IFfBIUCgB7kSDgECPcgcOAQI9CCz4RAg0INcAYcAgR5kFhwCBHqQy+EQINCDXAaHAIEe5FI4BAj0IDPhECDQg1wChwCBHuRiOAQI9CAXwSFAoAe5EA4BAj3IBXAIEOhBzodDgEAPch4cAgR6kHPhECDQg5wDhwCBHuRsOAQI9CBnwSFAoAeZAYcAgR5kOhwCBHqQM+EQINCDnAGHAIEe5HQ4BAj0IKfBIUCgB5kGhwCBHmQqHAIEepApcAgQ6EFOhUOAQA9yChwCBHqQk+EQINCDTIZDgEAPMgkOAQI9yElwCBDoQSbCIUCgBzkRDgECPcgJcAgQ6EGOh0OAQA8yAQ4BAj3IcXAIEOhBjoVDgEAPcgwcAgR6kKPhECDQgxwFhwCBHuRIOAQI9CBHwCFAoAc5HA4BAj3IYXAIEOhBDoVDgEAPcggcAgR6kIPhECDQgxwEhwCBHuRAOAQI9CAHwCFAoAcZD4cAgR5kfzgECPQg+8EhQKAH2RcOAQI9yD5wCBDoQfaGQ4BAD7IXHAIEepA94RAg0IPsAYcAgR5kHBwCBHqQ3eEQINCDjIVDgEAPshscAgR6kF3hECDQg+wChwCBHmRnOAQI9CBj4BAg0IOMhkOAQA+yExwCBHqQHeEQINCD7ACHAIEeZHs4BAj0INvBIUCgB9kWDgECPcg2cAgQ6EG2hkOAQA+yFRwCBHqQUXAIEOhBRsIhQKAH2RIOAQI9yBZwCBDoQTaHQ4BAD7IZHAIEepBN4RAg0INsAocAgR5kYzgECPQgI+AQINCDbASHAIEeZEM4BAj0IBvAIUCgB1kfDgECPch6cAgQ6EGG4BAgMLzAf3nyZGWodcvhAAAAAElFTkSuQmCC) repeat-x;
            background-size: 100% 53px;
            transition: background-size 0.3s,
                height 0.3s;
            will-change: background-size,
                height;
            transform: scaleY(1);
            opacity: 0.7;
        }
    }

    &.playing:not([data-hidden="false"]) {
        transition: $out-motion;
        transition-delay: 0s;
        transform: translate(0, -48px);
        opacity: 0;
        pointer-events: none;

        &.media-loading,
        &.media-paused {
            transition-delay: 0s;
            transition: $in-motion;
            transform: translate(0, 0px);
            opacity: 1;
            pointer-events: visible;
        }
    }

    &.media-loading,
    &.media-paused {
        transition-delay: 0s;
        transition: $in-motion;
        transform: translate(0, 0px);
        opacity: 1;
        pointer-events: visible;
    }
}

@include mobile {}

/* &.hover-ui-hide:hover, */
#gnb-header.hover {

    &.playing,
    &.media-loading {
        transition-delay: 0s;
        transition: $in-motion;
        transform: translate(0, 0);
        opacity: 1;
        pointer-events: visible;
    }
}



.cjp__video {
    opacity: 1 !important;
}
