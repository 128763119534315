// @import 'common.scss';

//상세페이지-movie
.page_detail_movie {
    position: relative;
    margin-bottom: 4.168rem;
    min-height: 43.55em;
    &_background {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: -1;
        &:after {
            $pd: -100px;
            content: '';
            position: absolute;
            top: $pd;
            bottom: $pd;
            left: $pd;
            right: $pd;
            background-color: rgba(0, 0, 0, 0.7);
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            filter: blur(80px);
        }
    }
    &.lay_grid {
        display: grid;
        // grid-template-rows: 8rem minmax(8rem, auto) 4.5rem auto;
        grid-auto-rows: auto;
        grid-template-columns: auto 30rem;
        grid-template-areas:
            'title poster'
            'button poster'
            'tag poster'
            'info poster';
        column-gap: 8.167rem;
        &.mobile {
            grid-template-areas:
                'poster'
                'title'
                'tag'
                'info'
                'button';
            grid-template-columns: 1fr;
        }
        &.grid_column {
            grid-template-columns: repeat(1fr);
        }
        &.grid__btn-none {
            grid-template-rows: 8rem 4.5rem auto;
            grid-template-areas:
                'title poster'
                'tag poster'
                'info poster';

            .page_btn {
                display: none;
            }
        }
    }
    .page_poster {
        grid-area: poster;
        justify-self: end;
        // grid-row: 1 / span 4;
        // grid-column-start: 2;
        width: 30rem;
        height: 43.55rem;
        border-radius: 4px;
        overflow: hidden;
        box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.5);
        //position으로 변경
        // position: relative;
        position: absolute;
        top: 0;
        right: 0;
        .tag_orig,
        .tag_only {
            &::before {
                background-size: 35% 100%;
            }
        }
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .page_title,
    .page_btn,
    .tag_wrap,
    .page_info,
    .btn__wrap {
        width: calc(100% - 38.167rem);

        &.tag_detail {
            width: auto;
        }
    }
    .page_title {
        grid-area: title;
        // grid-row: 1 / 2;
        margin: 0;
        font-size: 4.2rem;
        .info-text{
            margin-bottom: 0.75rem;
            color:#ff153c;
            font-size: 1.6rem;
        }
        .tving_original {
            margin-bottom: 0.5em;
            height: 0.275em;
            display: block;
            text-align: left;
            img {
                width: auto;
                vertical-align: top;
            }
        }
    }
    .page_btn {
        margin-top: 3.25rem;
        grid-area: button;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        .btn__action__wrap {
            margin-left: 2.5em;
        }
        .item__progress {
            margin-left: 0.5em;
        }
    }

    > .tag_wrap {
        margin-top: 2.5rem;
        grid-area: tag;
        position: initial;
        &.tag_detail {
            display: flex;
            align-items: center;
            font-size: 1.3em;
            overflow: hidden;

            div {
                // float: left;
                margin-right: 0.5em;
                margin-bottom: 0.5rem;
                position: initial;
                transform: initial;
                &:last-child {
                    margin-right: 0;
                }
            }
            .tag_lay {
                padding: 0.125rem 0.5rem;
                font-size: 1.2rem; //14px;
            }
        }
    }
    .page_info {
        margin-top: 1rem;
        grid-area: info;
        position: relative;
        opacity: 0.64;
        // grid-row: 4 / 5;
        line-height: 1.47;
        &_character {
            margin-bottom: 1em;
            line-height: 1.47;
            li {
                font-size: 1.25em;
                font-weight: 500;
                span {
                    margin-left: 0.75em;
                    font-weight: 400;
                }
            }
        }
        &_content {
            padding-bottom: 2em;
        }
        &_content {
            // opacity: 0.5;
            padding-bottom: 2em;
            font-size: 1.25rem; //15px;
            line-height: 1.47;
            // font-weight: 500;
            word-break: keep-all;
            /*&.item__line-3{
                -webkit-line-clamp: 3;
                height: 5rem;
            }*/
        }
    }
    .btn__wrap{
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        a{
            +a{
                margin-top:0.833rem;
            }
        }
        .btn__line{
            padding: 1.25rem 3.333rem;
            width: auto;
            font-size: 1.167rem;
            font-weight: 700;
        }
        .btn__long {
            width: 100%;
        }
    }
    .page_container {
        padding-top: 2.5em;
        border-top: 0.05em solid rgba(255, 255, 255, 0.15);
        &__layout-flex {
            display: flex;
            align-items: baseline;
            justify-content: flex-start;
            font-size: 1.5em;
            .flex-gap {
                margin-right: 1.2em; //24px;
            }
        }
        &__select {
            &-series {
                position: relative;
                .select_layout__single-value {
                    font-size: 1.5em;
                    font-weight: 600;
                    &::after {
                        // content: '';
                        display: block;
                        display: block;
                        background: url('/img/icon_detail_open.svg') no-repeat 50%;
                        padding: 1em 0 0 1em;
                        background-size: 125% auto;
                        margin-left: 0.25em;
                    }
                    &_content {
                        margin: 0 auto;
                        padding-right: 2em;
                        top: 2.75em;
                        right: initial;
                        left: 0;
                        z-index: 3;
                        width: 19.5em; //24.5vw;//290px;
                        height: 13.25em;
                        overflow: auto;
                        li {
                            &.active {
                                ::after {
                                    content: '';
                                    display: block;
                                    background: url('/img/icon_select_check.svg') no-repeat 50%;
                                    padding: 1em 0 0 1em;
                                    background-size: 200% auto;
                                    margin-left: 0.5em;
                                }
                                a {
                                    display: flex;
                                    color: rgba(255, 255, 255, 1);
                                }
                            }
                            a {
                                word-wrap: break-word;
                                overflow: hidden;
                                text-overflow: ellipsis;
                                -webkit-box-orient: vertical;
                                -webkit-line-clamp: 1;
                                display: -webkit-inline-box;
                            }
                        }
                    }
                }
            }
        }
        .item {
            &_info {
                // color: rgba(255, 255, 255, 1);
                font-weight: 600;
                -webkit-line-clamp: 2;
            }
            &_title {
                -webkit-line-clamp: 3;
            }
        }
    }
}

//상세페이지 공통- 버튼
.btn {
    &-long__detail{
        margin-top: 2rem;
        width: 100%;
        a{
            width: 100%;
            font-size: 1.5rem;
            padding: 1.5rem 0;
            border: solid 1.6px rgba(255, 255, 255, 0.5);
        }
    }
    &_view {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        span {
            white-space: nowrap;
        }
        & > button {
            margin-top: 0.75em;
            &:first-child {
                margin: 0;
            }
        }
    }
    //'찜, 평가, 공유'버튼 공통 레이아웃 시작
    &__action {
        font-size: 1.175rem;
        font-weight: 500;
        opacity: 0.6;
        transition: opacity .1s;
        &:hover {
            opacity: 1;
        }
        &__wrap {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            & > div {
                margin-left: 2.5em;
                &:first-child {
                    margin-left: 0;
                }
            }
        }
        &__lay {
            display: inline-block;
            padding: 2.75em 2.275em 0 0;
            background-position-x: 50%;
            background-position-y: 50%;
            background-repeat: no-repeat;
            background-size: contain;
            text-indent: -9999px;
        }
        &__title {
            text-align: center;
            font-size: 1.175rem;
        }
    }
    //'찜'버튼 시작
    &_selected {
        background-image: url('/img/icon-add.svg');
        &.active {
            background-image: url('/img/icon-add-check.svg');
        }
    }
    &_share {
        background-image: url('/img/icon-share.svg');
    }
    //'평가'시작
    &_result {
        font-size: 1.175rem;
        font-weight: 500;
        background-image: url('/img/icon-thumbup.svg');
        background-position-y: 40%;
        background-repeat: no-repeat;
        background-size: contain;
        opacity: 0.7;
        &:hover {
            opacity: 1;
        }
        &_wrap {
            position: relative;
        }
        &_popup {
            display: none;
            justify-content: center;
            position: absolute;
            left: 50%;
            z-index: 100;
            transform: translateX(-50%);
            margin-top: -8em;
            padding: 1.25em 1.5em 1.5em;
            border-radius: 0.1em;
            // background-color: rgba(255, 255, 255, 0.15);
            background-color: rgba(62, 62, 62, 0.95);
            // box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
            &.active {
                display: flex;
            }
            &::after {
                content: '';
                display: block;
                position: absolute;
                bottom: -1.5em;
                left: 50%;
                transform: translateX(-50%);
                border-style: solid;
                border-width: 0.75em;
                // border-top-color: rgba(255, 255, 255, 0.15);
                border-top-color: rgba(62, 62, 62, 0.95);
                border-right-color: rgba(255, 255, 255, 0);
                border-bottom-color: rgba(255, 255, 255, 0);
                border-left-color: rgba(255, 255, 255, 0);
            }
            .btn_result {
                &:nth-child(n + 2) {
                    margin-left: 1em;
                }
            }
        }
    }
}

//상세페이지-live
.page_detail_live {
    // margin-top: -2.5vw;
    .page_container {
        padding-top: 2em;
        border-top: solid 1px rgba(255, 255, 255, 0.3);
    }
    h2 {
        margin: 0.75em auto;
    }
    .schedules {
        &__day {
            margin-left: 1em;
            font-size: 1.125rem;
            font-weight: 400;
            color: rgba(255, 255, 255, 0.3);
        }
    }
    .contents_row_wrap {
        margin-bottom: 3.5em;
    }
    .container_item {
        display: flex;
        justify-content: flex-start;
    }
}

.item {
    &__only-text {
        padding: 1.667em;
        display: grid;
        grid-template-rows: auto;
        grid-template-columns: 16% 69% 15%;
        gap: 0.75em 0;
        box-sizing: border-box;
        border-style: solid;
        border-width: 1px;
        margin-right: -0.09em;
        &.active {
            grid-template-columns: 23% 62% 15%;
            opacity: 1;
            background-image: linear-gradient(
                to right,
                rgba(255, 21, 60, 0.3),
                rgba(255, 21, 60, 0.11) 52%,
                rgba(255, 21, 60, 0) 52%,
                rgba(255, 21, 60, 0)
            );

        }

        // gap:2.5vw .75vw;
        font-size: 1.125rem; //14px;
        &__now {
            grid-column: 1 / 2;
            > span {
                color: rgba(255, 255, 255, 1);
                text-transform: uppercase;
                &.live {
                    padding: 0em 0.75em;
                    border-radius: 50px;
                    background-color: rgba(255, 22, 60, 0.9);
                    font-weight: 800;
                    color: rgba(0, 0, 0, 0.7);
                    text-transform: uppercase;
                }
            }
        }
        &__time {
            grid-column: 2 / 3;
            color: rgba(255, 255, 255, 0.7);
            font-weight: 400;
        }
        &__title {
            margin-right: 1em;
            grid-column: 1 / span 2;
            font-size: 1.325rem; //16px;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            display: -webkit-inline-box;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        &__alarm {
            grid-column-start: 3;
            grid-row: 1 / span 2;
        }
    }
}

.program-info {
    padding: 3.5em 0 2.5em; //40px 0 30px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 1rem;
    &__channel {
        padding-right: em(40);
        img {
            width: auto;
        }
    }
    &__title {
        flex-basis: 78%;
        h2 {
            margin: 0 auto;
            font-size: 2.5rem;
        }
        &__time {
            margin-top: 0.75em;
            color: #666;
            font-size: 1.325rem; //16px;
        }
    }
    &__btn {
        flex-basis: 10%;
        text-align: right;
        // justify-content: flex-end;
        // &.btn_action input + label{
        //     padding:2.5em 0.45em 0em;
        //     background-size: 55% auto;
        // }
        &.btn__action {
            p {
                text-align: right;
                margin-right: 0.25em;
            }
        }
    }
}

//모바일
@media screen and (max-width:768px) {
    .page_detail_movie {
        margin-bottom:3rem;
        .page_title,
        .page_btn,
        .tag_wrap,
        .page_info,
        .btn__wrap{
            width: 100%;
        }
        .page{
            &_poster{
                position: inherit;
                margin: 0 -1.333rem 2rem;
                width: calc(100% + 2.666rem);
                height: 21.1rem;
                border-radius: 0;
            }
            &_title {
                font-size: 2.6rem;
                .tving_original{
                    margin-bottom: 0.75rem;
                    height: 0.9rem;
                    img{
                        height: 100%;
                    }
                }
            }
            &_btn{
                display: none;
            }
            &_info{
                width: 100%;
                /*&_content{
                    padding: 0 0 0.5rem 0;
                    -webkit-line-clamp: initial;
                    -webkit-box-orient: vertical;
                    display: -webkit-inline-box;
                    word-wrap: break-word;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    &.item__line-3{
                        -webkit-line-clamp: 3;
                        !*height: 5rem;*!
                    }
                }*/
            }
            //컨텐츠
            &_container{
                margin-top: 3rem;
                padding-top: 0;
                border: 0;
            }
            &_select{
                margin: 0 auto;
            }
        }

        >.tag_wrap{
            margin-top: 1rem;
        }
        .btn__wrap {
            display: block;
            margin-top: 1.6rem;
            .btn__line{
                padding: 1.6rem;
                width: 100%;
            }
        }
    }
}
