/* Ripple effect */
.effect-ripple {
  position: relative;
  overflow: hidden;

  transition: background 400ms;
  cursor: pointer;

  span.ripple {
    position: absolute;
    border-radius: 50%;
    transform: scale(0);
    animation: ripple 600ms linear;
    background-color: rgba(255, 255, 255, 0.4);
    box-shadow: 0 0 0.5rem 0.5rem rgba(255, 255, 255, 0.4);
  }
}

@keyframes ripple {
  to {
    transform: scale(4);
    opacity: 0;
  }
}

.drag-none {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}
